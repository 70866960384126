import React from 'react';
import {useFormContext} from "react-hook-form";
import {classNames} from "../../../../utils/class-names";

interface RadioFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    value: string;
    className?: string;
    showError?: boolean;
}

const RadioFormField: React.FC<RadioFormFieldProps> = (props: RadioFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {name, label, value, className = '', disabled, showError = true, ...rest} = props;

    const isDisabled = disabled !== undefined ? disabled : isSubmitting;

    return (
        <div className="cursor-pointer">
            <label htmlFor={`${name}-${value}`} className="flex items-center gap-2 text-gray-700 theme-dark:text-gray-50 cursor-pointer">
                <input
                    type="radio"
                    id={`${name}-${value}`}
                    value={value}
                    {...register(name)}
                    className={classNames(
                        `appearance-none ${className}`,
                        errors[name] ? '!border-red-500' : '',
                        isDisabled ? 'checked:!bg-gray-300 !border-gray-300' : '',
                    )}
                    disabled={isDisabled}
                    {...rest}
                />
                {label}
            </label>
            {showError && errors[name] && <p className="text-red-500 text-xs">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default RadioFormField;
