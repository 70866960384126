import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ArrowRightIcon} from "@heroicons/react/24/solid";

const EmptyListCourses = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/main');
    };

    return (
        <div className="flex flex-col h-full items-center">
            <p className="text-gray-600 theme-dark:text-gray-50 mb-6 text-base leading-none font-normal w-full sm:w-fit">
                Obecnie nie mamy kursów, do których można dołączyć.
            </p>
            <p className="text-gray-600 theme-dark:text-gray-50 mb-6 text-base leading-none font-normal w-full sm:w-fit">
                Zajrzyj do naszych bezpłatnych materiałów.
            </p>
            <button
                onClick={handleNavigate}
                className="bg-green-600 text-white font-semibold py-3 px-6 rounded-full flex items-center shadow-md
                hover:bg-green-700 focus:bg-green-700 transition-all"
            >
                Przejdź do obszarów
                <ArrowRightIcon className="w-5 h-5 ml-2"/>
            </button>
        </div>
    );
};

export default EmptyListCourses;
