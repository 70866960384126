import React from 'react';

interface SpinnerProps {
    size?: string;
    color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = "20px", color = "var(--primary-400)" }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
                border: `3px solid ${color}`,
                borderTop: `3px solid transparent`,
                borderRadius: "50%",
                animation: "spin 1s linear infinite"
            }}
        />
    );
};

export default Spinner;
