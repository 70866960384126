// Calendar.tsx
import React, { useEffect, useState } from "react";
import MobileCalendar from "../modules/calendar/containers/mobile-calendar";
import DesktopCalendar from "../modules/calendar/containers/desktop-calendar";
import MobileAddEventButton from "../modules/calendar/components/mobile/add-event-button";
import { TypeCalendarEvent } from "../modules/calendar/types/type-event.interface";
import CalendarFormMobile, {
    CalendarFormMobileHandler,
} from "../modules/calendar/components/mobile/calendar-form-mobile";
import CalendarFormDesktop, {
    CalendarFormDesktopHandler,
} from "../modules/calendar/components/desktop/calendar-form-desktop";
import { CalendarFormProvider } from "../modules/calendar/contexts/calendar-form-context";

const Calendar = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <CalendarFormProvider
            isMobile={isMobile}
            getFormComponentMobile={(ref) => <CalendarFormMobile ref={ref} />}
            getFormComponentDesktop={(ref) => <CalendarFormDesktop ref={ref} />}
        >
            <div className="overflow-y-auto max-h-full py-6">
                {isMobile ? (
                    <div>
                        <MobileCalendar />
                        <MobileAddEventButton />
                    </div>
                ) : (
                    <DesktopCalendar />
                )}
            </div>
        </CalendarFormProvider>
    );
};

export default Calendar;
