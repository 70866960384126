import React from "react";

const SkeletonCourseDetailed = () => {
    return (
        <div className="flex flex-col sm:flex-row sm:gap-x-10 max-w-lg sm:max-w-full sm:min-w-full mx-auto sm:mx-0 rounded-lg overflow-hidden w-full animate-pulse">
            <div className='sm:flex-grow w-full sm:max-w-[360px]'>
                <div className="relative w-full h-48 sm:h-60 bg-gray-200 rounded-lg"></div>

                <div className="p-4 flex justify-end items-center my-3">
                    <div className="h-6 bg-gray-200 w-20 rounded"></div>
                </div>

                <div className="px-4 pb-4">
                    <div className="w-full bg-gray-300 h-10 rounded-full"></div>
                </div>
            </div>

            <div className="px-4 pb-6 mt-10 sm:mt-0 w-full">
                <div className="h-6 bg-gray-200 w-1/3 mb-10 rounded"></div>

                <div className="space-y-10">
                    <div>
                        <div className="h-5 bg-gray-200 w-2/3 mb-2 rounded"></div>
                        <ul className="space-y-2">
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <div className="h-5 bg-gray-200 w-2/3 mb-2 rounded"></div>
                        <ul className="space-y-2">
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <div className="h-5 bg-gray-200 w-2/3 mb-2 rounded"></div>
                        <ul className="space-y-2">
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
                                <div className="h-4 bg-gray-200 w-3/4 rounded"></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonCourseDetailed;
