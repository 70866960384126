import {Backgrounds} from "../types/background.interface";

export const SettingBackground = [
    {
        background: Backgrounds.NONE,
        representativeName: 'Domyślne',
    },
    {
        background: Backgrounds.BLUE,
        representativeName: 'Góry',
    },
    {
        background: Backgrounds.YELLOW,
        representativeName: 'Las',
    },
    {
        background: Backgrounds.GREEN,
        representativeName: 'Paprocie',
    },
    {
        background: Backgrounds.DARK_GREEN,
        representativeName: 'Zorza',
    },
]
