import React, {useRef} from "react";
import useElementStates from "../../core/hooks/use-element-states";
import {useNavigate} from "react-router-dom";
import {PrefixBase64} from "../../core/constants/prefixBase64";
import {PhotoIcon} from "@heroicons/react/24/outline";
import {MainArea} from "../../dashboard/types/main-area.interface";
import {CurrencyHelper} from "../../../utils/currency-helper";

const PreviewCourse: React.FC<MainArea> = (props) => {
    const refButton = useRef<HTMLButtonElement>(null)
    const {isPressed, isHovered} = useElementStates({ref: refButton})
    const navigate = useNavigate();

    const goToCourseDetails = () => navigate(`/main/courses/${encodeURIComponent(props?.name)}`);

    const hasImage = (): boolean => !!props.image?.imageBase64;

    return (
        <article
            className={`rounded-lg flex sm:flex-wrap w-full sm:rounded-lg sm:overflow-hidden bg-white sm:max-h-[340px] max-h-[110px]
            overflow-hidden shadow-lg ring-black ring-opacity-5 ring-1 
            transform transition-transform duration-300 border-primary_600 box-border`}
        >
            <div
                className='hidden xs:block relative sm:w-full rounded-l-lg sm:rounded-none sm:h-60 bg-gray-200 w-28 min-w-28'>
                {hasImage() ? (
                    <img
                        className='w-full h-full object-cover'
                        alt={props?.name}
                        src={PrefixBase64 + props.image?.imageBase64}
                    />
                ) : (
                    <PhotoIcon className='w-full h-full text-gray-500'/>
                )}
            </div>
            <header className='flex flex-col rounded-l-lg overflow-hidden flex-grow m-4 h-fit gap-1'>
                <h6 className='text-gray-900 font-semibold text-md truncate'>
                    {props?.name}
                </h6>
                <div className='flex items-center gap-2 justify-between'>
                    <button
                        ref={refButton}
                        onClick={goToCourseDetails.bind(this)}
                        className={`px-4 py-2 text-xs font-semibold text-gray-600 truncate
                        bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-100"
                        ${isHovered ? 'border border-green-600 text-green-700' : ''} cursor-pointer
                        ${isPressed ? 'border border-green-600 text-green-700' : ''}`}>
                        Poznaj szczegóły
                    </button>

                    <div className="flex items-center justify-end pr-2 w-full max-w-fit">
                        <span className="text-xl leading-7 font-bold text-green-700">
                            {CurrencyHelper.formatToPLN(props.coursePrice)}
                        </span>
                    </div>
                </div>
            </header>
        </article>
    );
}

export default PreviewCourse
