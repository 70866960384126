import {PromoCodeType} from "../types/promo-codes/promo-code-type.interface";
import {CodeType} from "../types/promo-codes/code-type.interface";
import {PromotionType} from "../types/promo-codes/promotion-type.interface";
import {PromoCodeDetails} from "../types/promo-codes/promo-code.interface";

export const validatePromoCode = (promoCode: PromoCodeDetails, currentType: PromoCodeType, basePrice: number): {
    isValidPromoCode: boolean,
    finalPrice: number
} => {
    const today = new Date();

    if (new Date(promoCode.expiryDate) < today) {
        return {isValidPromoCode: false, finalPrice: basePrice};
    }

    if (promoCode.type !== currentType) {
        return {isValidPromoCode: false, finalPrice: basePrice};
    }

    if (promoCode.codeType === CodeType.SINGLE_USE && promoCode.usesNumber === 0) {
        return {isValidPromoCode: false, finalPrice: basePrice};
    }

    let finalPrice = basePrice;

    if (promoCode.promotionType === PromotionType.DISCOUNT) {
        if (promoCode.discountAmount) {
            finalPrice = basePrice - promoCode.discountAmount;
        } else if (promoCode.discountPercentage) {
            finalPrice = basePrice - (basePrice * (promoCode.discountPercentage / 100));
        }

        if (finalPrice <= 0) {
            finalPrice = 0.01;
        }
    }

    return {isValidPromoCode: true, finalPrice};
};
