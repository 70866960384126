import React, {forwardRef, useImperativeHandle} from "react";
import * as yup from "yup";
import TextFormField from "../../common/components/forms/text-form-field";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {IntroductionFormBody} from "../types/introduction-form.interface";
import {useIntroductionApi} from "../hooks/use-introduction-api";
import useAuth from "../../auth/hooks/useAuth";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";

const schema = yup.object({
    firstname: yup
        .string()
        .min(3, 'Imię jest za krótkie, minimum 3 znakowe imię')
        .max(255, 'Imię maksymalnie może mieć 255 znaków')
        .required('Imię jest wymagane'),
    age: yup
        .number()
        .transform((value, originalValue) => originalValue === '' ? null : value)
        .min(1, 'Minimalny wiek to 1')
        .max(100, 'Maksymalny dopuszczalny wiek to 100')
        .required('Wiek jest wymagany'),
    gender: yup
        .string()
        .min(3, 'Płeć jest za krótka, minimum 3 znaki')
        .max(255, 'Płeć maksymalnie może mieć 255 znaków')
        .required('Płeć jest wymagana'),
    profession: yup
        .string()
        .min(3, 'Wykształcenie jest za krótkie, minimum 3 znaki')
        .max(255, 'Imię maksymalnie może mieć 255 znaków')
        .required('Wykształcenie jest wymagane')
}).required();

interface PropsIntroductionForm {
    onSubmitSuccess: () => void;
}

export interface IntroductionFormHandle {
    submitForm: () => void;
}

const IntroductionForm = forwardRef(({onSubmitSuccess}: PropsIntroductionForm, ref) => {
    const {sendUserData} = useIntroductionApi();
    const {updateAuthenticationField, currentUser} = useAuth();

    const methods = useForm<IntroductionFormBody>({
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const handleSubmit = (data: IntroductionFormBody): void => {
        sendUserData(data)
            .then(() => {
                updateAuthenticationField("user", {...currentUser!, hasCompletedIntroduction: true})
            })
            .then(() => onSubmitSuccess())
            .catch((err) => {
                console.log(err)
            })
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            methods.handleSubmit(handleSubmit)();
        }
    }));

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} className='flex flex-col gap-y-4 p-1'>
                <TextFormField name='firstname' label='Imię' isRequired={true}/>
                <TextFormField name='age' label='Wiek' type='number' isRequired={true}/>
                <TextFormField name="gender" label="Płeć" isRequired={true}/>
                <TextFormField name="profession" label="Wykształcenie" isRequired={true}/>
            </form>
        </FormProvider>
    );
});

export default IntroductionForm;
