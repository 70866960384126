import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

interface Props {
    currentDate: Date;
    onPreviousMonth: () => void;
    onNextMonth: () => void;
}

const MonthCalendarNavigation: React.FC<Props> = ({ currentDate, onPreviousMonth, onNextMonth }) => {
    return (
        <div className="flex justify-between items-center px-4 mb-2">
            <button onClick={onPreviousMonth}>
                <ChevronLeftIcon className="w-5 h-5 text-gray-600 theme-dark:text-gray-50" />
            </button>
            <span className="text-base font-medium first-letter:uppercase">
                {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </span>
            <button onClick={onNextMonth}>
                <ChevronRightIcon className="w-5 h-5 text-gray-600 theme-dark:text-gray-50" />
            </button>
        </div>
    );
};

export default MonthCalendarNavigation;
