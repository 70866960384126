import {useContext} from "react";
import {PageScenarioContext} from "../contexts/page-scenario-context";
import {PageScenarioContextProps} from "../types/page-scenario-context.interface";

export const usePageScenario = (): PageScenarioContextProps=> {
    const context = useContext(PageScenarioContext);
    if (!context) {
        throw new Error('usePageScenario must be used within a PageScenarioProvider');
    }
    return context;
};
