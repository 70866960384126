import {useContext} from "react";
import {PromoCodeContext} from "../contexts/promo-code-context";

export const usePromoCode = () => {
    const context = useContext(PromoCodeContext);
    if (!context) {
        throw new Error('usePromoCodeContext must be used within a PromoCodeProvider');
    }
    return context;
};
