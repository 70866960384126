import React from "react";
import DOMPurify from 'dompurify';
import { BaseBlock } from "../types/base-block.interface";
import { IParagraphBlock } from "../types/blocks/paragraph-block.interface";

const ParagraphBlock: React.FC<BaseBlock<IParagraphBlock>> = (props: BaseBlock<IParagraphBlock>) => {
    const { data } = props;
    const { contentHTML } = data;

    const createMarkup = (html: string) => {
        return {
            __html: DOMPurify.sanitize(html, {
                ADD_ATTR: ['target'],
                RETURN_DOM: false
            })
        };
    };

    return (
        <div
            className='text-base leading-none font-normal text-gray-800'
            dangerouslySetInnerHTML={createMarkup(contentHTML)}
        ></div>
    );
};

export default ParagraphBlock;
