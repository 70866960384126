import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {HandRaisedIcon} from "@heroicons/react/24/outline";

const PhraseSortable: React.FC<{ id: string, content: string, readonly: boolean }> = ({id, content, readonly}) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}
             className="flex items-center p-4 bg-white shadow-lg text-gray-900 rounded-3xl border cursor-grab border-gray-300 hover:bg-gray-100">
            <span className="flex-grow">{content}</span>
            {!readonly && <HandRaisedIcon className='w-5 h-5'/>}
        </div>
    );
};

export default PhraseSortable
