import ListAreas from "../modules/dashboard/components/list-areas";
import {Route, Routes} from "react-router-dom";
import ListSubareas from "../modules/dashboard/components/list-subareas";
import ListChapters from "../modules/dashboard/components/list-chapters";
import BreadcrumbArea from "../modules/dashboard/components/breadcrumb-area";
import Chapter from "../modules/dashboard/components/chapter";

const Dashboard = () => {

    return (
        <main className='pt-5'>
            <BreadcrumbArea/>
            <Routes>
                <Route index element={<ListAreas/>}/>
                <Route path='/:area' element={<ListSubareas/>}/>
                <Route path='/:area/:subarea' element={<ListChapters/>}/>
                <Route path='/:area/:subarea/:chapter' element={<Chapter/>}/>
            </Routes>
        </main>
    )
}

export default Dashboard
