import {useContext} from "react";
import {AuthContextType} from "../types/authContextType.interface";
import {AuthContext} from "../contexts/AuthContext";

const useAuth = () => {
    const context: AuthContextType = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within and AuthProvider");
    }
    return context;
}

export default useAuth
