import React, {RefObject, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Listbox} from '@headlessui/react';
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../../../utils/class-names";
import SkeletonFormField from "./skeleton-form-field";

interface SelectFormFieldProps {
    name: string;
    label: string;
    options: any[];
    className?: string;
    valueKey?: string;
    labelKey?: string;
    parentWrapperRef?: RefObject<HTMLElement>;
    onChange?: (value: any) => void;
    isLoading?: boolean;
}

const SelectFormField: React.FC<SelectFormFieldProps> = (props: SelectFormFieldProps) => {
    const {register, setValue, watch, formState: {errors, isSubmitting}} = useFormContext();
    const [dropUp, setDropUp] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const {
        name,
        label,
        options,
        className = '',
        valueKey = 'value',
        labelKey = 'label',
        isLoading,
        parentWrapperRef,
        onChange,
        ...rest
    } = props;

    const defaultValue = watch(name);
    const [selectedOption, setSelectedOption] = useState(() => {
        return options.find(option => option[valueKey] === defaultValue) || options[0];
    });

    useEffect(() => {
        setValue(name, selectedOption[valueKey]);
    }, [selectedOption, setValue, name, valueKey]);

    useEffect(() => {
        const option = options.find(option => option[valueKey] === defaultValue);
        option && setSelectedOption(option);
    }, [defaultValue, options, valueKey]);

    const checkDropUp = () => {
        if (parentWrapperRef?.current && selectRef.current) {
            const optionHeight: number = 42;
            const optionsHeight: number = optionHeight * options.length;
            const parentRect: DOMRect = parentWrapperRef.current.getBoundingClientRect();
            const selectRect: DOMRect = selectRef.current.getBoundingClientRect();
            const spaceBelow: number = parentRect.bottom - selectRect.bottom;
            const shouldDropUp: boolean = spaceBelow - optionsHeight < 0;
            setDropUp(shouldDropUp);
        }
    };

    const handleChange = (option: any): void => {
        setSelectedOption(option);
        setValue(name, option[valueKey]);
        onChange && onChange(option);
    };

    return (
        <div className="mb-2" ref={selectRef}>
            <label htmlFor={name} className="block text-gray-700 mb-1 theme-dark:text-gray-50">{label}</label>
            {!isLoading
                    ? <Listbox value={selectedOption}
                               onChange={handleChange}
                               disabled={isSubmitting}>
                        <div className="relative">
                            <Listbox.Button onClick={checkDropUp} className={classNames(
                                'relative w-full py-2 pl-3 pr-10 text-left border bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-2',
                                errors[name] ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary_400',
                                className
                            )}>
                                <span className="block truncate text-gray-900">{selectedOption[labelKey]}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                        </span>
                            </Listbox.Button>
                            <Listbox.Options className={classNames(
                                dropUp
                                    ? 'bottom-full mb-2 '
                                    : 'top-full mt-1',
                                'absolute w-full py-1 mt-1 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none'
                            )}>
                                {options.map((option, index) => (
                                    <Listbox.Option
                                        key={index}
                                        value={option}
                                        className={({active}) => classNames(
                                            'cursor-pointer select-none relative py-2 pl-10 pr-4 hover:bg-gray-100',
                                            active ? 'bg-primary-600' : 'text-gray-900'
                                        )}
                                    >
                                        {({selected}) => (
                                            <>
                                        <span className={classNames(
                                            'block truncate text-gray-900',
                                            selected ? 'font-medium' : 'font-normal'
                                        )}>
                                            {option[labelKey]}
                                        </span>
                                                {selected ?
                                                    <span
                                                        className="absolute inset-y-3 left-0 flex justify-center items-center ml-3 h-4 w-4 rounded-full bg-primary">
                                                <span className='absolute bg-white h-[6px] w-[6px] rounded-full'></span>
                                            </span>
                                                    :
                                                    <span
                                                        className="absolute inset-y-3 left-0 flex justify-center items-center ml-3 h-4 w-4 rounded-full border-[1px] border-gray-300"></span>
                                                }
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </div>
                    </Listbox>
                    : <SkeletonFormField/>}
            <input type="hidden" {...register(name)} value={selectedOption[valueKey]}/>
            {errors[name] && <p className="text-red-500 text-sm">{'' + errors![name]!.message!}</p>}
        </div>
    );
};

export default SelectFormField;
