import React from "react";

interface StatusLabelProps {
    icon?: React.ReactNode;
    text: string;
    className?: string;
    classNameIcon?: string;
    shouldRender?: boolean;
}

const StatusLabel: React.FC<StatusLabelProps> = (props: StatusLabelProps) => {
    const {
        icon,
        text,
        className = '',
        classNameIcon = '',
        shouldRender = true,
    } = props;
    if (!shouldRender) return null;

    return (
        <div className={`status-label flex items-center rounded-full px-3 py-1 ${className}`}>
            {icon && <span className={`mr-1 text-inherit ${classNameIcon}`}>{icon}</span>}
            <span className="text">{text}</span>
        </div>
    );
};

export default StatusLabel;
