import React from 'react';
import useAuth from "../hooks/useAuth";
import {Navigate, Outlet , RouteProps} from "react-router-dom"

const PublicRoutes = (props: RouteProps) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to="/main" />;
    }

    return <Outlet />;
};
export default PublicRoutes;
