import React from "react";

const SkeletonCoursesTransaction = () => {
    return (
        <div className="w-full max-w-full">
            <h3 className="text-xl leading-8 font-bold flex gap-x-3 items-center mb-4">
                <span className='w-2 h-2 bg-green-600 rounded-full'></span>
                Zakupione kursy
            </h3>

            <div className="space-y-2">
                {/* Header szkielet */}
                <div className="h-4 bg-gray-300 rounded-md animate-pulse"></div>

                {/* Zawartość szkieletu */}
                {[...Array(3)].map((_, index) => (
                    <div key={index} className="flex justify-between items-center bg-gray-300 px-4 py-5 rounded-lg shadow-sm animate-pulse">
                    </div>
                ))}

                {/* Suma szkielet */}
                <div className="rounded-md font-bold h-7 bg-gray-300 pt-2 px-3 animate-pulse !mt-6">
                </div>
            </div>
        </div>
    );
}

export default SkeletonCoursesTransaction;
