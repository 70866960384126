import {Theme} from "../../core/types/theme.interface";

export interface BackgroundInterface {
    [color: string]: {
        theme: Theme,
        bgBackdropClasses: string,
    };
}

export enum Backgrounds {
    NONE = "NONE",
    GREEN = "GREEN",
    YELLOW = "YELLOW",
    BLUE = "BLUE",
    DARK_GREEN = "DARK_GREEN",
}
