import React, {useState} from "react";
import {TypeViewCalendar} from "../../types/type-view-calendar.interface";
import {ChevronDownIcon} from "@heroicons/react/16/solid";

interface DropdownTypeCalendarProps {
    currentView: TypeViewCalendar;
    onViewChange: (view: TypeViewCalendar) => void;
}

const DropdownTypeCalendar: React.FC<DropdownTypeCalendarProps> = ({currentView, onViewChange}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleViewChange = (view: TypeViewCalendar) => {
        onViewChange(view);
        setIsDropdownOpen(false);
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="inline-flex justify-between items-center w-full px-4 py-2 text-gray-700 theme-dark:text-gray-50 text-xl leading-7 font-semibold"
            >
                {currentView === TypeViewCalendar.MONTH_CALENDAR ? "Miesiąc" : "Harmonogram"}
                <ChevronDownIcon className="w-5 h-5 ml-2 text-gray-700 theme-dark:text-gray-50"/>
            </button>

            {isDropdownOpen && (
                <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md
                    ring-1 ring-black ring-opacity-5 focus:outline-none text-sm min-w-fit p-3">
                    <li
                        onClick={() => handleViewChange(TypeViewCalendar.MONTH_CALENDAR)}
                        className={`cursor-pointer w-full select-none relative py-2 pl-3 pr-9 text-sm leading-5 font-medium ${
                            currentView === TypeViewCalendar.MONTH_CALENDAR ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } hover:bg-gray-100 hover:text-gray-900`}
                    >
                        Miesiąc
                    </li>
                    <li
                        onClick={() => handleViewChange(TypeViewCalendar.SCHEDULER)}
                        className={`cursor-pointer w-full select-none relative py-2 pl-3 pr-9 text-sm leading-5 font-medium ${
                            currentView === TypeViewCalendar.SCHEDULER ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } hover:bg-gray-100 hover:text-gray-900`}
                    >
                        Harmonogram
                    </li>
                </ul>
            )}
        </div>
    );
};

export default DropdownTypeCalendar;
