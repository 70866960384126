import {useEffect, useState} from "react";
import useAreaApi from "../hooks/use-area-api";
import {Subarea as ISubarea} from "../types/sub-area.interface";
import Subarea from "./subarea";
import {useParams} from "react-router-dom";
import EmptyList from "./empty-list";
import SubareaSkeleton from "./subarea-skeleton";


const ListSubareas = () => {
    const [subareas, setSubareas] = useState<ISubarea[]>([])
    const {fetchSubareas, loading} = useAreaApi();
    const [isEmpty, setIsEmpty] = useState(false);
    const params = useParams();

    useEffect(() => {
        if (params['area']) {
            fetchSubareas(params['area'])
                .then(res => {
                    res.length && setSubareas(res || [])
                    if (!res.length) setIsEmpty(true)
                })
        }
    }, [])

    const renderSubareas = () => subareas.map((subarea, index) => <Subarea key={index} {...subarea} />)

    return (
        <>
            {!isEmpty
                ? (
                    <section
                        className='grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-8 px-[2px]'>
                        {!loading ? (
                            renderSubareas()
                        ) : (
                            <>
                                {Array.from({length: 6}).map((i, index) => <SubareaSkeleton key={index}/>)}
                            </>
                        )}
                    </section>
                )
                : <EmptyList content='Brak modułów.'/>
            }
        </>
    )
}

export default ListSubareas
