import React, {useEffect, useRef, useState} from "react";
import {CalendarIcon, PlusIcon} from "@heroicons/react/16/solid";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {TypeCalendarEvent} from "../../types/type-event.interface";
import {useCalendarForm} from "../../hooks/use-calendar-form";


const AddEventButton = () => {
    const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { openForm } = useCalendarForm();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 0);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const toggleOptions = () => {
        if (isOptionsVisible) {
            setIsAnimating(true);
            setIsOptionsVisible(false);
            setTimeout(() => {
                setIsAnimating(false);
                buttonRef.current!.style.display = 'flex';
            }, 200);
        } else {
            setIsAnimating(true);
            setTimeout(() => {
                setIsOptionsVisible(true);
                setIsAnimating(false);
                buttonRef.current!.style.display = 'none';
            }, 200);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
            if (isOptionsVisible) {
                toggleOptions();
            }
        }
    };

    useEffect(() => {
        if (isOptionsVisible) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isOptionsVisible]);

    return (
        <>
            {isOptionsVisible && (
                <div
                    className="fixed inset-0 bg-white bg-opacity-60 backdrop-blur-[2px] transition-opacity duration-200 ease-linear"
                    onClick={toggleOptions}
                />
            )}
            <aside className="fixed bottom-24 right-4 flex flex-col items-end w-fit">
                <div
                    className={`transform transition-all duration-200 flex flex-col items-end space-y-3 mb-4 ${
                        isOptionsVisible
                            ? "translate-y-0 opacity-100 pointer-events-auto "
                            : "translate-y-10 opacity-0 pointer-events-none h-0"
                    }`}
                >
                    <div className="flex items-center space-x-2">
                        <span
                            className="text-gray-900 text-sm leading-5 font-normal">Zadanie</span>
                        <button
                            className="w-14 h-14 rounded-full bg-green-100 flex items-center justify-center drop-shadow-2xl shadow-lg"
                            onClick={() => {
                                openForm(TypeCalendarEvent.TASK, null);
                                toggleOptions();
                            }}
                        >
                            <CheckCircleIcon className="w-7 h-7 text-green-600"/>
                        </button>
                    </div>
                    <div className="flex items-center space-x-2">
                        <span
                            className="text-gray-900 text-sm leading-5 font-normal">Wydarzenie</span>
                        <button
                            className="w-14 h-14 rounded-full bg-primary_600 flex items-center justify-center drop-shadow-2xl shadow-lg"
                            onClick={() => {
                                openForm(TypeCalendarEvent.EVENT, null);
                                toggleOptions();
                            }}
                        >
                            <CalendarIcon className="w-6 h-6 text-white"/>
                        </button>
                    </div>
                </div>
                <button
                    ref={buttonRef}
                    className={`w-14 h-14 rounded-full bg-primary_600 focus:outline-none focus:ring-2 focus:ring-primary_100
                focus:ring-offset-2 focus:bg-primary_700 hover:bg-primary_700 flex items-center justify-center transform 
                transition-transform duration-200 ease-out ${
                        isVisible ? "translate-x-0" : "translate-x-full"
                    } ${isOptionsVisible || isAnimating ? "translate-x-full opacity-0" : "opacity-100"}`}
                    onClick={toggleOptions}
                >
                    <PlusIcon className="w-7 h-7 text-white font-bold"/>
                </button>
            </aside>
        </>
    );
};

export default AddEventButton;
