// context/PromoCodeContext.tsx
import React, {createContext, useState} from 'react';
import {PromoCodeDetails} from "../types/promo-codes/promo-code.interface";
import {PromoCodeType} from "../types/promo-codes/promo-code-type.interface";
import {validatePromoCode} from "../helpers/validate-promo-code";


interface PromoCodeContextProps {
    promoCode?: string;
    promoCodeDetails: PromoCodeDetails | null;
    finalPrice: number | null;
    basePrice: number | null;
    setBasePrice: React.Dispatch<React.SetStateAction<number | null>>;
    isValidPromoCode: boolean;
    error: string | null;
    applyPromoCode: (promoCode: string, promoCodeDetails: PromoCodeDetails, currentType: PromoCodeType) => {
        isValidPromoCode: boolean,
        finalPrice: number
    };
    clearPromoCode: () => void;
}

export const PromoCodeContext = createContext<PromoCodeContextProps | undefined>(undefined);

// @ts-ignore
export const PromoCodeProvider: React.FC = ({children}) => {
    const [promoCode, setPromoCode] = useState<string | undefined>(undefined);
    const [promoCodeDetails, setPromoCodeDetails] = useState<PromoCodeDetails | null>(null);
    const [finalPrice, setFinalPrice] = useState<number | null>(null);
    const [basePrice, setBasePrice] = useState<number | null>(null);
    const [isValidPromoCode, setIsValidPromoCode] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const applyPromoCode = (promoCode: string, promoCodeDetails: PromoCodeDetails, currentType: PromoCodeType) => {
        const validation = validatePromoCode(promoCodeDetails, currentType, basePrice!);

        if (validation.isValidPromoCode) {
            setPromoCodeDetails(promoCodeDetails);
            setPromoCode(promoCode);
            setFinalPrice(validation.finalPrice);
            setIsValidPromoCode(true);
            setError(null);
        } else {
            setError('Kod promocyjny jest nieprawidłowy.');
            setPromoCodeDetails(null);
            setPromoCode(undefined);
            setFinalPrice(basePrice);
            setIsValidPromoCode(false);
        }

        return validation
    };

    const clearPromoCode = () => {
        setPromoCode(undefined);
        setPromoCodeDetails(null);
        setFinalPrice(basePrice);
        setIsValidPromoCode(false);
        setError(null);
    };

    return (
        <PromoCodeContext.Provider value={{
            promoCode,
            promoCodeDetails,
            finalPrice,
            basePrice,
            isValidPromoCode,
            error,
            setBasePrice,
            applyPromoCode,
            clearPromoCode
        }}>
            {children}
        </PromoCodeContext.Provider>
    );
};
