import {TypeCalendarEvent} from "../../calendar/types/type-event.interface";
import {CalendarInfo} from "../types/calendar-info.interface";
import {CalendarEventRequestBody} from "../../calendar/types/calendar-event-request-body.interface";

export const mapCalendarInfoToRequestBody = (calendarInfo: CalendarInfo): CalendarEventRequestBody => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + calendarInfo.daysCountForStartDate);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + calendarInfo.daysCountForEndDate - calendarInfo.daysCountForStartDate);

    let startTime: string;
    let endTime: string;

    if (calendarInfo.calendarTypeId === TypeCalendarEvent.TASK) {
        startTime = "T00:00:00";
        endTime = "T00:00:00";
    } else if (calendarInfo.calendarTypeId === TypeCalendarEvent.EVENT) {
        startTime = `T${calendarInfo.hourForStartDate}`;
        endTime = `T${calendarInfo.hourForEndDate}`;
    } else {
        startTime = `T${calendarInfo.hourForStartDate}`;
        endTime = `T${calendarInfo.hourForEndDate}`;
    }

    return {
        calendarTypeId: calendarInfo.calendarTypeId,
        title: calendarInfo.calendarTitle,
        startDate: `${startDate.toISOString().split('T')[0]}${startTime}`,
        endDate: `${endDate.toISOString().split('T')[0]}${endTime}`,
        reminders: calendarInfo.reminders
    };
};
