import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {BaseBlock} from '../types/base-block.interface';
import {DataBlock} from '../types/block.interface';
import {PageScenarioContextProps} from '../types/page-scenario-context.interface';
import {useFormManager} from '../../core/hooks/use-form-manager';
import {useScenario} from "../hooks/use-scenario";
import {ScenarioService} from "../services/scenario.service";

export interface PageScenarioProviderProps {
    children: React.ReactNode;
    scenario: BaseBlock<DataBlock>[];
}

export const PageScenarioContext = createContext<PageScenarioContextProps | undefined>(undefined);

export const PageScenarioProvider: React.FC<PageScenarioProviderProps> = ({children, scenario}) => {
    const {setLoadingScenario, isReadonlyMode} = useScenario();
    const scenarioService = useMemo(() => new ScenarioService(scenario, isReadonlyMode), [scenario]);
    const [currentPageIndex, setCurrentPageIndex] = useState(scenarioService.getInitialPageIndex());
    const pages = scenarioService.getPages();
    const currentPage = useMemo(() => pages[currentPageIndex] || [], [currentPageIndex, pages]);
    const [isPageCompleted, setIsPageCompleted] = useState<boolean>(false);
    const {validateForms, scrollToInvalidForm} = useFormManager();

    const timeoutPageLoading: number = 1500;


    const handleNextPage = useCallback(async (): Promise<boolean> => {
        if (await validateForms()) {
            setLoadingScenario(true);
            setCurrentPageIndex(prevIndex => prevIndex + 1);
            if (isReadonlyMode) {
                setLoadingScenario(false);
            } else {
                setTimeout(() => {
                    setLoadingScenario(false);
                }, timeoutPageLoading);
            }
            return true;
        } else {
            scrollToInvalidForm();
            return false;
        }
    }, [validateForms, scrollToInvalidForm, setLoadingScenario]);

    const handlePrevPage = useCallback(() => {
        setLoadingScenario(true);
        setCurrentPageIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : 0);
        if (isReadonlyMode) {
            setLoadingScenario(false);
        } else {
            setTimeout(() => {
                setLoadingScenario(false);
            }, timeoutPageLoading);
        }
    }, [pages]);

    useEffect(() => {
        const isCompleted: boolean = scenarioService.isPageCompleted(currentPage)
        setIsPageCompleted(isCompleted);
    }, [currentPage])

    const contextValue = useMemo(() => ({
        currentPageIndex,
        isPageCompleted,
        currentPage: currentPage,
        nextPage: handleNextPage,
        prevPage: handlePrevPage
    }), [currentPageIndex, currentPage, handleNextPage, handlePrevPage]);

    return (
        <PageScenarioContext.Provider value={contextValue}>
            {children}
        </PageScenarioContext.Provider>
    );
};
