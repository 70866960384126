import TextFormField from "../../common/components/forms/text-form-field";
import {Link, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import * as yup from "yup";
import useAuth from "../hooks/useAuth";
import {yupResolver} from "@hookform/resolvers/yup";
import {GetTokenResetPasswordBodyRequest} from "../types/reset-email-body.interface";
import PrimaryButton from "../../common/components/primary-button";

const schema = yup.object({
    email: yup
        .string()
        .email("Nieprawidłowy email")
        .required("Email jest wymagany"),
}).required();

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const {getTokenToResetPassword, loading} = useAuth();

    const methods = useForm<GetTokenResetPasswordBodyRequest>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        delayError: 100,
        disabled: loading,
    });

    const handleEmailSubmit = async (data: GetTokenResetPasswordBodyRequest): Promise<void> => {
        await getTokenToResetPassword(data.email)
            .then(() => navigate('/reset-password'))
            .catch(()=>{})
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleEmailSubmit)} className='flex flex-col gap-y-5'>
                <TextFormField name='email' label='Email' labelClassName='theme-dark:text-gray-700'/>
                <PrimaryButton loading={loading} type='submit'>Wyślij</PrimaryButton>
                <Link to="/login" replace
                      className='text-center w-full block text-sm text-link font-medium hover:text-teal-800 cursor-pointer'>
                    Powrót do poprzedniej strony
                </Link>
            </form>
        </FormProvider>
    )
}

export default ForgotPasswordForm
