import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SkeletonFormField from "../../../common/components/forms/skeleton-form-field";

interface SentenceInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    className?: string;
    index: number;
    isLoading?: boolean;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const SentenceInput: React.FC<SentenceInputProps> = (props: SentenceInputProps) => {
    const [hasError, setHasError] = useState(false);
    const { register, formState: { errors, isSubmitting }, watch, clearErrors } = useFormContext();
    const {
        name,
        className = '',
        isLoading,
        onBlur,
        onChange,
        index,
        ...rest
    } = props;

    const value = watch(name) || "";
    const containerRef = useRef<HTMLInputElement | null>(null);

    const adjustInputWidth = () => {
        // const input = containerRef.current?.querySelector('input');
        // if (input) {
        //     input.style.width = "auto";
        //     input.style.width = `${input.scrollWidth}px`;
        // }
    };

    useEffect(() => {
        adjustInputWidth();
    }, [value]);

    useEffect(() => {
        if (errors?.answers && Array.isArray(errors.answers)) {
            const currentError = errors.answers[index]?.answer;
            setHasError(!!currentError);
        }
    }, [errors, index]);

    return (
        <div className="mb-2 w-full" ref={containerRef}>
            {isLoading
                ? <SkeletonFormField />
                : (
                    <input
                        id={name}
                        className={`w-full p-2 placeholder:text-gray-500 text-gray-900 text-base leading-6 font-normal border 
                            bg-white shadow-sm rounded-md focus:outline-none focus:ring-2 overflow-hidden resize-none
                            ${hasError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-primary_400"} 
                            ${className}`}
                        {...register(name)}
                        placeholder='uzupełnij...'
                        disabled={isSubmitting}
                        onBlur={(e) => {
                            adjustInputWidth();
                            clearErrors('answers');
                            if (onBlur) onBlur(e);
                        }}
                        onChange={(e) => {
                            adjustInputWidth();
                            if (onChange) onChange(e);
                        }}
                        {...rest}
                    />
                )
            }
        </div>
    );
};

export default SentenceInput;
