import React, {createContext, useRef, useState} from "react";
import {CalendarFormMobileHandler} from "../components/mobile/calendar-form-mobile";
import {CalendarFormDesktopHandler} from "../components/desktop/calendar-form-desktop";
import {TypeCalendarEvent} from "../types/type-event.interface";
import {UpdateCalendarBodyInterface} from "../types/update-calendar-body.interface";
import {ModalProvider} from "../../common/contexts/modal-context";

interface CalendarFormContextType {
    openForm: (type: TypeCalendarEvent, event: UpdateCalendarBodyInterface | null) => void;
    refreshKey: number,
    setRefreshKey: (value: number | ((prevValue: number) => number)) => void;
}

export const CalendarFormContext = createContext<CalendarFormContextType | undefined>(undefined);


export const CalendarFormProvider: React.FC<{
    children: React.ReactNode;
    isMobile: boolean;
    getFormComponentMobile: (ref: React.Ref<CalendarFormMobileHandler>) => JSX.Element;
    getFormComponentDesktop: (ref: React.Ref<CalendarFormDesktopHandler>) => JSX.Element;
}> = ({children, isMobile, getFormComponentMobile, getFormComponentDesktop}) => {
    const formRef = useRef<CalendarFormMobileHandler | CalendarFormDesktopHandler>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0);

    const openForm = (type: TypeCalendarEvent, event: UpdateCalendarBodyInterface | null) => {
        if (formRef.current) {
            formRef.current.setTypeCalendarEvent(type);
            formRef.current.showForm(event);
        }
    };
    return (
        <CalendarFormContext.Provider value={{openForm, refreshKey, setRefreshKey}}>
            {children}
            {isMobile
                ? getFormComponentMobile(formRef as React.Ref<CalendarFormMobileHandler>)
                : (
                    <ModalProvider>
                        {getFormComponentDesktop(formRef as React.Ref<CalendarFormDesktopHandler>)}
                    </ModalProvider>
                )
            }
        </CalendarFormContext.Provider>
    );
};
