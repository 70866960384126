import React, {useEffect} from 'react';
import PageRenderer from "./page-renderer";
import {PageScenarioProvider} from "../contexts/page-scenario-context";
import {useScenario} from "../hooks/use-scenario";
import {FormManagerProvider} from "../../core/contexts/form-manager-context";
import PageLoader from "../../common/components/page-loader";

const ScenarioRenderer: React.FC = () => {
    const {scenario} = useScenario();

    return (
        <FormManagerProvider>
            <PageScenarioProvider scenario={scenario}>
                <PageRenderer/>
            </PageScenarioProvider>
        </FormManagerProvider>
    )
};

export default ScenarioRenderer;
