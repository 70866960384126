import {Day} from "../types/day.interface";
import ScheduleTaskItem from "./schedule-task-item";
import CalendarService from "../services/calendar.service";
import {useEffect, useState} from "react";
import {useCalendarForm} from "../hooks/use-calendar-form";

interface ScheduleDayViewProps {
    day: Day,
    index: number,
}

const ScheduleDayView = (props: ScheduleDayViewProps) => {
    const {index, day} = props;

    return (
        <div key={index} className={`flex mb-2`}>
            <div
                className={`w-[40px] h-fit p-1 rounded-lg text-right shadow-lg pr-2 bg-gray-50 ${CalendarService.isToday(day.tasks[0].startDate) ? 'text-orange-400' : 'text-gray-800'}`}>
                <div className="text-gray-700 text-base leading-6 font-normal">{day.weekday}</div>
                <div className="text-2xl leading-8 font-semibold">{day.date}</div>
            </div>
            <div className="flex-1 pl-4">
                {day.tasks.map((task, i) =>
                    <ScheduleTaskItem
                        key={i}
                        task={task}
                        index={i}
                        nextTaskStartDateString={day.tasks[i + 1]?.startDate || null}/>)
                }
            </div>
        </div>
    )

}

export default ScheduleDayView
