interface TaskHeaderProps {
    numberOfTask: number | null,
    text: string,
    className?: string,
}

const TaskHeader = (props: TaskHeaderProps) => {
    const {numberOfTask, text,className } = props

    return (
        <header>
            <h3 className={`text-base leading-6 font-semibold text-dark ${className}`}>
                {numberOfTask}. {text}
            </h3>
        </header>
    )
}

export default TaskHeader
