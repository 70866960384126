import React, {useState} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import {IVideoBlock} from "../types/blocks/video-block.interface";
import ReactPlayer from "react-player";


const VideoBlock: React.FC<BaseBlock<IVideoBlock>> = (props: BaseBlock<IVideoBlock>) => {
    const {data, interactiveTaskNumber} = props;
    const {url} = data;

    const [isLoading, setIsLoading] = useState(true);

    const handleReady = () => {
        setIsLoading(false);
    };

    return (
        <div className="relative w-full h-0 pb-[56.25%] overflow-hidden rounded-xl">
            {isLoading && (
                <div className="absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center">
                    <span className='font-semibold text-sm text-gray-700'>Ładowanie video...</span>
                </div>
            )}
            <ReactPlayer
                url={url}
                onReady={handleReady}
                className="absolute top-0 left-0 w-full h-full"
                width="100%"
                height="100%"
                controls
            />
        </div>
    );
}

export default VideoBlock;
