import MonthCalendarMobile from "../components/mobile/month-calendar-mobile";
import Schedule from "../components/schedule";
import React from "react";

const MobileCalendar = () => {
    return (
        <div>
            <MonthCalendarMobile/>
            <Schedule/>
        </div>
    )
}

export default MobileCalendar

