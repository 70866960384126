import React, {useEffect, useRef} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import {ISingleChoiceBlock} from "../types/blocks/single-choice-block.interface";
import TaskHeader from "../components/ui/task-header";
import {useFormManager} from "../../core/hooks/use-form-manager";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {yupResolver} from "@hookform/resolvers/yup";
import {defaultFormConfig} from "../constants/default-form-config";
import RadioFormField from "../../common/components/forms/radio-form-field";

interface FormValues {
    selectedAnswer: string;
}

const SingleChoiceBlock: React.FC<BaseBlock<ISingleChoiceBlock>> = (props: BaseBlock<ISingleChoiceBlock>) => {
    const {data, interactiveTaskNumber, id, blockCode, readonly} = props;
    const {selectedAnswer, answers, question} = data;
    const {registerForm, unregisterForm} = useFormManager();
    const {sendUserAnswer} = useDynamicViewApi();

    const schema = Yup.object().shape({
        selectedAnswer: Yup.string()
            .required('Proszę wybrać odpowiedź.'),
    }).required();

    const methods = useForm<FormValues>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        defaultValues: {
            selectedAnswer: selectedAnswer ? selectedAnswer.id.toString() : '',
        },
        disabled: readonly,
    });

    const originalValueRef = useRef(methods.getValues('selectedAnswer'));

    const onSubmit = (data: FormValues) => {
        sendUserAnswer(id, blockCode, {blockContentId: data.selectedAnswer})
            .then()
            .catch()
            .finally()
    };

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    const value = methods.watch('selectedAnswer');

    useEffect(() => {
        if (value !== originalValueRef.current) {
            onSubmit(methods.getValues());
            originalValueRef.current = value;
        }
    }, [value]);

    return (
        <FormProvider {...methods}>
            <form className='flex flex-col gap-3' id={id.toString()}>
                <TaskHeader numberOfTask={interactiveTaskNumber} text={question}/>
                {answers?.map((answer) => (
                    <RadioFormField
                        key={answer.id}
                        disabled={readonly}
                        showError={false}
                        name="selectedAnswer"
                        label={answer.name}
                        onFocus={() => methods.clearErrors()}
                        value={answer.id.toString()}
                    />
                ))}
                {readonly}
                {methods.formState.errors['selectedAnswer'] &&
                    <p className="text-red-500 text-xs">{'' + methods.formState.errors!['selectedAnswer']!.message}</p>}
            </form>
        </FormProvider>
    );
}

export default SingleChoiceBlock;

