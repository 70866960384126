import React, {createContext, useRef} from 'react';
import * as Yup from 'yup';

export interface FormManagerContextProps {
    registerForm: (id: number, schema: Yup.AnyObjectSchema, getData: () => any, trigger: () => any) => void;
    unregisterForm: (id: number) => void;
    validateForms: () => Promise<boolean>;
    scrollToInvalidForm: () => void;
}

export const FormManagerContext = createContext<FormManagerContextProps | undefined>(undefined);

export const FormManagerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const formsRef = useRef<Array<{ id: number, schema: Yup.AnyObjectSchema, getData: () => any, trigger: () => any }>>([]);
    const invalidFormRef = useRef<number | null>(null);

    const registerForm = (id: number, schema: Yup.AnyObjectSchema, getData: () => any, trigger: () => any) => {
        formsRef.current.push({ id, schema, getData, trigger });
    };

    const unregisterForm = (id: number) => {
        formsRef.current = formsRef.current.filter(form => form.id !== id);
    };

    const validateForms = async (): Promise<boolean> => {
        let isValid = true;

        for (const { id,trigger } of formsRef.current) {
            const result = await trigger();
            if (!result) {
                isValid = false;
                invalidFormRef.current = id;
                break;
            }
        }

        return isValid;
    };

    const scrollToInvalidForm = () => {
        if (invalidFormRef.current !== null) {
            const invalidFormElement = document.getElementById(invalidFormRef.current.toString());
            if (invalidFormElement) {
                invalidFormElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
            invalidFormRef.current = null;
        }
    };

    return (
        <FormManagerContext.Provider value={{ registerForm, unregisterForm, validateForms, scrollToInvalidForm }}>
            {children}
        </FormManagerContext.Provider>
    );
};
