import React from 'react';
import Button from "./primary-button";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import {useContextInstallPwa} from "../contexts/installer-pwa-context";


interface InstallPwaButtonProps {
    onClick: () => void;
}

const InstallPwaButton = ({onClick}: InstallPwaButtonProps) => {
    const {installPWA, isInstalled} = useContextInstallPwa()

    const handleClick = () => {
        installPWA().then(() => onClick())
    }

    if (isInstalled) return null;

    return (
        <div className="flex flex-col justify-between bg-primary_500 p-4">
            <div className="flex-1">
                <p className="text-white text-sm">
                    Zainstaluj aplikację i ciesz się jeszcze lepszym działaniem!
                </p>
            </div>
            <div className="mt-4 flex md:justify-end">
                <Button
                    onClick={handleClick}
                    className="flex !items-center justify-center bg-primary text-white text-sm px-6 py-3 rounded-md hover:bg-primary-dark transition-colors duration-300"
                >
                    <ArrowDownTrayIcon className="w-4 h-4 mr-2"/>
                    <span className='relative top-[2px]'>
                        Zainstaluj
                    </span>

                </Button>
            </div>
        </div>
    );
};

export default InstallPwaButton;
