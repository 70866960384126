let env;

switch (process.env.REACT_APP_ENV) {
  case 'prod':
    env = require('./environment.prod').environment;
    break;
  case 'test':
    env = require('./environment.test').environment;
    break;
  case 'dev':
    env = require('./environment.dev').environment;
    break;
  default:
    env = require('./environment.local').environment;
}

export const environment = env;