import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import PageLoader from "../../common/components/page-loader";
import {AuthenticatedUser} from "../types/authenticated-user.interface";

const AccountActivation = () => {
    const {token, userId} = useParams();
    const navigate = useNavigate();
    const {activateAccount, hasEmailConfirmed, updateAuthenticationField, currentUser} = useAuth();

    useEffect(() => {
        if (!hasEmailConfirmed()) {
            if (token && userId) {
                activateAccount(token, userId)
                    .then(() => {
                        const activatedUserData: AuthenticatedUser = {
                            ...currentUser!,
                            emailConfirmed: true,
                        }
                        updateAuthenticationField('user', activatedUserData);
                    })
                    .then(() => {
                        navigate('/main');
                    })
                    .catch(() => navigate('/main'));
            }
        } else {
            navigate('/main');
        }
    }, [token, userId]);

    return (
        <div className="flex flex-col gap-12 justify-center items-center h-screen">
            <div>
                <PageLoader/>
            </div>
        </div>
    );
};

export default AccountActivation;
