import React from 'react';
import {IHeaderBlock as IHeaderBlock} from "../types/blocks/header-block.interface";
import {BaseBlock} from "../types/base-block.interface";
import DOMPurify from "dompurify";

const HeaderBlock: React.FC<BaseBlock<IHeaderBlock>> = (props: BaseBlock<IHeaderBlock>) => {
    const { data } = props;
    const { titleHTML } = data;

    const createMarkup = (html: string) => {
        return {
            __html: DOMPurify.sanitize(html, {
                ADD_ATTR: ['target'],
                RETURN_DOM: false
            })
        };
    };

    return (
        <h3 className='leading-7 text-2xl font-bold' dangerouslySetInnerHTML={createMarkup(titleHTML)}></h3>
    )
}

export default HeaderBlock;
