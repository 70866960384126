
interface EmptyListProps{
    content: string;
}

const EmptyList = (props: EmptyListProps) => {
    const {content} = props

    return(
        <div className='text-xl font-semibold bg-white rounded-2xl p-5 text-center'>
            {content}
        </div>
    )
}

export default EmptyList
