import ProfileDataForm from "../modules/settings/components/profile-data-form";
import PurchasedSubscriptions from "../modules/subscriptions/components/purchased-subscriptions";

const SettingsView = () => {

    return (
        <div className='h-full overflow-y-auto flex flex-col'>
            <ProfileDataForm/>
            <PurchasedSubscriptions/>
        </div>
    )
}

export default SettingsView
