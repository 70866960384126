import ApiService from "../../core/services/api.service";
import {CalendarEvent} from "../types/calendar-event.interface";
import {CalendarEventRequestBody} from "../types/calendar-event-request-body.interface";
import {TypeCalendarEvent} from "../types/type-event.interface";

class CalendarApiService extends ApiService {

    constructor() {
        super();
    }

    public async getCalendarEvents(startDate: string, endDate: string): Promise<Array<CalendarEvent>> {
        return await this.get<Array<CalendarEvent>>('/calendars/all-user-tasks-events', {
            params: {
                startDate,
                endDate
            }
        })
            .then(res => res)
    }

    public async createCalendarEvent(body: CalendarEventRequestBody): Promise<void> {
        const isTask = body.calendarTypeId === TypeCalendarEvent.TASK;
        return await this.post<void>('/calendars', body, {
            successMessage: `Dodano ${isTask ? 'zadanie' : 'wydarzenie'} do kalendarza!`,
            errorMessage: `Nie udało się dodać nowego ${isTask ? 'zadania' : 'wydarzenia'}.`
        })
    }

    public async updateCalendarEvent(eventId: number, body: CalendarEventRequestBody): Promise<void> {
        const isTask = body.calendarTypeId === TypeCalendarEvent.TASK;
        return await this.put<void>(`/calendars/${eventId}`, body, {
            successMessage: `Zaktualizowano ${isTask ? 'zadanie' : 'wydarzenie'}!`,
            errorMessage: `Nie udało się zedytować ${isTask ? 'zadania' : 'wydarzenia'}.`
        })
    }

    public async changeCompleted(eventId: number): Promise<void> {
        return await this.put<void>(`/calendars/${eventId}/completed`, {},{
            errorMessage: `Nie udało się zmienić statusu.`
        })
    }

    public async deleteCalendarEvent(eventId: number): Promise<void> {
        return await this.delete<void>(`/calendars/${eventId}`,{
            errorMessage: `Nie udało się usunąć wpisu kalendarza.`,
            successMessage:"Usunięto wpis z kalendarza."
        })
    }
}

export default new CalendarApiService();
