import {TrashIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import {classNames} from "../../../utils/class-names";
import Spinner from "./spinner";

interface DeleteEntityShortcutProps {
    onDelete: () => void | Promise<void>;
}

const DeleteEntityShortcut = (props: DeleteEntityShortcutProps) => {
    const {onDelete} = props;
    const [isHoveredOrFocused, setIsHoveredOrFocused] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleMouseEnter = () => {
        setIsHoveredOrFocused(true);
    };

    const handleMouseLeave = () => {
        setIsHoveredOrFocused(false);
    };

    const handleFocus = () => {
        setIsHoveredOrFocused(true);
    };

    const handleBlur = () => {
        setIsHoveredOrFocused(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await onDelete();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='relative w-full h-full flex flex-col gap-3 justify-center items-center cursor-pointer'
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onFocus={handleFocus}
             onTouchStart={handleFocus}
             onBlur={handleBlur}
             onClick={handleDelete}
             onTouchEnd={handleBlur}>
            <TrashIcon className={classNames(
                (isHoveredOrFocused && !isLoading) && 'text-red-700',
                isLoading && 'text-gray-400',
                'duration-200 w-6 h-6'
            )}/>
            <span className={classNames(
                (isHoveredOrFocused && !isLoading) && 'text-red-700',
                isLoading && 'text-gray-400',
                'duration-200 text-[10px] text-gray-600 leading-none'
            )}>Usuń</span>

            {isLoading && (
                <div className='absolute w-full h-full top-0 left-0 flex justify-center items-center'>
                    <Spinner/>
                </div>
            )}
        </div>
    )
}

export default DeleteEntityShortcut;
