import React, {createContext, FC, ReactNode, useState} from 'react';

export interface OverlayContextProps {
    OverlayComponent: ReactNode | null;
    setOverlayComponent: (component: ReactNode | null) => void;
}

export const OverlaySidebarMenuContext = createContext<OverlayContextProps | undefined>(undefined);

export const OverlaySidebarMenuProvider: FC<{ children: ReactNode }> = ({children}) => {
    const [OverlayComponent, setOverlayComponent] = useState<ReactNode | null>(null);

    return (
        <OverlaySidebarMenuContext.Provider value={{OverlayComponent, setOverlayComponent}}>
            {children}
        </OverlaySidebarMenuContext.Provider>
    );
};
