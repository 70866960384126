import DynamicView from "./dynamic-view";

// Exporting rendering components
export default DynamicView;
export {default as BlockRenderer} from './components/block-renderer';
export {default as PageRenderer} from './components/page-renderer';
export {default as ScenarioRenderer} from './components/scenario-renderer';

// Exporting all block components
export {default as HeaderBlock} from './blocks/header-block';
export {default as ParagraphBlock} from './blocks/paragraph-block';
export {default as ShortAnswerBlock} from './blocks/short-answer-block';
export {default as LongAnswerBlock} from './blocks/long-answer-block';
export {default as VideoBlock} from './blocks/video-block';
export {default as SingleChoiceBlock} from './blocks/single-choice-block';
export {default as MultipleChoiceFixedCountBlock} from './blocks/multiple-choice-fixed-count-block';
export {default as MultipleChoiceFixedRangeBlock} from './blocks/multiple-choice-fixed-range-block';
export {default as MultipleChoiceAnyBlock} from './blocks/multiple-choice-any-block';
export {default as NextStepAsAnswerBlock} from './blocks/next-step-as-answer-block';
export {default as NextStepBlock} from './blocks/next-step-block';
export {default as SentenceCompletionBlock} from './blocks/sentence-completion-block';
export {default as AddToCalendarManualBlock} from './blocks/add-to-calendar-manual-block';
export {default as AddToCalendarAutomaticBlock} from './blocks/add-to-calendar-automatic-block';
export {default as PhrasePyramidBlock} from './blocks/phrase-pyramid-block';
export {default as AudioRecordingBlock} from './blocks/audio-recording-block';
export {default as VideoRecordingBlock} from './blocks/video-recording-block';
export {default as SeparatorBlock} from './blocks/separator-block';
export {default as AnimationBlock} from './blocks/animation-block';
export {default as PollBlock} from './blocks/poll-block';

// Exporting enums
export {BLOCK_CODE} from './types/block-codes.interface';
export {BLOCK_TYPE} from './types/block-types.interface';
export {BLOCK_CATEGORY} from './types/block-categories.interface';

// Exporting shared types and interfaces
export type {Range} from './types/range-multiple-choices.interface';
export type {CalendarInfo} from './types/calendar-info.interface';
export type {UserAnswer} from './types/user-answer.interface';
export type {BlockContent} from './types/block-content.interface';
export type {BlockContentAnswer} from './types/block-content-answer.interface';
export type {BaseBlock} from './types/base-block.interface';

// Exporting block-specific interfaces
export type {IShortAnswerBlock as SHORT_ANSWER} from './types/blocks/short-answer-block.interface';
export type {ILongAnswerBlock as LONG_ANSWER} from './types/blocks/long-answer-block.interface';
export type {ISingleChoiceBlock as SINGLE_CHOICE} from './types/blocks/single-choice-block.interface';
export type {
    IMultipleChoiceFixedRangeBlock as MULTIPLE_CHOICE_FIXED_RANGE
} from './types/blocks/multiple-choice-fixed-range-block.interface';
export type {
    IMultipleChoiceFixedCountBlock as MULTIPLE_CHOICE_FIXED_COUNT
} from './types/blocks/multiple-choice-fixed-count-block.interface';
export type {IMultipleChoiceAnyBlock as MULTIPLE_CHOICE_ANY} from './types/blocks/multiple-choice-any-block.interface';
export type {IPhraseCompletionBlock as PHRASE_COMPLETION} from './types/blocks/phrase-completion-block.interface';
export type {IPhrasePyramidBlock as PHRASE_PYRAMID} from './types/blocks/phrase-pyramid-block.interface';
export type {IAudioRecordingBlock as AUDIO_RECORDING} from './types/blocks/audio-recording-block.interface';
export type {IVideoRecordingBlock as VIDEO_RECORDING} from './types/blocks/video-recording-block.interface';
export type {
    IAddToCalendarManualBlock as ADD_TO_CALENDAR_MANUAL
} from './types/blocks/add-to-calendar-manual-block.interface';
export type {IPollBlock as POLL} from './types/blocks/poll-block.interface';
export type {IHeaderBlock as HEADER} from './types/blocks/header-block.interface';
export type {IParagraphBlock as PARAGRAPH} from './types/blocks/paragraph-block.interface';
export type {IVideoBlock as VIDEO} from './types/blocks/video-block.interface';
export type {IAnimationBlock as ANIMATION} from './types/blocks/animation-block.interface';
export type {INextStepBlock as NEXT_STEP} from './types/blocks/next-step-block.interface';
export type {INextStepAsAnswerBlock as NEXT_STEP_AS_ANSWER} from './types/blocks/next-step-as-answer-block.interface';
export type {
    IAddToCalendarAutomaticBlock as ADD_TO_CALENDAR_AUTOMATIC
} from './types/blocks/add-to-calendar-automatic-block.interface';
export type {ISeparatorBlock as SEPARATOR} from './types/blocks/seperator-block.interface';
