import React, {useEffect, useRef, useState} from 'react';
import {Note as NoteInterface} from "./types/note.interface";
import noteService from "./services/note-service.service";
import Note from "./components/note";
import ButtonAddNote from "./components/button-add-note";
import {useNoteApi} from "./hooks/useNoteApi";
import NoteForm from "./components/note-form";
import {classNames} from "../../utils/class-names";
import {useOverlay} from "../core/hooks/useOverlaySidebarMenu";
import DeleteEntityShortcut from "../common/components/delete-entity-shortcut";
import PromptAction from "../common/components/prompt-action";
import {PromptActionHandle} from "../common/types/prompt-action-handle.interface";
import {TrashIcon} from "@heroicons/react/24/outline";
import PrimaryButton from "../common/components/primary-button";
import {PlusCircleIcon} from "@heroicons/react/24/solid";

export const Notes: React.FC = () => {
    const [notes, setNotes] = useState<NoteInterface[]>([]);
    const [previewNote, setPreviewNote] = useState<NoteInterface | undefined>(undefined);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [countNotes, setCountNotes] = useState<number>(0);
    const [selectedNotes, setSelectedNotes] = useState<number[]>([]);
    const [isSelectionMode, setIsSelectionMode] = useState<boolean>(false);
    const deletePromptRef = useRef<PromptActionHandle>(null);
    const {setOverlayComponent} = useOverlay();
    const {getNotes} = useNoteApi();

    const fetchNotes = () => {
        getNotes().then(fetchedNotes => {
            setNotes(fetchedNotes);
            setCountNotes(fetchedNotes.length);
        });
    };

    const handleEditNote = (note: NoteInterface) => {
        setPreviewNote(note);
        setShowForm(true);
    };

    const closeNoteEditor = (reloading: boolean = false) => {
        setPreviewNote(undefined);
        setShowForm(false);
        reloading && fetchNotes();
    };

    const handleLongPress = (note: NoteInterface) => {
        setIsSelectionMode(true);
        handleSelectNote(note);
    };

    const handleSelectNote = (note: NoteInterface) => {
        setSelectedNotes(prevSelected => {
            const isSelected = prevSelected.includes(note.id);
            return isSelected
                ? prevSelected.filter(noteId => noteId !== note.id)
                : [...prevSelected, note.id];
        });
    };

    const handleSelectAll = () => {
        const allNoteIds = notes.map(note => note.id);
        setSelectedNotes(allNoteIds);
    };

    const handleCancelSelection = () => {
        setIsSelectionMode(false);
        setSelectedNotes([]);
        setOverlayComponent(undefined);
    };

    const handleDeleteSelected = async (): Promise<any> => {
        return await Promise
            .all(selectedNotes.map(id => noteService.deleteNote(id)))
            .then(() => {
                handleCancelSelection();
                fetchNotes();
            })
    };

    const renderNotes = (notes: NoteInterface[]) => {
        return notes.map((note, id) => (
            <Note
                key={id}
                note={note}
                onClick={isSelectionMode ? handleSelectNote : handleEditNote}
                onLongPress={handleLongPress}
                isSelectable={isSelectionMode}
                isSelected={selectedNotes.includes(note.id)}
            />
        ));
    };

    useEffect(() => {
        if (isSelectionMode && selectedNotes.length > 0) {
            setOverlayComponent(<DeleteEntityShortcut onDelete={deletePromptRef.current!.open}/>);
        }
    }, [selectedNotes, isSelectionMode]);

    useEffect(() => {
        fetchNotes();
        return () => {
            setOverlayComponent(undefined);
        };
    }, []);

    return (
        <>
            <section className={classNames('overflow-y-auto h-full p-1 mt-0 sm:mt-10')}>
                {isSelectionMode ? (
                    <header className='flex flex-col justify-between mb-6'>
                        <h1 className='font-bold text-2xl'>Wybrano {selectedNotes.length}</h1>
                        <div className='flex justify-between items-center mt-2'>
                            <span onClick={handleCancelSelection}
                                  className='text-base leading-6 font-bold cursor-pointer
                                  theme-dark:hover:text-gray-300 theme-dark:focus:text-gray-600 hover:text-gray-600
                                  focus:text-gray-600
                                 '>Anuluj</span>
                            <div className='flex gap-5 flex-wrap items-center'>
                                <span onClick={handleSelectAll}
                                      className='text-right w-fit h-fit sm:max-w-max max-w-20 text-base leading-6
                                      font-bold cursor-pointer hover:text-gray-600 focus:text-gray-600
                                      theme-dark:hover:text-gray-300 theme-dark:focus:text-gray-600'>
                                    Wybierz wszystko
                                </span>
                                {selectedNotes.length
                                    ? (
                                        <button
                                            onClick={deletePromptRef.current!.open}
                                            className='hidden sm:block bg-white focus:bg-red-300 focus:text-white focus:border-red-400
                                            hover:bg-red-300 hover:text-white hover:border-red-400 border-[1px] text-gray-900
                                            border-gray-300 rounded-3xl h-fit py-2 px-3 text-sm leading-4 font-semibold
                                            shadow-sm theme-dark:hover:bg-red-500 theme-dark:focus:bg-red-500
                                            theme-dark:hover:border-red-600 theme-dark:focus:border-red-600'>
                                            Usuń wybrane
                                        </button>
                                    )
                                    : null
                                }
                            </div>
                        </div>
                    </header>
                ) : (
                    <header className='flex flex-col'>
                        <div className='flex flex-col gap-2'>
                            <h1 className='font-bold text-2xl '>Twoje notatki</h1>
                            <span className='text-base'>{countNotes} notatek</span>
                        </div>
                        <PrimaryButton
                            onClick={() => setShowForm(true)}
                            styleClass='hidden sm:flex flex gap-2 items-center h-fit max-w-fit px-3 py-2 text-sm
                            font-semibold bg-primary_600 hover:bg-primary_600 mt-7 mb-4'>
                            <PlusCircleIcon className='w-6 h-6'/>
                            <span>Dodaj notatkę</span>
                        </PrimaryButton>
                    </header>
                )}
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-8'>
                    {renderNotes(notes)}
                </div>
                {!showForm && !isSelectionMode && <ButtonAddNote openForm={() => setShowForm(true)}/>}
            </section>
            {showForm && <NoteForm previewNote={previewNote} closeForm={closeNoteEditor.bind(this)}/>}
            <PromptAction ref={deletePromptRef}
                          variant='error'
                          customIcon={<TrashIcon/>}
                          title='Usunąć notatki?'
                          message='Notatki zostaną bezpowrotnie usunięte bez możliwości ich przywrócenia.'
                          onConfirm={handleDeleteSelected}/>
        </>
    );
};

export default Notes;
