import React, {useEffect} from 'react';
import BlockRenderer from './block-renderer';
import {useScenario} from "../hooks/use-scenario";
import {usePageScenario} from "../hooks/use-page-scenario";
import PageLoader from "../../common/components/page-loader";
import {scrollToTop} from "../../common/helpers/scroll-to-top";

const PageRenderer: React.FC = () => {
    const {containerRef} = useScenario()
    const {currentPage, currentPageIndex} = usePageScenario();
    const {isLoadingScenario} = useScenario();

    useEffect(() => {
        scrollToTop(containerRef);
    }, [currentPage]);

    if (isLoadingScenario) return <PageLoader/>;

    return (
        <div className='flex flex-col gap-6 pb-6'>
            {currentPage.map(block => (
                <BlockRenderer key={block.id} block={block}/>
            ))}
        </div>
    );
};

export default PageRenderer;
