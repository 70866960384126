import React from 'react';
import {Week} from "../types/week.interface";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import ScheduleDayView from "./schedule-day-view";
import {getSeason} from "../helpers/get-season";
import {useTheme} from "../../core/hooks/use-theme";
import withAnyProvider from "../../common/hoc/withAnyProvider";
import {ScheduleProvider, useSchedule} from "../contexts/schedule-context";

const Schedule: React.FC = () => {
    const {schedule, currentMonthAndYear, currentDate, handleMonthChange} = useSchedule();
    const {theme} = useTheme();

    const getBackgroundClass = () => {
        const month = currentDate.getMonth();
        const season = getSeason(month);
        return `bg-${season}-${theme}`;
    };

    return (
        <div className="rounded-lg">
            <div
                className={`flex justify-between my-4 py-6 px-3 bg-gray-200 text-2xl leading-8 font-bold ${getBackgroundClass()}`}>
                <button onClick={() => handleMonthChange(-1)}>
                    <ChevronLeftIcon className="w-6 h-6 text-gray-600 theme-dark:text-gray-50"/>
                </button>
                <span className='first-letter:uppercase'>{currentMonthAndYear}</span>
                <button onClick={() => handleMonthChange(1)}>
                    <ChevronRightIcon className="w-6 h-6 text-gray-600 theme-dark:text-gray-50"/>
                </button>
            </div>
            {schedule.length > 0 ? (
                schedule.map((week: Week, weekIndex: number) => (
                    <div key={weekIndex} className="mb-6">
                        <div className="mb-2 text-xl leading-8 font-normal">{week.label}</div>
                        {week.days
                            .filter(day => day.tasks.length > 0)
                            .map((day, i) => <ScheduleDayView key={i} day={day} index={i}/>)
                        }
                    </div>
                ))
            ) : (
                <div className="text-center text-gray-500 text-xl mt-10 theme-dark:text-gray-50">
                    Brak wydarzeń / zadań w tym miesiącu.
                </div>
            )}
        </div>
    );
};

export default withAnyProvider(ScheduleProvider, Schedule);
