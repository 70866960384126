import ApiService from "../../core/services/api.service";
import {TypeParent} from "../types/type-parent.interface";
import {BlockDTO} from "../types/get-blocks-response.interface";

class DynamicViewApiService extends ApiService {

    constructor() {
        super();
    }

    public async getBlocks(parentName: string, typeParent: TypeParent): Promise<Array<BlockDTO>> {
        return await this.get<Array<BlockDTO>>(`/user-blocks`, {
            params: {
                parentName:parentName,
                parent: typeParent,
            },
            skipDefault404Handling: true,
        }).then(res => res || [])
    }

    public async updateBlock(blockId: number, path: string, body: any): Promise<Array<BlockDTO>> {
        return await this.put<Array<BlockDTO>>(`/user-blocks/${blockId}/${path}`, body, {
            skipDefault404Handling: true
        }).then()
    }

    public async createPureUserBlocks(parentName: string, typeParent: TypeParent): Promise<Array<BlockDTO>> {
        return await this.post<Array<BlockDTO>>(`/user-blocks`, {}, {
            skipDefault404Handling: true,
            params: {
                parentName:parentName,
                parent: typeParent
            }
        }).then()
    }
}

export default new DynamicViewApiService();
