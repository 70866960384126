import PrimaryButton from "../../common/components/primary-button";
import {useStepperIntroduction} from "../hooks/use-steps-introduction";


interface PropsNextStepButton {
    customHandler?: boolean,
    handler?: () => any,
}

const NextStepButton = ({customHandler = false, handler}: PropsNextStepButton) => {
    const {nextStep} = useStepperIntroduction()

    const handleClick = customHandler ? handler : nextStep

    return (
        <div className='h-fit min-h-max'>
            <PrimaryButton onClick={handleClick}>Przejdź dalej</PrimaryButton>
        </div>
    )
}

export default NextStepButton
