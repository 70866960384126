import {TypeParent} from "../../view-builder/types/type-parent.interface";
import DynamicView from "../../view-builder";
import ChapterCompletedPrompt from "./chapter-completed-prompt";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import PageLoader from "../../common/components/page-loader";
import useAreaApi from "../hooks/use-area-api";

const Chapter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const chapterName: string | undefined = params['chapter'] as string | undefined;
    const [isCompletedChapter, setIsCompletedChapter] = useState<boolean | undefined>(location.state?.isCompleted ?? false);
    const [chapterId, setChapterId] = useState<number | undefined>(location.state?.chapterId ?? undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const {fetchChapterIsCompleted} = useAreaApi();

    useEffect(() => {
        if (!chapterId && !isCompletedChapter) {
            setLoading(true);
            if (chapterName) {
                fetchChapterIsCompleted(chapterName)
                    .then((data) => {
                        setIsCompletedChapter(data[0].isActive)
                    })
                    .catch(error => console.error('Error fetching chapter status:', error))
                    .finally(() => setLoading(false));
            } else {
                navigate("../")
            }
        }
    }, [chapterName]);

    if (loading) {
        return <PageLoader/>
    }

    return (
        <div className='h-full'>
            <DynamicView
                isCompleted={isCompletedChapter}
                errorFetching='Nie udało się załadować rozdziału.'
                titleParamName='chapter'
                typeView={TypeParent.CHAPTER}/>
            <ChapterCompletedPrompt isCompleted={isCompletedChapter!} chapterId={chapterId!}/>
        </div>
    );
}

export default Chapter;
