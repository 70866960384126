import React from "react";

type LogoProps = {
    size: "sm" | "md" | "lg" | "xl"
}

const Logo = (props: LogoProps) => {

    const {size} = props

    const sizeClasses = {
        sm: 'text-2xl w-16 h-16',
        md: 'text-3xl w-20 h-20',
        lg: 'text-4xl w-24 h-24',
        xl: 'text-5xl w-28 h-28',
    };

    return (
        <div className="flex justify-center mb-4">
            <div
                className={`text-gray-300 font-bold flex items-center justify-center rounded-full ${sizeClasses[size]}`}
            >
                <img  alt='logo' src={require('../../../assets/images/logo_256x256.png')}/>
            </div>
        </div>
    );
}

export default Logo
