import {useNoteApi} from "../hooks/useNoteApi";
import React, {useEffect, useRef, useState} from "react";
import {Note as NoteInterface} from "../types/note.interface";
import {XMarkIcon} from "@heroicons/react/20/solid";
import PrimaryButton from "../../common/components/primary-button";
import {DateHelper} from "../../core/utils/dateHelper";
import PromptAction from "../../common/components/prompt-action";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {PromptActionHandle} from "../../common/types/prompt-action-handle.interface";

interface NoteFormProps {
    closeForm: (reloading?: boolean) => void;
    previewNote?: NoteInterface;
}

const NoteForm = (props: NoteFormProps) => {
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [editingNote, setEditingNote] = useState<NoteInterface | undefined>(props.previewNote);
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const parentTextareaRef = useRef<HTMLDivElement>(null);
    const promptRef = useRef<PromptActionHandle>(null);
    const {closeForm} = props;
    const {addNote, updateNote} = useNoteApi();

    const handleAddOrUpdateNote = async () => {
        if (editingNote) {
            updateNote({...editingNote, title, content}).then(() => performClose(true));
        } else {
            addNote({title, content}).then(() => performClose(true));
        }
    };

    const handleClose = (reloading?: boolean) => {
        if (hasChanges) {
            promptRef.current?.open();
        } else {
            performClose(reloading);
        }
    };

    const performClose = (reloading?: boolean) => {
        setIsClosing(true);
        setTimeout(() => {
            reset();
            closeForm(reloading);
        }, 400);
    };

    const autoResize = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            textareaRef.current.style.maxHeight = `${Number(parentTextareaRef.current?.offsetHeight) - 20}px`
        }
    };

    const reset = () => {
        setTitle("");
        setContent("");
        setEditingNote(undefined);
        setHasChanges(false);
    };

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>, value: string) => {
        setter(value);
        setHasChanges(true);
    };

    useEffect(() => {
        if (editingNote) {
            setTitle(editingNote.title);
            setContent(editingNote.content);
        }
    }, [editingNote]);

    useEffect(() => {
        autoResize();
    }, [content]);

    return (
        <section
            className={`max-h-full flex flex-col absolute left-0 h-full w-full z-[4] bg-gray-50 overflow-hidden pt-6 ${isClosing ? 'animate-slide-down' : 'animate-slide-up'}`}>
            <div className="min-h-fit flex flex-col gap-2 border-b-[1px] border-gray-400 p-4 pt-0 pb-3 ">
                <aside className="flex justify-between sm:justify-end sm:gap-5 items-center">
                    <div className="w-16 h-16 flex items-center justify-center focus:border-2 cursor-pointer "
                         onClick={() => handleClose()}>
                        <XMarkIcon className="w-6 h-6 text-gray-800 sm:hidden block"/>
                        <button className="hidden sm:block px-3 py-2 rounded-3xl h-fit min-w-fit font-semibold
                        leading-4 text-gray-700  text-sm bg-white border-[1px] border-gray-300 shadow-sm">
                            Anuluj
                        </button>
                    </div>
                    <PrimaryButton
                        disabled={!hasChanges}
                        onClick={handleAddOrUpdateNote}
                        styleClass="max-w-fit px-4 py-2 rounded-3xl h-fit font-semibold text-sm bg-primary_700 focus:bg-primary_800"
                    >
                        Zapisz
                    </PrimaryButton>
                </aside>
                <p className="text-xs text-gray-500 leading-4 font-normal">
                    <span>{DateHelper.formatDate(new Date(), "DD.MM.YYYY HH:mm")}</span>
                    <span className="mx-2">|</span>
                    <span>{content.length} znaków</span>
                </p>
            </div>
            <div className='bg-white justify-center h-full max-h-full flex flex-col overflow-hidden'>
                <div
                    className="flex flex-col p-4 text-gray-900 h-full max-w-[900px] w-full flex-grow pb-0 mx-auto">
                    <div className="flex flex-col flex-shrink-0">
                        <label className='flex gap-2 p-2'>
                            <PencilSquareIcon
                                className='sm:h-5 sm:w-5 w-4 h-4 min-h-4 min-w-4 text-primary_700 cursor-pointer mt-3'/>
                            <input
                                className="p-3 text-lg font-bold leading-7 placeholder:text-gray-500 w-full focus:outline-0
                                focus:ring-1 focus:ring-offset-2 ring-primary_600 rounded-md"
                                type="text"
                                placeholder="Nagłówek..."
                                value={title}
                                onChange={(e) => handleInputChange(setTitle, e.target.value)}
                            />
                        </label>
                    </div>
                    <div ref={parentTextareaRef} className='h-full overflow-hidden'>
                        <label className='flex gap-2 max-h-min overflow-hidden w-full h-min p-2'>
                            <PencilSquareIcon
                                className='sm:h-5 sm:w-5 w-4 h-4 text-primary_700 cursor-pointer mt-2 flex-grow-1'/>
                            <textarea
                                ref={textareaRef}
                                className="p-3 max-h-min text-base font-normal leading-none placeholder:text-gray-500 resize-none overflow-y-auto w-full
                                focus:outline-0 focus:ring-1 focus:ring-offset-2 ring-primary_600 rounded-md h-full"
                                placeholder="Treść notatki..."
                                value={content}
                                onChange={(e) => {
                                    handleInputChange(setContent, e.target.value);
                                    autoResize();
                                }}
                            />
                        </label>
                    </div>
                </div>
            </div>
            <PromptAction
                ref={promptRef}
                variant='warning'
                confirmText='Odrzuć'
                cancelText='Pisz dalej'
                title='Odrzucić notatkę?'
                message='Jeśli teraz przerwiesz, utracisz wprowadzony tekst.'
                onConfirm={() => performClose()}
            />
        </section>
    );
};

export default NoteForm;
