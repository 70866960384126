import React, {Suspense} from 'react';
import {AuthProvider} from "./modules/auth/contexts/AuthContext";
import NotificationPrompt from "./modules/common/components/notification/notification-prompt";
import {BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./app-routes";
import PageLoader from "./modules/common/components/page-loader";
import {InstallPwaProvider} from "./modules/common/contexts/installer-pwa-context";

function App() {

    return (
        <Router>
            <AuthProvider>
                <InstallPwaProvider>
                    <Suspense fallback={<PageLoader/>}>
                        <AppRoutes/>
                    </Suspense>
                    <NotificationPrompt/>
                </InstallPwaProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
