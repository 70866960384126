import React from "react";
import {BaseBlock} from "../types/base-block.interface";
import {INextStepBlock} from "../types/blocks/next-step-block.interface";
import PrimaryButton from "../../common/components/primary-button";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import {usePageScenario} from "../hooks/use-page-scenario";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import EndBlock from "./end-block";
import {useFormManager} from "../../core/hooks/use-form-manager";

const NextStepBlock: React.FC<BaseBlock<INextStepBlock>> = (props: BaseBlock<INextStepBlock>) => {
    const {data, id, blockCode, readonly} = props;
    const {connectWithApi, isLastBlockInScenario} = data;
    const {sendUserAnswer} = useDynamicViewApi();
    const {nextPage, prevPage, currentPageIndex} = usePageScenario()
    const {validateForms, scrollToInvalidForm} = useFormManager();

    const defaultHandleClick = async () => {
        return await nextPage()
            .then(async (res) => {
                if (res && connectWithApi && !readonly) {
                    return await sendUserAnswer(id, blockCode, {})
                }
            })
    }

    const lastBlockHandleClick = async () => {
        if (!readonly) {
            if (await validateForms()) {
                if (connectWithApi) {
                    await sendUserAnswer(id, blockCode, {})
                    return await Promise.resolve();
                }
            } else {
                scrollToInvalidForm();
                return await Promise.resolve();
            }
        }
        return await Promise.resolve();
    }

    return (
        <>
            {isLastBlockInScenario ? (
                <EndBlock {...props} data={{onClick: lastBlockHandleClick.bind(this)}}/>
            ) : (
                <div className='flex gap-5 items-center mt-10'>
                    {(readonly && currentPageIndex > 0) && (
                        <button
                            onClick={prevPage}
                            className='w-fit h-fit bg-gray-50 flex items-center justify-center rounded-full border border-gray-300 p-3
                    max-h-[44px] max-w-[44px] hover:text-primary_700 focus:text-primary_700 '>
                            <ChevronLeftIcon className='w-5 h-5 text-gray-900'/>
                        </button>
                    )}
                    <PrimaryButton
                        styleClass='sm:w-[600px] w-full mx-auto'
                        onClick={defaultHandleClick}
                        icon={<ArrowRightIcon className='w-5 h-5 ml-2'/>}>
                        Przejdź dalej
                    </PrimaryButton>
                </div>
            )}
        </>
    )
}

export default NextStepBlock
