import {Note} from '../types/note.interface';
import NoteService from "../services/note-service.service";

export const useNoteApi = () => {

    const addNote = async (note: Omit<Note, 'id' | 'created' | 'edited'>): Promise<IDBValidKey> => {
        const currentDate: Date = new Date();
        const fullNote: Omit<Note, 'id'> = {
            ...note,
            created: currentDate,
            edited: currentDate,
        }
        return await NoteService.addNote(fullNote);
    }

    const getNotes = async (): Promise<Note[]> => {
        const notes: Note[] = await NoteService.getNotes();
        return  notes.sort((a: Note, b: Note) => {
            const dateA: Date = new Date(a.edited);
            const dateB: Date = new Date(b.edited);
            return dateB.getTime() - dateA.getTime();
        });
    };

    const getNoteById = async (id: number): Promise<Note | undefined> => await NoteService.getNoteById(id);

    const updateNote = async (note: Note): Promise<void> => {
        const currentDate: Date = new Date();
        const fullNote: Note = {
            ...note,
            edited: currentDate,
        }
        return await NoteService.updateNote(fullNote);
    }

    const deleteNote = async (id: number): Promise<void> => await NoteService.deleteNote(id);

    const clearAllNotes = async (): Promise<void> => await NoteService.clearAllNotes();

    return {
        addNote,
        getNotes,
        getNoteById,
        updateNote,
        deleteNote,
        clearAllNotes,
    };
};
