import React, {createContext, ReactNode, useContext} from 'react';
import usePWAInstall from "../hooks/use-pwa-install";

interface InstallerPwaContextProps {
    isInstallable: boolean;
    installPWA: () => Promise<void>;
    instructions: JSX.Element | null;
    hideInstructions: () => void;
    isInstalled: boolean;
}

const InstallerPwaContext = createContext<InstallerPwaContextProps | undefined>(undefined);

export const useContextInstallPwa = (): InstallerPwaContextProps => {
    const context = useContext(InstallerPwaContext);
    if (!context) {
        throw new Error('useContextInstallPwa must be used within an InstallPwaProvider');
    }
    return context;
};

interface InstallerPwaProviderProps {
    children: ReactNode;
}

export const InstallPwaProvider: React.FC<InstallerPwaProviderProps> = ({children}) => {
    const {
        isInstallable,
        installPWA,
        manualInstructions,
        setManualInstructions,
        isInstalled
    } = usePWAInstall();

    const hideInstructions = () => {
        setManualInstructions(null);
    }

    return (
        <InstallerPwaContext.Provider
            value={{
                hideInstructions,
                instructions: manualInstructions,
                installPWA,
                isInstallable,
                isInstalled
            }}
        >
            {children}
        </InstallerPwaContext.Provider>
    );
};
