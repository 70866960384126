export class DateHelper {
    /**
     * Formats a given Date object into a string based on the specified format.
     * @param date - The date to format.
     * @param format - The format string (e.g., 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM/DD/YYYY').
     * @returns A formatted date string.
     */
    static formatDate(date: Date, format: string): string {
        const map: { [key: string]: string | number } = {
            YYYY: date.getFullYear(),
            MM: ('0' + (date.getMonth() + 1)).slice(-2),
            DD: ('0' + date.getDate()).slice(-2),
            HH: ('0' + date.getHours()).slice(-2),
            mm: ('0' + date.getMinutes()).slice(-2),
            ss: ('0' + date.getSeconds()).slice(-2),
        };

        return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (matched) => map[matched].toString());
    }

    /**
     * Maps a date string from one format to another.
     * @param dateStr - The date string to map (e.g., '2024-08-14').
     * @param fromFormat - The original format of the date string (e.g., 'YYYY-MM-DD').
     * @param toFormat - The target format to map the date to (e.g., 'DD-MM-YYYY').
     * @returns A formatted date string in the target format.
     * @throws Error if the date string or format is invalid.
     */
    static mapDate(dateStr: string, fromFormat: string, toFormat: string): string {
        const parts: { [key: string]: number | null } = {};
        const fromFormatRegex = fromFormat.replace(/YYYY|MM|DD|HH|mm|ss/g, (matched) => {
            parts[matched] = null;
            return `(${matched === 'YYYY' ? '\\d{4}' : '\\d{2}'})`;
        });

        const match = new RegExp(fromFormatRegex).exec(dateStr);

        if (match) {
            Object.keys(parts).forEach((key, index) => {
                parts[key] = parseInt(match[index + 1], 10);
            });

            const date = new Date(
                parts['YYYY'] || 0,
                (parts['MM'] || 1) - 1,
                parts['DD'] || 1,
                parts['HH'] || 0,
                parts['mm'] || 0,
                parts['ss'] || 0
            );

            return this.formatDate(date, toFormat);
        }

        throw new Error('Invalid date or format');
    }

    /**
     * Adds or subtracts a specified amount of time (hours and minutes) to a given date.
     * @param date - The date to modify.
     * @param hours - The number of hours to add (can be negative to subtract).
     * @param minutes - The number of minutes to add (can be negative to subtract).
     * @returns A new Date object with the modified time.
     */
    static addTime(date: Date, hours: number, minutes: number): Date {
        const result = new Date(date);

        result.setHours(result.getHours() + hours);
        result.setMinutes(result.getMinutes() + minutes);

        return result;
    }

    /**
     * Adds a specified number of days to a given date.
     * @param date - The date to add days to.
     * @param days - The number of days to add.
     * @returns A new Date object with the added days.
     */
    static addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    /**
     * Adds a specified number of months to a given date.
     * @param date - The date to add months to.
     * @param months - The number of months to add.
     * @returns A new Date object with the added months.
     */
    static addMonths(date: Date, months: number): Date {
        const result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    }

    /**
     * Checks if two dates are equal, ignoring the time component.
     * @param date1 - The first date.
     * @param date2 - The second date.
     * @returns true if the dates are equal (ignoring time), false otherwise.
     */
    static areDatesEqual(date1: Date, date2: Date): boolean {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    /**
     * Zwraca tekst wskazujący, ile czasu minęło od podanej daty.
     * @param date - Data, z którą porównujemy aktualny czas.
     * @returns Tekst reprezentujący upływ czasu (np. "1 minutę temu", "3 dni temu").
     */
    static timeAgo(date: Date): string {
        const now = new Date();
        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

        const intervals = [
            { label: 'rok', seconds: 31536000, singular: 'rok', plural: 'lata', pluralMany: 'lat' },
            { label: 'miesiąc', seconds: 2592000, singular: 'miesiąc', plural: 'miesiące', pluralMany: 'miesięcy' },
            { label: 'tydzień', seconds: 604800, singular: 'tydzień', plural: 'tygodnie', pluralMany: 'tygodni' },
            { label: 'dzień', seconds: 86400, singular: 'dzień', plural: 'dni', pluralMany: 'dni' },
            { label: 'godzina', seconds: 3600, singular: 'godzinę', plural: 'godziny', pluralMany: 'godzin' },
            { label: 'minuta', seconds: 60, singular: 'minutę', plural: 'minuty', pluralMany: 'minut' },
            { label: 'sekunda', seconds: 1, singular: 'sekundę', plural: 'sekundy', pluralMany: 'sekund' },
        ];

        for (const interval of intervals) {
            const count = Math.floor(seconds / interval.seconds);
            if (count >= 1) {
                if (interval.label === 'dzień' && count > 3) {
                    return this.formatDate(date, 'DD.MM.YYYY HH:mm');
                }
                const label = this.getPolishLabel(interval, count);
                return `${count} ${label} temu`;
            }
        }

        return 'Przed chwilą';
    }

    /**
     * Zwraca odpowiednią formę liczby dla polskich tekstów.
     * @param interval - Obiekt z danymi dotyczącymi jednostki czasu.
     * @param count - Liczba jednostek czasu.
     * @returns Odpowiednia forma liczby w języku polskim.
     */
    private static getPolishLabel(interval: { singular: string, plural: string, pluralMany: string }, count: number): string {
        if (count === 1) return interval.singular;
        if (count > 1 && count < 5) return interval.plural;
        return interval.pluralMany;
    }
}
