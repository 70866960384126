import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import React, {useEffect, useRef, useState} from "react";
import TextFormField from "../../common/components/forms/text-form-field";
import PrimaryButton from "../../common/components/primary-button";
import * as yup from "yup";
import {PencilIcon} from "@heroicons/react/24/solid";
import {useAppBackground} from "../hooks/use-app-background";
import SelectFormField from "../../common/components/forms/select-form-field";
import {SettingBackground} from "../constants/setting-backgrounds";
import {useUserApi} from "../../core/hooks/useUserApi";
import useAuth from "../../auth/hooks/useAuth";
import {GetUserData} from "../../core/types/get-user-data.interface";
import {RequestBodyUserData} from "../../core/types/request-body-user-data.interface";
import DialogChangeImage from "./dialog-change-image";
import {RequestBodyUploadProfileImage} from "../../core/types/request-body-upload-profile-image.interface";
import axios from "axios";
import {useUnsavedChangesBlocker} from "../../core/hooks/useUnsavedChangesBlocker";
import {useNavigate} from "react-router-dom";
import withModalProvider from "../../common/hoc/withModalProvider";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import {useModal} from "../../common/hooks/useModal";
import Modal from "../../common/components/modal";
import ChangePasswordForm from "../../auth/components/change-password-form";

const schema = yup.object({
    firstname: yup
        .string()
        .min(3, 'Imię może składać się minimalnie z 3 znaków')
        .required('Imię jest wymagane'),
    lastname: yup
        .string()
        .min(3, 'Nazwisko może składać się minimalnie z 3 znaków')
        .required('Nazwisko jest wymagane'),
    gender: yup
        .string()
        .transform(value => value === '' ? null : value)
        .min(3, 'Płeć może składać się minimalnie z 3 znaków')
        .nullable()
        .notRequired(),
    profession: yup
        .string()
        .min(3, 'Zawód może składać się minimalnie z 3 znaków')
        .required('Zawód jest wymagany'),
    theme: yup
        .string(),
}).required();

const ProfileDataForm = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const {getUserData, loadingData, loading, saveUserData, uploadProfilePicture} = useUserApi();
    const {currentUser} = useAuth();
    const {registerModals, openModal} = useModal();
    const [tempImage, setTempImage] = useState<RequestBodyUploadProfileImage>({
        name: currentUser!.profilePictureBase64 ? 'profile-image' : null,
        contentBase64: currentUser!.profilePictureBase64 ? 'data:image/jpeg;base64,' + currentUser!.profilePictureBase64 : null
    });
    const {changeBackground, saveBackground, background} = useAppBackground();
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        reValidateMode: "onSubmit",
        defaultValues: {
            theme: background,
            gender: '',
            profession: '',
            firstname: '',
        },
    });

    const handleSaveData = async () => {
        const body: RequestBodyUserData = {
            gender: methods.getValues('gender')!,
            profession: methods.getValues('profession')!,
            firstname: methods.getValues('firstname')!,
            lastname: methods.getValues('lastname')!,
        };
        try {

            await uploadProfilePicture(tempImage)

            await saveUserData(body);

            setIsDirty(false);
            saveBackground();
        } catch (error) {
            console.error("Błąd podczas zapisywania danych profilu:", error);
        }
    };

    const handleChangeBackground = (value: typeof SettingBackground[0]) => {
        changeBackground(value.background);
    };

    const updateProfileImage = (body: RequestBodyUploadProfileImage): void => setTempImage(body)
    const [isDirty, setIsDirty] = useState(false);

    const clearProfileImage = (): void => setTempImage({contentBase64: null, name: null})

    useEffect(() => {
        getUserData()
            .then((res: GetUserData): void => {
                methods.reset({
                    theme: background,
                    gender: res.gender,
                    profession: res.profession,
                    firstname: res.firstname,
                    lastname: res.lastname,
                });
                setIsDirty(false);
            })
            .catch()
    }, []);

    useEffect(() => {
        const subscription = methods.watch((values) => {
            const isFormDirty = methods.formState.isDirty;
            setIsDirty(isFormDirty);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        registerModals([ModalIds.CHANGE_PASSWORD]);
    }, [])

    useUnsavedChangesBlocker(isDirty);

    return (
        <div>
            <FormProvider {...methods}>
                <form ref={formRef} onSubmit={methods.handleSubmit(handleSaveData, () => {
                })} className='flex flex-col gap-y-10 h-full'>
                    <div className='mt-6 w-fit h-fit md:mb-6 mb-0 flex flex-col md:flex-row md:items-end md:gap-5'>
                        <div className='relative cursor-pointer' onClick={() => setDialogOpen(true)}>
                            {tempImage?.contentBase64
                                ? <img alt="profile-image"
                                       src={tempImage?.contentBase64}
                                       className="h-20 w-20 md:h-30 md:w-30 rounded-full"/>
                                : <span style={{background: '#AFAFAF'}}
                                        className="h-20 w-20 md:h-30 md:w-30 rounded-full flex items-center justify-center font-bold text-xl text-gray-500">
                                {currentUser?.firstname ? currentUser?.firstname.toUpperCase().charAt(0) : currentUser?.email.toUpperCase().charAt(0)}
                        </span>}
                            <span
                                style={{transform: 'translate(50%, 50%)'}}
                                className='absolute bottom-2 right-2 w-5 h-5 md:w-6 md:h-6 bg-gray-300 rounded-full flex items-center justify-center'>
                            <PencilIcon className='font-extralight w-3 h-3 text-gray-600'/>
                    </span>
                        </div>
                        <span
                            className='text-xl mt-2 ml-3 md:text-2xl leading-7 font-semibold md:font-bold first-letter:uppercase'>{currentUser!.firstname}</span>
                    </div>

                    <div className='md:max-w-[720px] flex mx-auto flex-col gap-y-4 h-full w-full'>
                        <div className='mx-3 flex flex-col gap-3'>
                            <TextFormField name='firstname' placeholder='Wpisz imię' label='Imię'
                                           isLoading={loadingData}/>
                            <TextFormField name='lastname' placeholder='Wpisz nazwisko' label='Nazwisko'
                                           isLoading={loadingData}/>
                            <TextFormField name='gender' placeholder='Wpisz płeć' label='Płeć' isLoading={loadingData}/>
                            <TextFormField name='profession' placeholder='Wpisz wykształcenie' label='Wykształcenie'
                                           isLoading={loadingData}/>
                            <SelectFormField name='theme'
                                             isLoading={loadingData}
                                             onChange={handleChangeBackground}
                                             parentWrapperRef={formRef}
                                             label='Wybierz swoje tło w aplikacji'
                                             options={SettingBackground}
                                             valueKey='background'
                                             labelKey='representativeName'
                            />
                        </div>
                        <span
                            className='mx-3 underline text-sm leading-5 mt-5 cursor-pointer hover:text-primary_700 focus:text-primary_700'
                            onClick={() => openModal(ModalIds.CHANGE_PASSWORD)}>
                        Zmień hasło
                    </span>
                        <div className='flex md:justify-end gap-3 mx-3 pb-6 mt-8'>
                            <PrimaryButton
                                loading={loading}
                                disabled={loadingData}
                                styleClass='w-fit h-fit max-h-fit max-w-fit py-2 px-4 text-sm hover:bg-primary_700 focus:bg-primary_700'
                                type='submit'>
                                Zapisz
                            </PrimaryButton>
                            <button
                                type='button'
                                onClick={() => navigate(-1)}
                                className='w-fit h-fit max-h-fit max-w-fit py-2 px-4 text-sm border-[1px] border-gray-300 bg-white text-gray-700 rounded-3xl'
                            >
                                Anuluj
                            </button>
                        </div>
                    </div>
                </form>
                <DialogChangeImage hasImage={!!tempImage?.contentBase64}
                                   onClearImage={clearProfileImage}
                                   onLoadedImage={updateProfileImage}
                                   dialogOpen={dialogOpen}
                                   setDialogOpen={setDialogOpen}/>
                <Modal id={ModalIds.CHANGE_PASSWORD}>
                    <ChangePasswordForm/>
                </Modal>
            </FormProvider>
        </div>
    )
}

export default withModalProvider(ProfileDataForm);
