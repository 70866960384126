import React, {useRef} from "react";
import {useFormContext} from "react-hook-form";

interface TokenInputFieldProps {
    length: number;
    name: string;
    onlyNumber?: boolean;
    isDivider?: boolean;
}

const TokenInputField: React.FC<TokenInputFieldProps> = (props: TokenInputFieldProps) => {
    const {length, name, onlyNumber = false, isDivider = true} = props;
    const {register, setValue, getValues} = useFormContext();
    const tokenRefs = useRef<(HTMLInputElement | null)[]>(Array(length).fill(null));

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
        if (e.key === 'Backspace') {
            handleBackspace(index);
        }
    };

    const handleBackspace = (index: number): void => {
        if (!getValues(`${name}.${index}`) && index > 0) {
            setValue(`${name}.${index - 1}`, '');
            moveFocusToPrevious(index);
        }
    };

    const handleInput = (e: React.FormEvent<HTMLInputElement>, index: number): void => {
        const input = e.currentTarget as HTMLInputElement;
        if (onlyNumber) {
            filterNonNumeric(input);
        }
        if (input.value.length === 1) {
            moveFocusToNext(index);
        }
    };

    const filterNonNumeric = (input: HTMLInputElement): void => {
        input.value = input.value.replace(/\D/g, '');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        const value = e.target.value;
        setValue(`${name}.${index}`, value);
        if (value.length === 1) {
            moveFocusToNext(index);
        } else if (value.length > 1) {
            handleMultipleCharacters(value, index);
        }
    };

    const handleMultipleCharacters = (value: string, index: number): void => {
        const values = value.split('');
        values.forEach((char, i) => {
            if (index + i < length) {
                setValue(`${name}.${index + i}`, char);
                tokenRefs.current[index + i]!.value = char;
            }
        });
        if (index + values.length < length) {
            tokenRefs.current[index + values.length]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
        const pasteData = e.clipboardData.getData('text');
        if (isValidPasteData(pasteData)) {
            pasteData.split('').forEach((char, index): void => {
                setValue(`${name}.${index}`, char);
                tokenRefs.current[index]!.value = char;
                if (index < length - 1) {
                    tokenRefs.current[index + 1]?.focus();
                }
            });
            e.preventDefault();
        }
    };

    const isValidPasteData = (pasteData: string) => {
        return pasteData.length <= length && (!onlyNumber || /^\d*$/.test(pasteData));
    };

    const moveFocusToNext = (index: number): void => {
        if (index < length - 1) {
            tokenRefs.current[index + 1]?.focus();
        }
    };

    const moveFocusToPrevious = (index: number): void => {
        if (index > 0) {
            tokenRefs.current[index - 1]?.focus();
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        const input = e.currentTarget;
        const length = input.value.length;
        input.setSelectionRange(0, length + 1);
    };

    return (
        <div className="flex justify-between mb-4">
            {Array.from({length}).map((_, index) => (
                <React.Fragment key={index}>
                    {isDivider && length % 2 === 0 && index === length / 2 && (
                        <div className='px-1 w-fit h-11 flex items-center justify-center'>
                            <div className="w-5 bg-primary" style={{height:"3px"}}></div>
                        </div>
                    )}
                    {isDivider && length % 2 !== 0 && (index === Math.floor(length / 2) || index === Math.floor(length / 2) + 1) && (
                        <div className='px-1 w-fit h-11 flex items-center justify-center'>
                            <div className="w-5 bg-primary" style={{height:"3px"}}></div>
                        </div>
                    )}
                    <input
                        type="text"
                        maxLength={1}
                        className="selection:bg-transparent w-11 h-11 text-xl text-dark text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary_400 hover:bg-gray-100 transition-colors shadow-sm"
                        {...register(`${name}.${index}` as const)}
                        ref={(el) => (tokenRefs.current[index] = el)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onInput={(e) => handleInput(e, index)}
                        onChange={(e) => handleChange(e, index)}
                        onPaste={handlePaste}
                        onFocus={handleFocus}
                    />
                </React.Fragment>
            ))}
        </div>
    );
};

export default TokenInputField;
