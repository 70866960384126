import {ValidationErrors} from "../types/validation-errors";

class ValidationError extends Error {
    private errors: ValidationErrors;

    constructor(errors: ValidationErrors) {
        super('Validation Error');
        this.errors = errors;
        Object.setPrototypeOf(this, ValidationError.prototype);
    }

    getError(field: string): string | null {
        return this.errors[field] || null;
    }

    hasError(field: string): boolean {
        return !!this.errors[field];
    }

    getAllErrors(): ValidationErrors {
        return this.errors;
    }
}

export default ValidationError;
