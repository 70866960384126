import {Dialog, DialogBackdrop, DialogPanel} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/20/solid";
import React, {ReactNode, useEffect, useState} from "react";
import {useModal} from "../hooks/useModal";
import {classNames} from "../../../utils/class-names";
import {ModalIds} from "../constants/modal-ids.interface";


interface ModalProps {
    id: ModalIds;
    children?: ReactNode;
    classModal?: string;
    showClose?: boolean;
    disableBackdropClick?: boolean;
}

const Modal = (props: ModalProps) => {
    const {
        id,
        children,
        classModal,
        showClose = true,
        disableBackdropClick = false,
    } = props;
    const modal = useModal();
    const {isOpenModal, closeModal} = modal;
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleClose = () => {
        if (!disableBackdropClick) {
            closeModal(id);
        }
    };

    useEffect(() => {
        setIsVisible(prevState => isOpenModal(id) || false);
    }, [isOpenModal(id)])

    return (
        <Dialog open={isVisible}
                onClose={handleClose}
                className={classNames('relative z-20')}
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-white bg-opacity-60 backdrop-blur-[2px] transition-opacity
                data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200
                data-[enter]:ease-linear data-[leave]:ease-linear"
            />
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                    <DialogPanel
                        className={`relative w-96 min-h-44 h-auto border-[1px] border-green-600 transform 
                        overflow-hidden rounded-lg bg-white p-5 text-left shadow-xl transition-all 
                        sm:my-8 sm:w-full sm:max-w-lg sm:p-6 ${classModal ? classModal : ''}`}
                    >
                        {children}
                        {showClose && (
                            <div
                                className="absolute w-6 h-6 top-5 right-5 cursor-pointer text-gray-400 focus:text-dark hover:text-dark"
                                onClick={() => closeModal(id)}
                            >
                                <XMarkIcon/>
                            </div>
                        )}

                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default Modal
