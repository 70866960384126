export enum BLOCK_CODE {
    SHORT_ANSWER = 'SHORT_ANSWER',
    LONG_ANSWER = 'LONG_ANSWER',
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE_FIXED_RANGE = 'MULTIPLE_CHOICE_FIXED_RANGE',
    MULTIPLE_CHOICE_FIXED_COUNT = 'MULTIPLE_CHOICE_FIXED_COUNT',
    MULTIPLE_CHOICE_ANY = 'MULTIPLE_CHOICE_ANY',
    PHRASE_COMPLETION = 'PHRASE_COMPLETION',
    PHRASE_PYRAMID = 'PHRASE_PYRAMID',
    AUDIO_RECORDING = 'AUDIO_RECORDING',
    VIDEO_RECORDING = 'VIDEO_RECORDING',
    ADD_TO_CALENDAR_MANUAL = 'ADD_TO_CALENDAR_MANUAL',
    POLL = 'POLL',
    HEADER = 'HEADER',
    PARAGRAPH = 'PARAGRAPH',
    VIDEO = 'VIDEO',
    ANIMATION = 'ANIMATION',
    ADD_TO_CALENDAR_AUTOMATIC = 'ADD_TO_CALENDAR_AUTOMATIC',
    SEPARATOR = 'SEPARATOR',
    END_BLOCK = 'END_BLOCK',
    NEXT_STEP = 'NEXT_STEP',
    NEXT_STEP_AS_ANSWER = 'NEXT_STEP_AS_ANSWER',
}
