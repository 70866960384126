import {useEffect} from "react";

export const useUnsavedChangesBlocker = (isDirty: boolean) => {
    useEffect(() => {
        const handleBeforeUnload = (event: any) => {

            if (isDirty) {
                event.preventDefault();
                event.returnValue = "";
                return ""
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);


        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isDirty]);


};
