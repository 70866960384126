export function replaceEmptyStringsWithNull(obj: Record<string, any>): Record<string, any> {
    Object.keys(obj).forEach(key => {
        if (obj[key] === "") {
            obj[key] = null;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceEmptyStringsWithNull(obj[key]); // Rekurencja dla zagnieżdżonych obiektów
        }
    });
    return obj;
}
