import {useContext, useEffect} from "react";
import {ModalContext} from "../contexts/modal-context";
import {ModalIds} from "../constants/modal-ids.interface";

export const useModal = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }

    return context;
};
