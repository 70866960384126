import React from "react";

const SkeletonSubscriptionCard = () => {
    return (
        <div
            className="bg-gray-300 min-h-[450px] rounded-xl flex flex-col justify-between gap-y-10 shadow-sm border "></div>
    )
}

export default SkeletonSubscriptionCard
