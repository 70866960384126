import React, {useRef} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";

interface DateTimeFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
    labelClassName?: string;
    isLoading?: boolean;
    isRequired?: boolean;
    onlyDate?: boolean;
    onlyTime?: boolean;
}

const DateTimeFormField: React.FC<DateTimeFormFieldProps> = (props: DateTimeFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        labelClassName,
        isRequired = false,
        onlyDate = false,
        onlyTime = false,
        ...rest
    } = props;

    const inputType = onlyDate ? 'date' : onlyTime ? 'time' : 'datetime-local';

    return (
        <div className="mb-2">
            <label htmlFor={name} className={`block text-gray-700 theme-dark:text-gray-50 text-sm ${labelClassName}`}>
                {label}
                {isRequired && <span className='text-red-500'>*</span>}
            </label>
            {isLoading
                ? <SkeletonFormField/>
                : <input
                    type={inputType}
                    id={name}
                    {...register(name)}
                    className={`w-full px-3 py-[6px] border text-gray-900 rounded-lg shadow-sm focus:outline-none focus:ring-2 ${errors[name] ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary_400'} ${className}`}
                    disabled={isSubmitting}
                    {...rest}
                />}
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default DateTimeFormField;
