export const environment = {
    production: true,
    stage: 'test',
    backendUrl: 'https://test.backend.panel.pozytywnazmiana.com/api',
    firebaseConfig: {
        apiKey: "AIzaSyB853czU6rTRV205wB1WrT9a9X2PY_ATy4",
        authDomain: "psycho-app-84576.firebaseapp.com",
        projectId: "psycho-app-84576",
        storageBucket: "psycho-app-84576.appspot.com",
        messagingSenderId: "286025397297",
        appId: "1:286025397297:web:0fff28ad5a0140723bd110",
        vapidKey: "BKzQvp_CDaNHTTOHxaRCCWYcHyj97e7sMxpv60qQZ82UY6f_eamEkICsYM-5sXREGFIaJitrMXtoJtmmuhziexc",
      }
};
