import React, {useEffect, useState} from "react";
import CalendarFormMobile from "../components/mobile/calendar-form-mobile";
import CalendarFormDesktop from "../components/desktop/calendar-form-desktop";
import {CalendarFormProvider} from "../contexts/calendar-form-context";


export const withCalendarFormProvider = <P extends object>(
    Component: React.ComponentType<P>,
) => {
    return (props: P) => {
        const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
            };
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        return (
            <CalendarFormProvider
                isMobile={isMobile}
                getFormComponentMobile={(ref) => <CalendarFormMobile ref={ref}/>}
                getFormComponentDesktop={(ref) => <CalendarFormDesktop ref={ref}/>}
            >
                <Component {...props} />
            </CalendarFormProvider>
        );
    };
};
