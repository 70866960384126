export type NotificationType = 'success' | 'error' | 'warning' | 'info';

export enum Notifications {
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning",
    INFO = "info"
}

export interface Notification {
    id: number,
    message: string,
    title: string,
    type: NotificationType,
    duration: number,
}

export type ListenerNotify = (notifications: Notification[]) => void;

export interface NotificationProps {
    notification: Notification
    onClose: () => void,
}
