import React from 'react';
import {ScenarioProvider} from "../contexts/scenario-context";

const withDynamicViewProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => (
        <ScenarioProvider>
            <WrappedComponent {...props} />
        </ScenarioProvider>
    );
};

export default withDynamicViewProvider;
