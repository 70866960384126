import React, {useEffect, useRef, useState} from 'react';
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/react";
import {ArrowRightStartOnRectangleIcon, Bars3Icon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {classNames} from "../utils/class-names";
import AppWrapper from "./app-wrapper";
import {NavLink, useLocation} from "react-router-dom";
import useAuth from "../modules/auth/hooks/useAuth";
import {navigation} from "./menu";
import InstallPwaButton from "../modules/common/components/install-pwa-button";


interface HeaderLayerProps {
    handleOpenMenu: (value: boolean) => any;
}

const HeaderLayer = ({handleOpenMenu}: HeaderLayerProps) => {
    const [panelHeight, setPanelHeight] = useState('100svh');
    const headerRef = useRef(null);
    const location = useLocation();
    const {logout, currentUser} = useAuth();
    const {profilePictureBase64, firstname, lastname, email} = currentUser!;

    useEffect(() => {
        const handleResize = () => {
            if (headerRef.current) {
                const headerHeight = (headerRef.current as any).offsetHeight;
                const isSmallScreen = window.matchMedia("(min-width: 640px)").matches;

                if (isSmallScreen) {
                    setPanelHeight(`calc(100vh - ${headerHeight}px)`);
                } else {
                    setPanelHeight('100svh');
                }
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCloseMenu = (closeCallback: Function, stateMenu: boolean) => {
        handleOpenMenu(stateMenu);
        closeCallback();
    }

    useEffect(() => {
    }, [currentUser])

    return (
        <Disclosure as="nav" className="sm:border-b border-gray-200 sm:bg-white bg-transparent relative z-auto sm:z-40">
            {({open, close}) =>
                <>
                    <AppWrapper>
                        <div ref={headerRef}
                             className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex min-h-16 justify-between sm:justify-between md:gap-x-6 gap-x-2">
                                <div className="min-h-16 hidden sm:flex lg:gap-x-24 md:gap-x-12 gap-x-6 flex-wrap">
                                    <NavLink to='' onClick={() => handleOpenMenu(!open)}>
                                        <div className="h-16 flex-shrink-0 items-center flex gap-x-2 cursor-pointer">
                                            <img
                                                alt="Your Company"
                                                src={require("../assets/images/logo_64x64.png")}
                                                className="block h-8 w-auto"
                                            />
                                            <span className='text-gray-700 font-bold text-base'>Pozytywna Zmiana</span>
                                        </div>
                                    </NavLink>
                                    <div className="h-16 sm:-my-px flex md:space-x-4 space-x-2">
                                        {navigation
                                            .filter((item) => item.hasShortCut)
                                            .map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    end
                                                    aria-current={location.pathname === item.href ? 'page' : undefined}
                                                    onClick={() => handleOpenMenu(!open)}
                                                    className={({isActive}) =>
                                                        classNames(
                                                            isActive
                                                                ? 'border-green-600 text-gray-900'
                                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-nowrap',
                                                        )
                                                    }
                                                >
                                                    <item.Icon aria-hidden="true" className={classNames(
                                                        location.pathname === item.href
                                                            ? 'text-primary_600'
                                                            : 'text-gray-400',
                                                        'mr-2 h-5 w-5'
                                                    )}/>
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                    </div>
                                </div>
                                <div className='h-16 flex items-center sm:justify-end sm:flex-grow'>
                                    <NavLink to='profile' >
                                        {profilePictureBase64 ?
                                            <img alt="profile-image"
                                                 src={'data:image/jpeg;base64,' + profilePictureBase64}
                                                 className="h-8 w-8 rounded-full"/>
                                            : <span style={{background: '#AFAFAF'}}
                                                    className="h-8 w-8 rounded-full flex items-center justify-center font-bold text-xl text-gray-500">
                                            {firstname ? firstname.toUpperCase().charAt(0) : email.toUpperCase().charAt(0)}
                                        </span>
                                        }
                                    </NavLink>
                                </div>
                                <div className="h-16 -mr-2 flex items-center">
                                    {/* Mobile menu button */}
                                    <DisclosureButton
                                        onClick={() => handleOpenMenu(!open)}
                                        className={classNames(
                                            open
                                                ? 'sm:text-gray-400 bg-transparent text-white'
                                                : 'sm:text-gray-400 text-dark focus:bg-transparent',
                                            'z-20 group relative inline-flex items-center justify-center ' +
                                            'rounded-md sm:bg-white p-2  hover:text-gray-500' +
                                            'focus:outline-none focus:ring-2 focus:ring-primary_500 focus:ring-offset-2',
                                        )}>
                                        <span className="absolute -inset-0.5"/>
                                        <span className="sr-only">Open main menu</span>
                                        <Bars3Icon aria-hidden="true"
                                                   className="block h-6 w-6 group-data-[open]:hidden"/>
                                        <XMarkIcon aria-hidden="true"
                                                   className="hidden h-6 w-6 group-data-[open]:block"/>
                                    </DisclosureButton>
                                </div>
                            </div>
                        </div>
                    </AppWrapper>

                    {open && (
                        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 sm:hidden" aria-hidden="true"/>
                    )}

                    <DisclosurePanel
                        className="flex flex-col z-10 w-3/4 sm:w-fit absolute top-0 sm:h-svh sm:top-full left-0 sm:left-auto right-auto sm:right-0 sm:max-w-xs sm:overflow-y-auto bg-white shadow-lg"
                        style={{height: panelHeight}}
                    >
                        <div
                            className="border-t border-gray-200 pb-3 pt-4 px-8 cursor-pointer min-w-40 sm:min-w-60 w-full">
                            <div className="flex items-center border-b border-gray-200 pb-6">
                                <div className="flex-shrink-0 w-10 h-10 min-w-10 min-h-10">
                                    {profilePictureBase64
                                        ?
                                        <img alt="profile-image" src={'data:image/jpeg;base64,' + profilePictureBase64}
                                             className="h-10 w-10 rounded-full"/>
                                        : <span style={{background: '#AFAFAF'}}
                                                className="h-10 w-10 rounded-full flex items-center justify-center font-bold text-xl text-gray-500">
                                            {firstname ? firstname.toUpperCase().charAt(0) : email.toUpperCase().charAt(0)}
                                    </span>
                                    }
                                </div>
                                <NavLink to='profile' onClick={() => handleCloseMenu(close, !open)}>
                                    <div className="ml-3">
                                        <div
                                            className="text-base font-medium text-gray-800">{firstname}</div>
                                        <div className="text-[10px] font-medium text-gray-500">Pokaż mój profil</div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div
                            className='flex flex-col justify-between content-between place-content-between flex-grow'>
                            <div className="space-y-1 pb-3 pt-2 flex-grow">
                                {navigation
                                    .map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            end
                                            aria-current={location.pathname === item.href ? 'page' : undefined}
                                            onClick={() => handleCloseMenu(close, !open)}
                                            className={({isActive}) =>
                                                classNames(
                                                    isActive
                                                        ? 'border-primary_600 bg-primary_50 text-primary_700'
                                                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                                                    'border-l-4 py-2 pl-6 pr-4 text-sm font-medium flex gap-x-1 items-center',
                                                )
                                            }
                                        >
                                            <item.Icon aria-hidden="true" className={classNames(
                                                location.pathname === item.href
                                                    ? 'text-primary_600'
                                                    : 'text-gray-500',
                                                'mr-2 h-5 w-5'
                                            )}/>
                                            {item.name}
                                        </NavLink>
                                    ))}
                            </div>
                            <div>
                                <div
                                    className='text-sm flex font-medium p-6 cursor-pointer text-gray-500 hover:text-primary'
                                    onClick={logout}>
                                    <ArrowRightStartOnRectangleIcon className='mr-3 h-5 w-5'/>
                                    <span className='text-gray-700'>Wyloguj</span>
                                </div>
                                <div className="w-full" >
                                    <InstallPwaButton onClick={() => handleCloseMenu(close, !open)}/>
                                </div>
                            </div>
                        </div>
                    </DisclosurePanel>
                </>
            }
        </Disclosure>
    );
};

export default HeaderLayer;
