import {useEffect, useRef, useState} from "react";
import {PromptActionHandle} from "../../common/types/prompt-action-handle.interface";
import PromptAction from "../../common/components/prompt-action";
import {StarIcon} from "@heroicons/react/24/outline";
import ChapterResetPrompt from "./chapter-reset-prompt";
import {ModalProvider} from "../../common/contexts/modal-context";

interface ChapterCompletedProps {
    isCompleted: boolean;
    chapterId: number;
}

const ChapterCompletedPrompt = (props: ChapterCompletedProps) => {
    const {isCompleted} = props
    const promptRef = useRef<PromptActionHandle>(null)
    const [showResetPrompt, setShowResetPrompt] = useState(false);

    const handleConfirm = () => {
    }

    const handleReject = () => {
        setShowResetPrompt(true);
    }

    const handleCancelReset = () => {
        setShowResetPrompt(false);
        promptRef.current?.open();
    }

    useEffect(() => {
        isCompleted && promptRef.current?.open();
    }, [showResetPrompt]);

    return (
        <div>
            {!showResetPrompt ? (
                <PromptAction
                    ref={promptRef}
                    title="Rozdział ukończony"
                    cancelText="Usuń"
                    confirmText="Zobacz odpowiedzi"
                    variant="success"
                    showClose={false}
                    customIcon={<StarIcon/>}
                    message="Chcesz przejrzeć swoje odpowiedzi, czy zresetować i zacząć od nowa?"
                    onConfirm={handleConfirm}
                    onReject={handleReject}
                />
            ) : (
                <ModalProvider><ChapterResetPrompt onCancel={handleCancelReset}
                                                   idChapter={props.chapterId}/></ModalProvider>
            )}
        </div>
    );
}

export default ChapterCompletedPrompt
