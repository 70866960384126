import React from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../../../utils/class-names";

interface CalendarNavigationProps {
    currentDate: Date;
    onPreviousMonth: () => void;
    onNextMonth: () => void;
    enableDarkMode?: boolean,
}


const MonthCalendarNavigation: React.FC<CalendarNavigationProps> = (props: CalendarNavigationProps) => {
    const {currentDate, onPreviousMonth, onNextMonth, enableDarkMode = true} = props

    return (
        <div
            className={classNames(
                'flex justify-between items-center px-4 mb-2',
                !enableDarkMode ? 'text-gray-900' : ''
            )}
        >
            <button onClick={onPreviousMonth}>
                <ChevronLeftIcon
                    className={classNames(
                        'w-5 h-5',
                        !enableDarkMode ? 'text-gray-600' : 'theme-dark:text-gray-50'
                    )}
                />
            </button>
            <span className="text-base font-medium">
            {currentDate.toLocaleString('default', {month: 'long', year: 'numeric'})}
        </span>
            <button onClick={onNextMonth}>
                <ChevronRightIcon
                    className={classNames(
                        'w-5 h-5',
                        !enableDarkMode ? 'text-gray-600' : 'theme-dark:text-gray-50'
                    )}
                />
            </button>
        </div>
    );

}

export default MonthCalendarNavigation;
