import React, {createContext, useState} from 'react';

interface PaymentContextProps {
    isStartedPayment: boolean;
    setIsStartedPayment: (value: boolean) => void;
}

export const PaymentContext = createContext<PaymentContextProps | undefined>(undefined);

export const PaymentProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [isStartedPayment, setIsStartedPayment] = useState(false);

    return (
        <PaymentContext.Provider value={{isStartedPayment, setIsStartedPayment}}>
            {children}
        </PaymentContext.Provider>
    );
};

;
