import React from "react";

const PageLoader = () => {
    return (
        <div className='relative h-full'>
            <div className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]'>
                <div className='flex-grow w-full h-full flex flex-col items-center justify-center'>
                    <div className='image-loader-container flex-grow min-h-max'>
                        <img
                            alt="Your Company"
                            src={require("../../../assets/images/logo_128x128.png")}
                            className="block h-fit w-fit pulsating-image-loader shadow-2xl drop-shadow-xl rounded-full"
                        />
                        <div className=''></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageLoader
