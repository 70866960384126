import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {classNames} from "../../../utils/class-names";
import {XMarkIcon} from "@heroicons/react/20/solid";
import React, {useRef} from 'react';
import {Base64} from "../../core/types/base-64.interface";
import {RequestBodyUploadProfileImage} from "../../core/types/request-body-upload-profile-image.interface";

interface DialogChangeImageProps {
    dialogOpen: boolean,
    hasImage: boolean,
    setDialogOpen: (value: boolean) => void;
    onLoadedImage: (body: RequestBodyUploadProfileImage) => void;
    onClearImage: () => void;
}

const DialogChangeImage = (props: DialogChangeImageProps) => {
    const {
        dialogOpen,
        setDialogOpen,
        hasImage,
        onLoadedImage,
        onClearImage
    } = props;
    const fileInputRef = useRef<HTMLInputElement>(null);

    const closeDialog = () => setDialogOpen(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && onLoadedImage) {
            convertToBase64(file, onLoadedImage)
        }
        closeDialog();
    };

    const handleClearImage = () => {
        onClearImage();
        closeDialog();
    };

    const convertToBase64 = (file: File, callback: (body: RequestBodyUploadProfileImage) => void) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback({
                name: file.name,
                contentBase64: reader.result as Base64,
            });
        };
        reader.readAsDataURL(file);
    };

    const openFileExplorer = () => {
        fileInputRef.current?.click();
    };

    return (
        <Dialog open={dialogOpen} onClose={setDialogOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-white bg-opacity-30 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className=" flex min-h-full items-center w-full justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left
                        shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0
                        data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out
                        data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0
                        data-[closed]:sm:scale-95 border-[1px] border-primary_600"
                    >
                        <div className='flex flex-col gap-6 pr-8'>
                            <DialogTitle as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                                Zmień zdjęcie profilowe
                            </DialogTitle>
                            <div className='text-gray-900 text-base cursor-pointer' onClick={openFileExplorer}>
                                Wybierz zdjęcie
                            </div>
                            <div onClick={handleClearImage}
                                 className={classNames(
                                     hasImage ? 'text-gray-900 cursor-pointer' : 'text-gray-500 cursor-not-allowed',
                                     'text-base'
                                 )}>
                                Usuń obecne zdjęcie
                            </div>
                        </div>
                        <div className='absolute w-7 h-7 top-3 right-3 cursor-pointer hover:text-primary_700'
                             onClick={closeDialog}>
                            <XMarkIcon/>
                        </div>
                    </DialogPanel>
                </div>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
            />
        </Dialog>
    );
};

export default DialogChangeImage;
