import React, {useEffect, useRef, useState} from "react";
import {CalendarEvent} from "../../types/calendar-event.interface";
import {classNames} from "../../../../utils/class-names";
import ExtraEventsPopover from "../extra-events-popover";
import {useCalendarForm} from "../../hooks/use-calendar-form";

interface MonthCalendarDayProps {
    day: { date: Date; isCurrentMonth: boolean } | null;
    events: CalendarEvent[];
    isToday: (date: Date) => boolean;
    calendarRef: React.RefObject<HTMLDivElement>;
}

const MonthCalendarDay: React.FC<MonthCalendarDayProps> = ({ day, events, isToday, calendarRef }) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const parentDayRef = useRef<HTMLDivElement>(null);

    if (!day) return <div className="w-full h-10"></div>;

    const totalItems = events.length;
    const maxDots = 3;
    const dots = Math.min(totalItems, maxDots);

    return (
        <>
            <div ref={parentDayRef} key={day.date.getDate()} className="relative w-9 h-9 text-base leading-6 font-normal"
                 onClick={() => setPopoverOpen(true)}>
                <div className={classNames(
                    `flex items-center justify-center rounded-full h-full`,
                    isToday(day.date) ? 'border border-primary_800 bg-primary_800 text-white' : ''
                )}>
                    <span>{day.date.getDate()}</span>
                </div>
                <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 flex gap-0.5">
                    {Array.from({ length: dots }).map((_, index) => {
                        return (
                            <div key={index}
                                 className={`w-1 h-1 bg-primary_800 rounded-full 
                                 ${isToday(day.date) ? '!bg-gray-50' : ''}`}
                            >
                            </div>
                        )
                    })}
                </div>
            </div>
            {isPopoverOpen && (
                <ExtraEventsPopover
                    calendarRef={calendarRef}
                    parentRef={parentDayRef}
                    date={day.date}
                    events={events}
                    onClose={() => setPopoverOpen(false)}
                />
            )}
        </>
    );
};

export default MonthCalendarDay;
