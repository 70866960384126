import React, {createContext, useContext, useEffect, useState} from 'react';
import {Week} from '../types/week.interface';
import ScheduleService from '../services/schedule.service';
import CalendarService from '../services/calendar.service';
import useCalendarApi from '../hooks/use-calendar-api';
import {CalendarEvent} from '../types/calendar-event.interface';
import {useCalendarForm} from '../hooks/use-calendar-form';

interface ScheduleContextProps {
    schedule: Week[];
    setSchedule: ((schedule: Week[]) => void) | null;
    currentMonthAndYear: string;
    currentDate: Date;
    handleMonthChange: (increment: number) => void;
}

const ScheduleContext = createContext<ScheduleContextProps>({
    schedule: [],
    setSchedule: null,
    currentMonthAndYear: '',
    currentDate: new Date(),
    handleMonthChange: () => {
    }
});

// Custom hook to use the context
export const useSchedule = () => useContext(ScheduleContext);

// Provider component
export const ScheduleProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [schedule, setSchedule] = useState<Week[]>([]);
    const [currentMonthAndYear, setCurrentMonthAndYear] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date());
    const {getCalendarData} = useCalendarApi();
    const {refreshKey} = useCalendarForm();

    useEffect(() => {
        const updateSchedule = async () => {
            setCurrentMonthAndYear(CalendarService.getMonthAndYear(currentDate));

            const [start, end] = ScheduleService.getMonthStartAndEndDates(currentDate);
            const apiData = await getCalendarData(start, end);

            const generatedSchedule = generateSchedule(start, end, apiData);
            setSchedule(generatedSchedule);
        };

        updateSchedule();
    }, [currentDate, refreshKey]);

    const generateSchedule = (start: Date, end: Date, apiData: CalendarEvent[]): Week[] => {
        return ScheduleService
            .generateEmptySchedule(start)
            .map(week => ({
                ...week,
                days: week.days.map(day => ({
                    ...day,
                    tasks: ScheduleService
                        .getTasksForDay(day.date!, apiData, currentDate)
                        .sort(ScheduleService.sortTasksByTime(currentDate)),
                })),
            }))
            .filter(week => week.days.some(day => day.tasks.length > 0));
    };

    const handleMonthChange = (increment: number) => {
        setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + increment, 1));
    };

    return (
        <ScheduleContext.Provider value={{schedule, setSchedule, currentMonthAndYear, currentDate, handleMonthChange}}>
            {children}
        </ScheduleContext.Provider>
    );
};
