import React from "react";
import {BaseBlock} from "../types/base-block.interface";
import {IAddToCalendarAutomaticBlock} from "../types/blocks/add-to-calendar-automatic-block.interface";
import PrimaryButton from "../../common/components/primary-button";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import useCalendarApi from "../../calendar/hooks/use-calendar-api";
import {mapCalendarInfoToRequestBody} from "../helpers/map-calendar-info-to-request-body.interface";

const AddToCalendarAutomaticBlock: React.FC<BaseBlock<IAddToCalendarAutomaticBlock>> = (props: BaseBlock<IAddToCalendarAutomaticBlock>) => {
    const {data} = props;
    const {createCalendarEvent} = useCalendarApi();

    const handleClick = async () => {
        const requestBody = mapCalendarInfoToRequestBody(data);
        await createCalendarEvent(requestBody)
            .then()
            .catch()
            .finally()
    };

    return (
        <PrimaryButton
            styleClass='sm:w-[600px] w-full mx-auto'
            onClick={handleClick}
            icon={<CalendarDaysIcon className='w-5 h-5 ml-2'/>}
        >
            Dodaj do kalendarza
        </PrimaryButton>
    )
}

export default AddToCalendarAutomaticBlock;
