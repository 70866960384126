import React, {useEffect} from 'react';
import LoginForm from "../../components/login-form";
import Logo from "../../../common/components/logo";
import {useParams} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const LoginView: React.FC = () => {
    const params = useParams()
    const {handleAdminTokenLogin} = useAuth();

    useEffect((): void => {
        if ('token' in params && params['token'] as string) handleAdminTokenLogin(params['token'] as string);
    }, [])

    return (
        <div className="max-h-dvh h-dvh flex items-center justify-center">
            <div
                className="w-full md:max-w-md h-full md:h-fit p-8 md:p-16 flex flex-col justify-center bg-white rounded-lg shadow-md">
                <Logo size="md"/>
                <h2 className="text-2xl font-bold text-center mb-16 text-dark dark:text-gray-900">Zaloguj się</h2>
                <LoginForm/>
            </div>
        </div>
    );
};

export default LoginView;
