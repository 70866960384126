import React, {useState} from "react";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {CalendarIcon} from "@heroicons/react/24/solid";
import {ChevronDownIcon, PlusIcon} from "@heroicons/react/16/solid";
import {TypeCalendarEvent} from "../../types/type-event.interface";
import {useCalendarForm} from "../../hooks/use-calendar-form";


const AddEventButton = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { openForm } = useCalendarForm();

    const handleOptionClick = (type: TypeCalendarEvent) => {
        setIsDropdownOpen(false);
        openForm(type,null);
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="inline-flex items-center bg-primary_700 text-white text-base leading-6
                font-semibold rounded-full hover:bg-green-800 h-10 px-3 "
            >
                <div className='flex-shrink w-fit'>
                    <PlusIcon className="w-5 h-5"/>
                </div>
                <div className='flex-grow px-2'>Utwórz</div>
                <div className='flex-shrink border-l border-primary_600 w-fit h-full flex items-center justify-center'>
                    <ChevronDownIcon className="w-5 h-5 ml-2"/>
                </div>
            </button>

            {isDropdownOpen && (
                <ul className="absolute right-0 z-10 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden p-3">
                    <li
                        onClick={() => handleOptionClick(TypeCalendarEvent.TASK)}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                    >
                        <CheckCircleIcon className="w-5 h-5 mr-2 text-gray-500"/>
                        Zadanie
                    </li>
                    <li
                        onClick={() => handleOptionClick(TypeCalendarEvent.EVENT)}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                    >
                        <CalendarIcon className="w-5 h-5 mr-2 text-gray-500"/>
                        Wydarzenie
                    </li>
                </ul>
            )}
        </div>
    );
};

export default AddEventButton;
