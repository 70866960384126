import React from "react";
import {useContextInstallPwa} from "../contexts/installer-pwa-context";
import {XMarkIcon} from "@heroicons/react/16/solid";

export const InstructionsInstallPwa = () => {
    const {instructions,hideInstructions} = useContextInstallPwa();

    if (!instructions) return null;

    return (
        <div className='z-50 fixed left-0 top-0 w-full bg-indigo-400 text-white p-5 flex justify-between gap-3 text-sm'>
            <div>
                <p className='mb-2'>Przepraszamy za wszelkie utrudnienia. Automatyczna instalacja naszej aplikacji nie jest wspierana przez Twoją przeglądarkę lub system operacyjny.</p>
                <div>{instructions}</div>
            </div>
            <XMarkIcon onClick={hideInstructions} className='w-7 h-7 min-w-7 cursor-pointer'/>
        </div>
    )
}
