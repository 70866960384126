import useAuth from "../hooks/useAuth";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PrimaryButton from "../../common/components/primary-button";
import React from "react";
import * as yup from "yup";
import {ChangePasswordBody} from "../types/change-password-body.interface";
import {PencilIcon} from "@heroicons/react/24/outline";
import PasswordFormField from "../../common/components/forms/password-form-field";
import {useModal} from "../../common/hooks/useModal";
import {ModalIds} from "../../common/constants/modal-ids.interface";


const schema = yup.object({
    currentPassword: yup
        .string()
        .required("Obecne hasło jest wymagane"),
    newPassword: yup
        .string()
        .min(8, "Hasło musi mieć co najmniej 8 znaków")
        .max(255, "Hasło może mieć maksymalnie 255 znaków")
        .matches(/[a-z]/, "Hasło musi zawierać co najmniej jedną małą literę")
        .matches(/[A-Z]/, "Hasło musi zawierać co najmniej jedną dużą literę")
        .matches(/\d/, "Hasło musi zawierać co najmniej jedną cyfrę")
        .matches(/[@$!%*?&#]/, "Hasło musi zawierać co najmniej jeden znak specjalny (@$!%*?&#)")
        .required("Nowe hasło jest wymagane"),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], "Hasła muszą się zgadzać")
        .required("Powtórz nowe hasło jest wymagane"),
}).required();

const ChangePasswordForm = () => {
    const {changePassword, loading} = useAuth();
    const {closeModal} = useModal()

    const methods = useForm<ChangePasswordBody>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        delayError: 100,
        disabled: loading,
    });

    const handleChangePasswordSubmit = async (data: ChangePasswordBody): Promise<void> => {
        await changePassword(data)
            .then(() => closeModal(ModalIds.CHANGE_PASSWORD))
            .catch()
            .finally()
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleChangePasswordSubmit)} className='flex flex-col gap-y-5'>
                <header className='flex gap-2 items-center mb-5'>
                    <PencilIcon className='w-5 h-5 text-green-600'/>
                    <h3 className='text-lg leading-6 font-semibold text-gray-900'>Zmień hasło</h3>
                </header>
                <PasswordFormField name='currentPassword' label='Obecne hasło'/>
                <div>
                    <PasswordFormField name='newPassword' label='Nowe hasło'/>
                    <span className='text-gray-500 text-sm leading-5 font-normal'>
                        Silne hasło powinno zawierać co najmniej 8 znaków w tym cyfry, litery (wielkiem i małe), a także znaki specjalne (np. ! @ #)
                    </span>
                </div>
                <PasswordFormField name='confirmNewPassword' label='Powtórz nowe hasło'/>
                <div className='flex gap-2 flex-wrap mt-5'>
                    <PrimaryButton
                        styleClass='max-w-fit h-fit px-3 py-2 text-sm leading-4 bg-primary_700 hover:bg-primary_800'
                        loading={loading} type='submit'>
                        Zapisz
                    </PrimaryButton>
                    <PrimaryButton
                        styleClass='max-w-fit h-fit px-3 py-2 text-sm leading-4 bg-white border-[1px] border-gray-300
                    !text-gray-700 hover:border-gray-400 hover:bg-white'
                        onClick={() => closeModal(ModalIds.CHANGE_PASSWORD)}
                        disabled={loading} type='button'>
                        Anuluj
                    </PrimaryButton>
                </div>
            </form>
        </FormProvider>
    )
}

export default ChangePasswordForm
