import React from 'react';

const withAnyProvider = <P extends object>(
    Provider: React.ComponentType<any>,
    WrappedComponent: React.ComponentType<P>
) => {
    return (props: P) => (
        <Provider>
            <WrappedComponent {...props} />
        </Provider>
    );
};

export default withAnyProvider;
