import { PlusIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";

interface ButtonAddNoteProps {
    openForm: () => void;
}

const ButtonAddNote = (props: ButtonAddNoteProps) => {
    const { openForm } = props;
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 0);
        return () => {
            clearTimeout(timer);
            setIsVisible(false);
        };
    }, []);

    return (
        <aside>
            <button
                className={`sm:hidden fixed bottom-24 right-2 w-14 h-14 rounded-full bg-primary_600 focus:outline-none focus:ring-2
                focus:ring-primary_100 focus:ring-offset-2 focus:bg-primary_700 hover:bg-primary_700 flex
                items-center justify-center transform transition-transform duration-200 ease-out
                ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}
                onClick={openForm}
            >
                <PlusIcon className='w-7 h-7 text-white font-bold' />
            </button>
        </aside>
    );
};

export default ButtonAddNote;
