import React, {useRef} from 'react';
import useElementStates from "../../core/hooks/use-element-states";
import {Subarea} from "../types/sub-area.interface";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import Completed from "./completed";
import {useNavigate, useParams} from "react-router-dom";

const Area: React.FC<Subarea> = (props) => {
    const refSubarea = useRef<HTMLElement>(null)
    const {isPressed, isHovered} = useElementStates({ref: refSubarea})
    const navigate = useNavigate();
    const params = useParams();

    const goToChapters = () => navigate(`/main/${encodeURIComponent(params['area'] ?? '')}/${encodeURIComponent(props.name)}`);

    return (
        <article
            onClick={goToChapters}
            ref={refSubarea}
            className={`relative rounded-2xl flex  sm:max-h-80 max-h-[80px] sm:min-w-72
            overflow-hidden shadow-lg ring-black ring-opacity-5 ring-1 bg-white
            transform transition-transform duration-300  cursor-pointer border-primary_600 box-border
            ${isHovered ? 'border-[1px]' : ''} cursor-pointer
            ${isPressed ? 'border-2' : ''} cursor-pointer`}
        >
            <header className='flex gap-4 rounded-l-lg overflow-hidden flex-grow m-4'>
                <ArrowRightIcon className='text-green-600 h-6 w-7 min-w-7'/>
                <h6 className='text-gray-900 font-semibold text-md truncate leading-7'>
                    {props.name}
                </h6>
            </header>
            <aside className='relative mt-4 mr-3'>
                <Completed isCompleted={props.isCompleted}/>
            </aside>
        </article>
    );
}

export default Area;
