import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/20/solid";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon
} from "@heroicons/react/24/outline";
import {PromptActionHandle} from "../types/prompt-action-handle.interface";
import {PromptVariant} from "../types/prompt-variants.interface";
import Spinner from "./spinner";


interface PromptProps {
    title: string;
    message: string;
    confirmText?: string;
    cancelText?: string;
    onConfirm?: () => void | Promise<void>;
    onReject?: () => void | Promise<void>;
    loading?: boolean;
    showClose?: boolean;
    showConfirm?: boolean;
    showReject?: boolean;
    customContent?: () => React.ReactNode;
    variant?: PromptVariant;
    customIcon?: React.ReactNode;
}

const PromptAction = forwardRef<PromptActionHandle, PromptProps>((props, ref) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isRejecting, setIsRejecting] = useState<boolean>(false);
    const {
        title,
        message,
        confirmText = "OK",
        cancelText = "Anuluj",
        onConfirm = () => {
        },
        onReject = () => {
        },
        showClose = true,
        showConfirm = true,
        showReject = true,
        loading = false,
        customContent,
        variant = "information",
        customIcon
    } = props;

    useImperativeHandle(ref, () => ({
        open() {
            setIsVisible(true)
        },
        close() {
            setIsVisible(false)
        },
        async confirm() {
            await handleConfirm()
        },
        async reject() {
            await handleConfirm()
        }
    }));

    const closeDialog = () => setIsVisible(false);

    const handleReject = async () => {
        setIsRejecting(true);
        try {
            await onReject();
            closeDialog();
        } finally {
            setIsRejecting(false);
        }
    };

    const handleConfirm = async () => {
        setIsConfirming(true);
        try {
            await onConfirm();
        } finally {
            setIsConfirming(false);
            closeDialog();
        }
    };

    const variantStyles = {
        success: {
            borderColor: "border-green-600",
            iconClass: 'text-green-600',
            defaultIcon: customIcon || <CheckCircleIcon/>,
            confirmButtonClass: "bg-green-700 text-white hover:bg-green-800 focus:bg-green-800"
        },
        error: {
            borderColor: "border-red-500",
            iconClass: 'text-red-500',
            defaultIcon: customIcon || <ExclamationCircleIcon/>,
            confirmButtonClass: "bg-red-500 text-white hover:bg-red-600 focus:bg-red-600"
        },
        information: {
            borderColor: "border-blue-500",
            iconClass: 'text-blue-500',
            defaultIcon: customIcon || <InformationCircleIcon/>,
            confirmButtonClass: "bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600"
        },
        warning: {
            borderColor: "border-yellow-600",
            iconClass: 'text-yellow-600',
            defaultIcon: customIcon || <ExclamationTriangleIcon/>,
            confirmButtonClass: "bg-yellow-400 text-white hover:bg-yellow-500 focus:bg-yellow-500 text-shadow-xl"
        }
    };

    const variantPrompt = variantStyles[variant];

    const renderIcon = () => {
        return applyIconStyles(variantPrompt.defaultIcon as React.ReactElement)
    };

    const applyIconStyles = (iconComponent: React.ReactElement) => {
        return React.cloneElement(iconComponent, {className: `${variantPrompt.iconClass} w-full h-full`});
    }

    return (
        <Dialog open={isVisible} onClose={closeDialog} className="relative z-20">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-white bg-opacity-60 backdrop-blur-[2px] transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                    {customContent ? (customContent()) : (
                        <DialogPanel
                            transition
                            className={`flex flex-col justify-between relative w-96 min-h-44 h-auto ${variantPrompt.borderColor} border-[1px] transform overflow-hidden rounded-lg bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6`}
                        >
                            <div className='flex gap-8'>
                                <div className="flex flex-col gap-3 flex-grow">
                                    <DialogTitle as="h3"
                                                 className="text-lg font-semibold leading-6 text-gray-900 flex gap-3 items-center">
                                        <span className='block w-5 h-5'>{renderIcon()}</span>
                                        <header>{title}</header>
                                    </DialogTitle>
                                    <p className="text-gray-500 text-sm font-semibold text-wrap break-words">
                                        {message}
                                    </p>
                                </div>
                                {showClose && (
                                    <div
                                        className={`w-6 h-6 min-w-6 min-h-6 cursor-pointer text-gray-400 focus:text-dark hover:text-dark`}
                                        onClick={closeDialog}>
                                        <XMarkIcon/>
                                    </div>
                                )}
                            </div>
                            <div className="mt-5 sm:mt-6 flex gap-3">
                                {showReject && (
                                    <button
                                        className='border-[1px] flex gap-3 justify-center items-center border-gray-300 min-w-16 rounded-3xl px-3 py-2 text-sm leading-4 text-gray-700 hover:bg-gray-200 focus:bg-gray-200'
                                        onClick={handleReject}
                                        disabled={isConfirming || isRejecting || loading}
                                    >
                                        {isRejecting || loading ? <Spinner/> : ''}
                                        {cancelText}
                                    </button>
                                )}
                                {showConfirm && (
                                    <button
                                        className={`min-w-16 flex gap-3 justify-center items-center rounded-3xl px-3 py-2 text-sm leading-4 ${variantPrompt.confirmButtonClass}`}
                                        onClick={handleConfirm}
                                        disabled={isConfirming || isRejecting || loading}
                                    >
                                        {isConfirming || loading ? <Spinner color='white'/> : ''}
                                        {confirmText}
                                    </button>
                                )}
                            </div>
                        </DialogPanel>
                    )}
                </div>
            </div>
        </Dialog>
    );
});

export default PromptAction;
