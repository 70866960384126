import {BackgroundInterface, Backgrounds} from "../types/background.interface";
import {Theme} from "../../core/types/theme.interface";

const backgrounds: BackgroundInterface = {
    [Backgrounds.NONE]: {
        theme: Theme.LIGHT,
        bgBackdropClasses: 'none',
    },
    [Backgrounds.GREEN]: {
        theme: Theme.LIGHT,
        bgBackdropClasses: 'app-bg-backdrop-green',
    },
    [Backgrounds.YELLOW]: {
        theme: Theme.LIGHT,
        bgBackdropClasses: 'app-bg-backdrop-yellow',
    },
    [Backgrounds.BLUE]: {
        theme: Theme.LIGHT,
        bgBackdropClasses: 'app-bg-backdrop-blue',
    },
    [Backgrounds.DARK_GREEN]: {
        theme: Theme.DARK,
        bgBackdropClasses: 'app-bg-backdrop-dark-green',
    },
}

export {backgrounds}
