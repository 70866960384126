import {createContext, ReactNode, useState} from "react";
import {ModalContextType} from "../types/modal-context-type.interface";
import {ModalIds} from "../constants/modal-ids.interface";

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = ({children}: { children: ReactNode }) => {
    const [openModals, setOpenModals] = useState<{ [key: string]: boolean }>({});

    const openModal = (id: ModalIds) => {
        setOpenModals((prev) => ({...prev, [id]: true}));
    };

    const closeModal = (id: ModalIds) => {
        setOpenModals((prev) => ({...prev, [id]: false}));
    };

    const isOpenModal = (id: ModalIds) => {
        return openModals[id];
    };

    const registerModals = (ids: ModalIds[]) => {
        setOpenModals((prev) => {
            const modals = {...prev};
            ids.forEach((id) => {
                if (!(id in modals)) {
                    modals[id] = false;
                }
            });
            return modals;
        });
    };

    return (
        <ModalContext.Provider value={{isOpenModal, openModal, closeModal, registerModals}}>
            {children}
        </ModalContext.Provider>
    );
};
