import ApiService from "../../core/services/api.service";
import {PromoCodeDetails} from "../types/promo-codes/promo-code.interface";

class PromoCodesApiService extends ApiService {

    constructor() {
        super();
    }

    public async checkPromoCode(code: string): Promise<PromoCodeDetails> {
        return await this.get<PromoCodeDetails>(`/promo-codes/details`, {
            params: {value: code},
            skipDefault404Handling: true,
        })
            .catch((err)=> {
                throw err
            })
            .finally()
    }

}

export default new PromoCodesApiService();
