import React, {useEffect, useRef} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";

interface TextareaFormFieldProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label: string;
    className?: string;
    isLoading?: boolean;
    rows?: number;
    maxChar?: number;
}

const TextareaFormField: React.FC<TextareaFormFieldProps> = (props: TextareaFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}, watch} = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        rows = 4,
        maxChar = 255,
        disabled,
        ...rest
    } = props;
    const value = watch(name) || "";
    const containerRef = useRef<HTMLDivElement | null>(null);

    function adjustTextareaHeight() {
        const textarea = containerRef.current?.children[1] as HTMLTextAreaElement;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    const handleInput = (e: any) => {
        const textarea = e.target;
        textarea.scrollIntoView({behavior: 'auto', block: 'nearest'});
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [value]);

    return (
        <div className="mb-2 w-full" ref={containerRef}>
            <label htmlFor={name} className="block text-gray-700 text-sm">{label}</label>
            {isLoading
                ? <SkeletonFormField/>
                : (
                    <textarea
                        id={name}
                        rows={rows}
                        onInput={handleInput}
                        className={`p-3 placeholder:text-gray-500 text-gray-900 text-base leading-6 font-normal border border-gray-300 
                            bg-white shadow-sm rounded-md focus:outline-none focus:ring-2 w-full  overflow-hidden resize-none
                            ${errors[name] || (value.length > maxChar) ? "border-red-500 focus:ring-red-500" : "border-gray-300 " +
                            "focus:ring-primary_400"} ${className}`}
                        {...register(name)}
                        disabled={isSubmitting || disabled}
                        {...rest}
                    ></textarea>)
            }
            {errors[name] &&
                (<div className="flex flex-wrap justify-between flex-row-reverse">
                    {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
                    <span className={`text-sm leading-5 font-normal ${
                        errors[name] || (value.length > maxChar) ? "text-red-500" : "text-gray-500 theme-dark:text-gray-50"
                    }`}
                    >
                    {value.length}/{maxChar}
                </span>
                </div>)
            }
        </div>
    );
};

export default TextareaFormField;
