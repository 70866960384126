import React from 'react';
import { BaseBlock } from "../types/base-block.interface";
import { ISeparatorBlock } from "../types/blocks/seperator-block.interface";

const SeparatorBlock: React.FC<BaseBlock<ISeparatorBlock>> = (props: BaseBlock<ISeparatorBlock>) => {
    const { data } = props;
    const { text} = data;

    return (
        <div className="relative w-full my-6">
            <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
            </div>
            {text && (
                <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                        {text}
                    </span>
                </div>
            )}
        </div>
    );
}

export default SeparatorBlock;
