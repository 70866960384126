import {MutableRefObject, useEffect, useState} from 'react';

interface UseElementStatesProps {
    ref: MutableRefObject<HTMLElement | null>;
}

const useElementStates = ({ref}: UseElementStatesProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isPressed, setIsPressed] = useState(false);

    useEffect(() => {
        if (!ref.current) return;

        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);
        const handleMouseDown = () => {
            setIsActive(true);
            setIsPressed(true);
        };
        const handleMouseUp = () => {
            setIsActive(false);
            setIsPressed(false);
        };
        const handleTouchStart = () => setIsPressed(true);
        const handleTouchEnd = () => setIsPressed(false);
        const handleClick = () => setIsClicked(prev => !prev);
        const handleFocus = () => setIsFocused(true);
        const handleBlur = () => setIsFocused(false);

        const element = ref.current;

        element.addEventListener('mouseenter', handleMouseEnter);
        element.addEventListener('mouseleave', handleMouseLeave);
        element.addEventListener('mousedown', handleMouseDown);
        element.addEventListener('mouseup', handleMouseUp);
        element.addEventListener('touchstart', handleTouchStart);
        element.addEventListener('touchend', handleTouchEnd);
        element.addEventListener('click', handleClick);
        element.addEventListener('focus', handleFocus);
        element.addEventListener('blur', handleBlur);

        return () => {
            element.removeEventListener('mouseenter', handleMouseEnter);
            element.removeEventListener('mouseleave', handleMouseLeave);
            element.removeEventListener('mousedown', handleMouseDown);
            element.removeEventListener('mouseup', handleMouseUp);
            element.removeEventListener('touchstart', handleTouchStart);
            element.removeEventListener('touchend', handleTouchEnd);
            element.removeEventListener('click', handleClick);
            element.removeEventListener('focus', handleFocus);
            element.removeEventListener('blur', handleBlur);
        };
    }, [ref]);

    return { isHovered, isClicked, isFocused, isActive, isPressed };
};

export default useElementStates;
