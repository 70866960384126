import {useState} from 'react';
import {IntroductionFormBody} from "../types/introduction-form.interface";
import useAuth from "../../auth/hooks/useAuth";
import IntroductionApiService from "../services/introduction-api.service";


export const useIntroductionApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const {currentUser} = useAuth();
    const user = currentUser;

    const sendUserData = async (body: IntroductionFormBody) => {
        setLoading(true);
        setError(null);

        return await IntroductionApiService.sendUserData(user!.id, body)
            .catch((err) => {
                setError(err);
                throw err
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return {loading, error, sendUserData};
};
