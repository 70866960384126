import React, {useEffect, useState} from "react";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    styleClass?: string;
    onClick?: () => void;
    children?: React.ReactNode;
    loading?: boolean;
    disabled?: boolean,
    icon?: React.ReactNode,
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const {
        styleClass,
        loading: parentLoading,
        onClick,
        children, icon,
        ...rest
    } = props;
    const [ownLoading, setOwnLoading] = useState(false);

    useEffect(() => {
        if (parentLoading !== undefined) {
            setOwnLoading(parentLoading);
        }
    }, [parentLoading]);

    const handleClick = async () => {
        if (onClick) {
            setOwnLoading(true);
            try {
                await onClick();
            } finally {
                setOwnLoading(false);
            }
        }
    };

    const isLoading = onClick ? ownLoading : parentLoading;

    return (
        <button
            type="button"
            className={`w-full h-11 text-white rounded-3xl focus:outline-none focus:ring-2 
                focus:ring-primary_100 focus:ring-offset-2 flex items-center justify-center 
                text-base leading-6 font-medium
                ${isLoading ? 'bg-gray-500 hover:bg-gray-500' : 'bg-primary_700 hover:bg-primary_800'} ${styleClass}`}
            onClick={handleClick}
            disabled={isLoading || props.disabled}
            {...rest}
        >
            {isLoading && (
                <svg className="w-5 h-5 mr-3 text-white animate-spin" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            )}
            {children}
            {!isLoading && icon}
        </button>
    );
};

export default Button;
