import {useContext} from "react";
import {CalendarFormContext} from "../contexts/calendar-form-context";

export const useCalendarForm = () => {
    const context = useContext(CalendarFormContext);
    if (!context) {
        throw new Error("useCalendarForm must be used within a CalendarFormProvider");
    }
    return context;
};
