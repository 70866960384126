import React from 'react';
import {ModalProvider} from "../contexts/modal-context";

const withModalProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => (
        <ModalProvider>
            <WrappedComponent {...props} />
        </ModalProvider>
    );
};

export default withModalProvider;
