import React, {ComponentType} from 'react';
import {StepperIntroductionProvider} from "../contexts/stepper-introduction-context";

interface WithStepperProviderProps {
}

const withStepperProvider = <P extends WithStepperProviderProps>(WrappedComponent: ComponentType<P>) => {
    return (props: P) => {
        return (
            <StepperIntroductionProvider>
                <WrappedComponent {...props} />
            </StepperIntroductionProvider>
        )
    };
};

export default withStepperProvider;
