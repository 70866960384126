import React, {useEffect, useState} from 'react';
import {PurchasedSubscription} from "../types/purchased-subscription.interface";
import useSubscriptionApi from "../hooks/use-subscription-api";
import {CheckIcon} from "@heroicons/react/24/solid";
import StatusLabel from "../../common/components/status-label";
import PrimaryButton from "../../common/components/primary-button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {DateHelper} from "../../core/utils/dateHelper";
import {useNavigate} from "react-router-dom";

const PurchasedSubscriptions: React.FC = () => {
    const {
        getListAllPurchasedSubscriptions,
        deactivateSubscription,
    } = useSubscriptionApi();
    const navigate = useNavigate();
    const [subscriptions, setSubscriptions] = useState<Array<PurchasedSubscription>>([]);

    useEffect(() => {
        handleFetchListPurchasedSubscriptions();
    }, []);

    const handleReSubscribe = () => {
        navigate("/main/subscriptions");
    };

    const handleDeactivateSubscription = (subscriptionId: number) => {
        deactivateSubscription(subscriptionId)
            .then(() => handleFetchListPurchasedSubscriptions())
            .catch(() => {
            })
            .finally()
    };

    const handleFetchListPurchasedSubscriptions = () => {
        getListAllPurchasedSubscriptions()
            .then(res => setSubscriptions(res ?? []))
            .catch(() => {
            })
            .finally()
    }

    return (
        <div className='md:max-w-[720px] mx-auto w-full'>
            <h2 className='text-base leading-6 font-semibold mb-3'>Subskrypcje</h2>
            {subscriptions.map((subscription) => (
                <div key={subscription.id} className="p-4 bg-white shadow-sm rounded-lg mb-4 flex justify-between gap-2">
                    <div className='flex flex-col justify-between gap-3 flex-grow'>
                        <h3 className="text-base leading-6 font-medium text-gray-600">{subscription.name}</h3>
                        {subscription.isActive ? (
                            <PrimaryButton
                                onClick={handleDeactivateSubscription.bind(this, subscription.id)}
                                className="bg-transparent min-w-max max-w-min border rounded-full px-4 py-2 border-gray-300 text-gray-700 text-xs leading-4 font-medium">
                                Anuluj subskrypcję
                            </PrimaryButton>
                        ) : (
                            <PrimaryButton
                                onClick={handleReSubscribe}
                                className="bg-green-100 min-w-max max-w-min rounded-full px-4 py-2 shadow-sm text-green-700 text-xs leading-4 font-medium">
                                Subskrybuj ponownie
                            </PrimaryButton>
                        )}
                    </div>
                    {subscription.isActive ? (
                        <div className="flex items-end gap-2 flex-col justify-between">
                            <StatusLabel
                                text={'Aktywna'}
                                icon={<CheckIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit font-medium leading-3 text-xs bg-white text-green-700 border border-green-500'
                            />
                            <span className='text-[0.7rem] font-semibold text-gray-800 text-wrap text-right mr-1'>
                                obowiązuje do
                                <span className='ml-1'>
                                    {DateHelper.mapDate(subscription.expiryDate, 'YYYY-MM-DD', 'DD.MM.YYYY r.')}
                                </span>
                            </span>
                        </div>
                    ) : (
                        <div className="flex items-end gap-2 flex-col justify-between">
                            <StatusLabel
                                text='Nieaktywna'
                                icon={<XMarkIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit font-medium leading-3 text-xs bg-white text-gray-700 border border-gray-300'
                            />
                            {!!subscription.expiryDate && (
                                <span className='text-[0.7rem] font-semibold text-gray-800 text-wrap text-right mr-1'>
                                    obowiązuje do
                                    <span className='ml-1'>
                                        {DateHelper.mapDate(subscription.expiryDate, 'YYYY-MM-DD', 'DD.MM.YYYY r.')}
                                    </span>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            ))}
            {subscriptions.length === 0 && (
                <div className="p-4 bg-white shadow-sm rounded-lg mb-4 text-gray-900 text-center">
                    <span>Brak wykupionych subskrypcji</span>
                </div>
            )}
        </div>
    );
};

export default PurchasedSubscriptions;
