import ApiService from "../../core/services/api.service";

class CoursesApiService extends ApiService {

    constructor() {
        super();
    }

    public async startPaymentForCourse(areaName: string,promoCode?:string): Promise<string> {
        return await this.post<string>(`/tpay/start-payment-course/${areaName}`, {},{
            params:{
                code: promoCode,
            }
        })
            .then(res => res || '')
    }
}

export default new CoursesApiService();
