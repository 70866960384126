import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import NotificationService from "../../common/services/notification.service";

const TokenHandler = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const {handleAdminTokenLogin, isAuthenticated, currentUser} = useAuth();

    useEffect(() => {
        if (token) {
            handleAdminTokenLogin(token)
                .then(() => navigate("/main"))
                .catch(() => !isAuthenticated && navigate('/login'))
        }
    }, [token]);

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="flex flex-col items-center">
                <div
                    className="animate-spin ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-4"></div>
                <p className="text-xl text-gray-700">Processing...</p>
            </div>
        </div>
    )
};

export default TokenHandler;
