import {useContext} from "react";
import {FormManagerContext, FormManagerContextProps} from "../contexts/form-manager-context";

export const useFormManager = (): FormManagerContextProps => {
    const context = useContext(FormManagerContext);
    if (!context) {
        throw new Error('useFormManager must be used within a FormManagerProvider');
    }
    return context;
};
