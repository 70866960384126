import { Note as NoteI } from "../types/note.interface";
import { DateHelper } from "../../core/utils/dateHelper";
import React, {useRef, useState} from "react";

interface NoteProps {
    onClick: (note: NoteI) => void;
    onLongPress?: (note: NoteI) => void;
    note: NoteI;
    isSelectable: boolean;
    isSelected: boolean;
}

const Note = (props: NoteProps) => {
    const { note, onClick, onLongPress, isSelectable, isSelected } = props;
    const { id, content, title, edited } = note;
    const longPressTimeout = useRef<NodeJS.Timeout | null>(null);
    const [isLongPress, setIsLongPress] = useState(false);

    const startLongPress = () => {
        if (!isSelectable && onLongPress) {
            longPressTimeout.current = setTimeout(() => {
                onLongPress(note);
                setIsLongPress(true);
            }, 500);
        }
    };

    const cancelLongPress = () => {
        if (longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
            longPressTimeout.current = null;
        }
        setIsLongPress(false);
    };

    const handleClick = () => {
        if (!isLongPress) {
            onClick(note);
        }
        setIsLongPress(false);
    };

    return (
        <article
            onMouseDown={startLongPress}
            onTouchStart={startLongPress}
            onMouseUp={cancelLongPress}
            onMouseLeave={cancelLongPress}
            onTouchEnd={cancelLongPress}
            onClick={handleClick}
            className={`max-h-28 min-h-28 w-full p-3 bg-white shadow-lg ring-1 ring-black hover:cursor-pointer ring-opacity-5 rounded-2xl flex flex-col justify-between ${isSelectable ? 'cursor-pointer' : ''}`}>
            <header className='flex justify-between items-end'>
                <div className='flex flex-col flex-grow'>
                    <h3 className='font-bold text-lg leading-7 truncate text-gray-900'>{title ? title : 'Brak tytułu'}</h3>
                    <span className='text-gray-500 text-xs leading-4'>{DateHelper.timeAgo(edited)}</span>
                </div>
                {isSelectable ? (
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={()=>{}}
                        className="appearance-none border min-h-4 min-w-4 h-4 w-4 rounded mr-2 shadow-md focus:outline-none checked:bg-primary focus:ring-0"
                    />
                ) : null}
            </header>
            <p className='text-gray-600 text-base leading-none font-normal truncate'>
                {content}
            </p>
        </article>
    );
};

export default Note;
