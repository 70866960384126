import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import {navigation} from "./menu";
import {classNames} from "../utils/class-names";
import {useOverlay} from "../modules/core/hooks/useOverlaySidebarMenu";

interface SideBarLayerProps {
    isMenuOpen: boolean;
}

const SideBarLayer: React.FC<SideBarLayerProps> = ({isMenuOpen}) => {
    const {OverlayComponent} = useOverlay();
    const location = useLocation();

    const determineIsActive = (
        itemHref: string,
        strictActive: boolean,
        locationPathname: string,
        navigationItems: typeof navigation
    ): boolean => {
        if (strictActive) {
            return locationPathname === itemHref;
        } else {
            return locationPathname.startsWith(itemHref) && !navigationItems.some(navItem =>
                navItem.strictActive && locationPathname.startsWith(navItem.href)
            );
        }
    };

    return (
        <>
            <div
                className={classNames(
                    isMenuOpen ? "hidden" : "block",
                    "z-30 relative sm:hidden flex justify-around h-16 bg-white shadow-sm gap-x-8 px-2 min-h-20 sm:max-w-sm border-t-2 border-gray-300"
                )}
            >
                {navigation
                    .filter((item) => item.hasShortCut)
                    .map((item) => {
                        const isActive = determineIsActive(item.href, item.strictActive, location.pathname, navigation);

                        return (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                end
                                className={classNames(
                                    isActive
                                        ? "border-green-600 text-gray-900"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "flex flex-1 flex-col items-center justify-center border-t-4 text-sm font-medium text-nowrap"
                                )}
                            >
                                <item.Icon aria-hidden="true" className="h-5 w-5 text-gray-600"/>
                                <span className="min-[335px]:block hidden">{item.name}</span>
                            </NavLink>
                        );
                    })}
                {OverlayComponent && (
                    <div className='absolute w-full h-full top-0 left-0 z-[21] bg-white'>
                        {OverlayComponent}
                    </div>
                )}
            </div>
        </>
    );
};

export default SideBarLayer;
