import {useLocation, useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {useEffect, useState} from "react";

interface BreadcrumbAreaProps {
    skipStart?: number;
    skipEnd?: number;
}

const BreadcrumbArea = ({skipEnd, skipStart = 1}: BreadcrumbAreaProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [breadcrumbPath, setBreadcrumbPath] = useState<string[]>([]);

    useEffect(() => {
        const pathnames: string[] = location.pathname
            .split('/')
            .filter((x) => decodeURIComponent(x))
            .slice(skipStart, skipEnd ?? undefined)

        setBreadcrumbPath(pathnames);
    }, [location.pathname, skipStart, skipEnd]);

    if (breadcrumbPath.length === 0 || breadcrumbPath.length > 2) return null;

    return (
        <nav className="flex items-end space-x-4 mb-8 justify-between sm:mb-12">
            <div className="flex">
                <header className="w-full text-dark font-bold text-2xl flex flex-col">
                    {breadcrumbPath.length > 1 && (
                        <span className="text-gray-400 text-sm font-semibold leading-5">
                            {decodeURIComponent(breadcrumbPath[breadcrumbPath.length - 1])}
                        </span>
                    )}
                    <span>
                        {decodeURIComponent(breadcrumbPath[0])}
                    </span>
                </header>
            </div>
            <button
                onClick={() => navigate(-1)}
                className="bg-white border-[1px] border-gray-300 w-11 hover:text-primary_50 duration-100 h-11 rounded-full flex items-center justify-center"
            >
                <ChevronLeftIcon className="w-6 h-6 text-gray-900"/>
            </button>
        </nav>
    );
};

export default BreadcrumbArea;
