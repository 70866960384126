import {useContext} from "react";
import {OverlayContextProps, OverlaySidebarMenuContext} from "../contexts/overlay-sidebar-menu-context";

export const useOverlay = (): OverlayContextProps => {
    const context = useContext(OverlaySidebarMenuContext);
    if (!context) {
        throw new Error('useOverlay must be used within an OverlayProvider');
    }
    return context;
};
