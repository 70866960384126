import Settings from "./settings";

const MyProfile = () => {

    return (
        <Settings/>
    )
}

export default MyProfile
