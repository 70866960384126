import {
    AcademicCapIcon,
    CalendarDaysIcon, CurrencyDollarIcon,
    HeartIcon,
    LifebuoyIcon,
    PencilSquareIcon,
    UserIcon,
    KeyIcon
} from "@heroicons/react/24/outline";

export const navigation = [
    {name: 'Mój profil', href: '/main/profile', Icon: UserIcon, hasShortCut: false, strictActive: true},
    {name: 'Obszary', href: '/main', Icon: HeartIcon, hasShortCut: true, strictActive: false},
    {name: 'Kursy', href: '/main/courses', Icon: AcademicCapIcon, hasShortCut: false, strictActive: true},
    {name: 'Historia transakcji', href: '/main/history-transactions', Icon: CurrencyDollarIcon, hasShortCut: false, strictActive: true},
    {name: 'Subskrypcje', href: '/main/subscriptions', Icon: KeyIcon, hasShortCut: false, strictActive: true},
    {name: 'Kalendarz', href: '/main/calendar', Icon: CalendarDaysIcon, hasShortCut: true, strictActive: true},
    {name: 'Notatki', href: '/main/notes', Icon: PencilSquareIcon, hasShortCut: true, strictActive: true},
    {name: 'Pilna pomoc', href: '/main/support', Icon: LifebuoyIcon, hasShortCut: true, strictActive: true},
]
