import {createContext, ReactNode, useEffect, useState} from "react";
import {Theme} from "../types/theme.interface";

export interface ThemeContextProps {
    theme: Theme;
    setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [theme, setTheme] = useState<Theme>(Theme.LIGHT);

    useEffect(() => {
        document.documentElement.className = `theme-${theme}`;
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export {ThemeContext, ThemeProvider}
