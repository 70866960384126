import ApiService, {CustomAxiosRequestConfig} from "../../core/services/api.service";
import {Authentication} from "../types/authentication.interface";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";
import {AuthenticationResponse} from "../types/authentication-response.interface";
import {UserAuthority} from "../types/user-authority.interface";
import {ResetPasswordBodyRequest} from "../types/reset-email-body.interface";
import {AuthenticatedUser} from "../types/authenticated-user.interface";
import {ChangePasswordBodyRequest} from "../types/change-password-body.interface";
import {getFirebaseToken, messagingFirebaseApp} from "../../../firebase/firebase";
import {firebaseVapidKey} from "../../../firebase/firebase.config";

class AuthService extends ApiService {

    private authentication: Authentication | null = this.loadAuthenticationFromLocalStorage();
    private authChangeSubscribers: ((isAuthenticated: boolean) => void)[] = [];

    constructor() {
        super();
    }

    public async authenticate(email: string, password: string): Promise<string> {
        const authHeader: string = 'Basic ' + btoa(`${email}:${password}`);
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': authHeader
            },
            params: {
                frontend: 'application',
            },
            errorMessage: "Błędny login lub hasło.",
            skipDefault401Handling: true,
        };

        if (!this.isAuthenticated()) {
            this.authentication = null;
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        }

        return await this.post<AuthenticationResponse>('/authenticate', {}, httpOptions)
            .then((res: AuthenticationResponse): string => {
                this.authentication = this.prepareAuthentication(res);
                LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                this.notifyAuthChange();
                return "Sukces"
            })
    }

    public subscribeToAuthChanges(callback: (isAuthenticated: boolean) => void) {
        this.authChangeSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.authChangeSubscribers = this.authChangeSubscribers.filter(sub => sub !== callback);
            }
        };
    }

    private notifyAuthChange() {
        const isAuthenticated = this.isAuthenticated();
        this.authChangeSubscribers.forEach(callback => callback(isAuthenticated));
    }

    public async getRequestAndSendNotificationToken() {
        let token: string = "";

        const requestPermission = async () => {
            try {
                navigator.serviceWorker.ready.then(async (serviceWorker) => {
                    const permission = await Notification.requestPermission();
                    if (permission === "granted") {
                        token = await getFirebaseToken(messagingFirebaseApp, {
                            serviceWorkerRegistration: serviceWorker,
                            vapidKey: firebaseVapidKey,
                        });
                        await this.subscribeNotifications(token);
                        console.log("Token generated: ", token);
                    } else if (permission === "denied") {
                        alert("You denied the notification permission.");
                    }
                });
            } catch (error) {
                console.error("Error getting token or subscribing: ", error);
            }
        };

        await requestPermission();
    }

    public async subscribeNotifications(token: string): Promise<void> {
        return await this.post<void>('notification/subscribe', {}, {
            params: {token},
            skipDefault404Handling: true,
        }).then(() => console.log('subscribe'));
    }

    public async handleAdminTokenLogin(token: string): Promise<void> {
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            successMessage: "Zostałeś zalogowany na wskazanego użytkownika!",
            errorMessage: "Logowanie na wybranego usera nie powiodło się.",
            skipDefault401Handling: true,
        };

        if (this.isAuthenticated()) {
            this.authentication = null;
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        }

        return await this.get<AuthenticatedUser>('/authenticated-user', httpOptions)
            .then((res: AuthenticatedUser): void => {
                if (res) {
                    let now: Date = new Date();
                    now.setMinutes(now.getMinutes() + 30);
                    now = new Date(now);
                    this.authentication = {user: res, token: {value: token, validUntil: now}};
                    LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                    this.notifyAuthChange();
                }
            })
    }

    public async sendEmailConfirmationToken(email: string): Promise<void> {
        return await this.post<void>('/send-email-confirm-token', {email}, {
            skipDefault404Handling: true,
            successMessage: 'Wysłano link aktywacyjny na podany adres e-mail!',
            errorMessage: "Nie udało się wysłać linku aktywacjnego na podany adres e-mail."
        }).then();
    }

    public async activateAccount(userId: string, token: string): Promise<void> {
        return await this.put<void>(`/activate-account/${userId}`, {token}, {
            skipDefault404Handling: true,
            successMessage: 'Konto zostało zaktywowane!',
            errorMessage: "Nie udało się zaktywować konta."
        }).then();
    }

    public async getTokenResetPassword(email: string): Promise<void> {
        return await this.post<void>('/send-password-reset-token', {email}, {
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            isPublic: true,
            successMessage: "Wysłano token na podany adres e-mail!",
            errorMessage: "Nie udało się wysłać tokenu na podany adres e-mail."
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_RESET_PASSWORD);
                LocalStorageService.save(LocalStorageKeys.EMAIL_RESET_PASSWORD, email);
            })
    }

    public async resetPassword(body: ResetPasswordBodyRequest): Promise<void> {
        return await this.put<void>('/reset-password', body, {
            isPublic: true,
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            successMessage: "Twoje hasło zostało zmienione! Możesz się teraz zalogować.",
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_RESET_PASSWORD);
            });
    }

    public async changePassword(body: ChangePasswordBodyRequest): Promise<void> {
        return await this.put<void>('/change-password', body, {
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            successMessage: "Twoje hasło zostało zmienione!.",
        })
    }

    public get authenticatedUser(): AuthenticatedUser | undefined {
        return this.authentication?.user;
    }

    public logout(): void {
        this.authentication = null;
        LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        this.notifyAuthChange();
    }

    public isAuthenticated(): boolean {
        return !!this.authentication && new Date(this.authentication.token.validUntil).getTime() > new Date().getTime();
    }

    public updateAuthenticatedUser(authenticatedUser: AuthenticatedUser) {
        this.authentication = {
            user: authenticatedUser,
            token: this.authentication?.token!
        };
        LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
    }

    public updateAuthenticationField<K extends keyof Authentication>(
        key: K,
        value: Authentication[K]
    ): void {
        if (this.authentication) {
            this.authentication = {
                ...this.authentication,
                [key]: value
            };
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
        }
    }

    private loadAuthenticationFromLocalStorage(): Authentication | null {
        const authenticationStringJson: string | null = LocalStorageService.get(LocalStorageKeys.AUTHENTICATION);
        if (authenticationStringJson) {
            return JSON.parse(authenticationStringJson);
        } else {
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            return null;
        }
    }

    private prepareAuthentication(authenticationResponse: AuthenticationResponse): Authentication {
        return {
            user: {
                id: authenticationResponse.user.id,
                email: authenticationResponse.user.email,
                firstname: authenticationResponse.user.firstname,
                lastname: authenticationResponse.user.lastname,
                authority: authenticationResponse.user.authority as UserAuthority,
                hasCompletedIntroduction: authenticationResponse.user.hasCompletedIntroduction,
                emailConfirmed: authenticationResponse.user.emailConfirmed,
                profilePictureBase64: authenticationResponse.user.profilePictureBase64,
                introductionScreenName: authenticationResponse.user.introductionScreenName ?? null,
                userCourses: authenticationResponse.user.userCourses,
                userSubscriptions: authenticationResponse.user.userSubscriptions,
                isTrialSubscriptionAvailable: authenticationResponse.user.isTrialSubscriptionAvailable,
            },
            token: {
                value: authenticationResponse.token,
                validUntil: this.prepareTokenValidUntil(authenticationResponse.tokenValidInMinutes)
            }
        }
    }

    private prepareTokenValidUntil(tokenValidInMinutes: number): Date {
        // return new Date(new Date().getTime() + (tokenValidInMinutes - 1) * 60 * 1000);
        return new Date(new Date().getTime() + tokenValidInMinutes * 60 * 1000);
    }
}

export default new AuthService();
