import React from "react";

interface CompletedEntityProps {
    isCompleted: boolean,
    bgColor?: string,
    className?: string,
}

const CompletedEntity = ({isCompleted, bgColor,className}: CompletedEntityProps) => {
    const colorBg: string = bgColor ? bgColor : 'bg-primary_600'

    if (isCompleted) return (
        <span className={`block w-5 h-5 rounded-full border-2 border-white ${colorBg} ${className}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="white"
                className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"/>
            </svg>
        </span>
    )
    else return null
}

export default CompletedEntity
