import {useState} from 'react';
import UserApiService from '../services/user-api.service';
import {GetUserData} from '../types/get-user-data.interface';
import useAuth from "../../auth/hooks/useAuth";
import {RequestBodyUserData} from "../types/request-body-user-data.interface";
import {RequestBodyUploadProfileImage} from "../types/request-body-upload-profile-image.interface";
import {extractBase64} from "../../../utils/extract-base64";
import {AuthenticatedUser} from "../../auth/types/authenticated-user.interface";
import AuthService from "../../auth/services/auth.service";
import {AxiosError} from "axios";

interface UseUserApiResult {
    loading: boolean;
    loadingData: boolean;
    getUserData: () => Promise<GetUserData>;
    saveUserData: (body: RequestBodyUserData) => Promise<void>;
    uploadProfilePicture: (body: RequestBodyUploadProfileImage) => Promise<void>;
}

export const useUserApi = (): UseUserApiResult => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tempUserData, setTempUserData] = useState<AuthenticatedUser | null>(null)
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const {currentUser,setCurrentUser} = useAuth();
    const user = currentUser!;


    const getUserData = async (): Promise<GetUserData> => {
        setLoadingData(true);
        return await UserApiService
            .getUserData(user.id)
            .catch(()=>{
                return {} as any
            })
            .finally(() => setLoadingData(false))
    };

    const saveUserData = async (body: RequestBodyUserData): Promise<void> => {
        setLoading(true);
        return await UserApiService
            .saveUserData(body)
            .then(() => {
                setTempUserData(prevTempUserData => {
                    let userData = prevTempUserData ? {...prevTempUserData} : {...user};
                    const authenticatedUser: AuthenticatedUser = {
                        ...userData,
                        firstname: body.firstname,
                        lastname: body.lastname,
                    }
                    setCurrentUser(authenticatedUser);
                    AuthService.updateAuthenticatedUser(authenticatedUser);
                    return authenticatedUser;
                })
            })
            .catch((err)=>{
                throw err
            })
            .finally(() => setLoading(false))
    };

    const uploadProfilePicture = async (body: RequestBodyUploadProfileImage): Promise<void> => {
        setLoading(true);
        const mappedBody: RequestBodyUploadProfileImage = {
            ...body,
            contentBase64: body.contentBase64 ? extractBase64(body.contentBase64) : null
        }
        return await UserApiService
            .uploadProfilePicture(mappedBody)
            .then(() => {
                setTempUserData(prevTempUserData => {
                    let userData = prevTempUserData ? { ...prevTempUserData } : { ...user };
                    const authenticatedUser: AuthenticatedUser = {
                        ...userData,
                        profilePictureBase64: body.contentBase64 ? extractBase64(body.contentBase64) : ""
                    };
                    setCurrentUser(authenticatedUser);
                    AuthService.updateAuthenticatedUser(authenticatedUser);
                    return authenticatedUser;
                });
            })
            .catch((err)=>{
                throw err
            })
            .finally(() => setLoading(false))
    };

    return {
        loading,
        loadingData,
        getUserData,
        saveUserData,
        uploadProfilePicture
    };
};
