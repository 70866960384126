import {StepperIntroductionContext} from "../contexts/stepper-introduction-context";
import {useContext} from "react";

export const useStepperIntroduction = () => {
    const context = useContext(StepperIntroductionContext);
    if (!context) {
        throw new Error('useStepper must be used within a StepperProvider');
    }
    return context;
};
