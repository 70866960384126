import ValidationError from "./validation-error.service";
import {ErrorHandler, ErrorHandlers, StatusCode} from "../types/error-handler.interface";
import {AxiosError} from "axios";
import {ValidationErrors} from "../types/validation-errors";
import AuthService from "../../auth/services/auth.service";
import NotificationService from "../../common/services/notification.service";
import {CustomAxiosRequestConfig} from "./api.service";

class ErrorHandling {
    public handlers: ErrorHandlers = {};
    public validationErrorHandler: ValidationError | null = null;

    constructor() {
        this.addDefaultHandlers();
    }

    handle(error: AxiosError): void {
        let status = error.response ? error.response.status : null;
        if (status && this.handlers[status]) {
            this.handlers[status](error);
        } else {
            this.handleDefault(error);
        }
    }

    handleDefault(error: AxiosError): void {
        const customMessage = (error.config as CustomAxiosRequestConfig).errorMessage

        const defaultErrorMessage: string = customMessage || "Wystąpił nieoczekiwany błąd";
        NotificationService.error(defaultErrorMessage, error);
        console.error('Default handle error:', error);
    }

    private addHandler(statusCode: StatusCode, handler: ErrorHandler): void {
        this.handlers[statusCode] = handler;
    }

    private setValidationErrorHandler(handler: any): void {
        this.validationErrorHandler = handler;
    }

    private addDefaultHandlers(): void {
        this.addHandler(401, (): void => {
            AuthService.logout();
            NotificationService.error("Sesja wygasła. Zaloguj się ponownie.");
        });

        this.addHandler(404, (): void => {
            window.location.href = '/404';
        });

        this.addHandler(422, (error: AxiosError): void => {
            if (this.validationErrorHandler && error.response) {
                const errors = error.response?.data as ValidationErrors;
                this.setValidationErrorHandler(new ValidationError(errors));
            }
            NotificationService.error("Błąd walidacji.", error.response?.data);
        });
    }
}

const errorHandling: ErrorHandling = new ErrorHandling();

export default errorHandling;
