import {useContext} from "react";
import {BackgroundContext, BackgroundContextProps} from "../contexts/background-context";

export const useAppBackground = (): BackgroundContextProps => {
    const context = useContext(BackgroundContext);
    if (!context) {
        throw new Error('useBackground must be used within a BackgroundProvider');
    }
    return context;
};
