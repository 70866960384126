import React from 'react';
import {useFormContext} from "react-hook-form";
import {classNames} from "../../../../utils/class-names";

interface CheckboxFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
    showError?: boolean;
    onChange?: () => void;
}

const CheckboxFormField: React.FC<CheckboxFormFieldProps> = (props: CheckboxFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {
        name,
        label,
        className = '',
        disabled,
        showError,
        onChange,
        ...rest
    } = props;

    const isDisabled = disabled !== undefined ? disabled : isSubmitting;

    const handleOnChange = () => {
        if (!disabled && onChange) onChange();
    }

    return (
        <div className="cursor-pointer">
            <label htmlFor={name} className="flex items-center gap-2 text-gray-700 theme-dark:text-gray-50 cursor-pointer"
                   onClick={handleOnChange}>
                <input
                    type="checkbox"
                    id={name}
                    {...register(name)}
                    className={classNames(
                        `appearance-none ${className}`,
                        errors[name] ? '!border-red-500 checked:!bg-red-400' : '',
                        isDisabled ? 'checked:!bg-gray-300 checked:!border-gray-300' : '',
                    )}
                    disabled={isDisabled}
                    {...rest}
                />
                {label}
            </label>
            {showError && errors[name] && <p className="text-red-500 text-xs">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default CheckboxFormField;
