import CoursesTransaction from "../modules/courses/components/courses-transaction";
import SubscriptionsTransaction from "../modules/subscriptions/components/subscriptions-transaction";

const Transactions = () => {

    return (
        <div className='pt-5 sm:pt-10 flex-col flex gap-10 max-h-full overflow-y-auto pb-5'>
            <header>
                <h3 className='text-2xl leading-7 font-bold'>Historia transakcji</h3>
            </header>
            <section className='flex-col flex gap-16 xs:max-w-xl'>
                <CoursesTransaction/>
                <SubscriptionsTransaction/>
            </section>
        </div>
    )
}

export default Transactions
