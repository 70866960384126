import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {TypeCalendarEvent} from "../../types/type-event.interface";
import PrimaryButton from "../../../common/components/primary-button";
import {
    BellIcon,
    CalendarDaysIcon,
    CheckCircleIcon,
    ClockIcon,
    PencilIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import MonthCalendarMobile from "./month-calendar-mobile";
import DateTimeFormField from "../../../common/components/forms/date-time-form-field";
import SwitcherFormField from "../../../common/components/forms/switcher-form-field";
import TextareaFormField from "../../../common/components/forms/textarea-form-field";
import useCalendarApi from "../../hooks/use-calendar-api";
import {CalendarEventRequestBody, Reminder} from "../../types/calendar-event-request-body.interface";
import CalendarService from "../../services/calendar.service";
import {UpdateCalendarBodyInterface} from "../../types/update-calendar-body.interface";
import {useCalendarForm} from "../../hooks/use-calendar-form";
import {DateHelper} from "../../../core/utils/dateHelper";

export interface CalendarFormMobileHandler {
    showForm: (event: UpdateCalendarBodyInterface | null) => void;
    hideForm: () => void;
    setTypeCalendarEvent: (type: TypeCalendarEvent | null) => void;
}

interface CalendarFormProps {
    onSuccess?: () => void;
}

interface FormValues {
    title: string;
    date?: string;
    timeFrom?: string;
    timeTo?: string;
    isFullDay?: boolean;
}

const CalendarFormMobile = forwardRef<CalendarFormMobileHandler, CalendarFormProps>((props, ref) => {
    const [showForm, setShowForm] = useState(false);
    const [showAnimation, setShowAnimation] = useState<boolean>(false);
    const [typeEvent, setTypeEvent] = useState<TypeCalendarEvent | null>(null);
    const [reminders, setReminders] = useState<Array<Reminder>>([]); // State to manage reminders

    const schema = Yup.object().shape({
        title: Yup.string().required("Proszę podać tytuł."),
        isFullDay: Yup.boolean(),
        date: Yup.string()
            .when('isFullDay', {
                is: true,
                then: schema => schema.required("Proszę podać datę."),
                otherwise: schema => schema.notRequired(),
            }),
        timeFrom: Yup.string()
            .when('isFullDay', {
                is: false,
                then: schema => schema.required("Proszę podać czas rozpoczęcia."),
                otherwise: schema => schema.notRequired(),
            }),
        timeTo: Yup.string()
            .when('isFullDay', {
                is: false,
                then: schema => schema.required("Proszę podać czas zakończenia."),
                otherwise: schema => schema.notRequired(),
            }),
    });

    const {createCalendarEvent, updateCalendarEvent, deleteCalendarEvent} = useCalendarApi();
    const {setRefreshKey, refreshKey} = useCalendarForm();

    const methods = useForm<FormValues>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            title: "",
            date: "",
            timeFrom: "",
            timeTo: "",
            isFullDay: false,
        },
    });

    const {handleSubmit, reset, setValue, watch} = methods;
    const values = watch();
    const [currentEventId, setCurrentEventId] = useState<number | null>(null);

    useImperativeHandle(ref, () => ({
        showForm: (event) => {
            const isFullDay = event?.isFullDay;
            const startDate = event?.startDate;
            const endDate = event?.endDate;

            if (event) {
                setValue('title', event.title);
                setValue('date', isFullDay ? CalendarService.formatDate(event.startDate, 'YYYY-MM-DD') : "");
                setValue('timeFrom', !isFullDay ? startDate : "");
                setValue('timeTo', !isFullDay ? endDate : "");
                setValue('isFullDay', isFullDay);
                setReminders(event.reminders || []);
                setTypeEvent(event.calendarTypeName === "Zadanie" ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT);
                setCurrentEventId(event.id);
            } else {
                reset();
                setReminders([]);
                setCurrentEventId(null);
            }
            setShowForm(true);
            setTimeout(() => {
                setShowAnimation(true);
            }, 10);
        },
        hideForm: handleClose,
        setTypeCalendarEvent: setTypeEvent,
    }));

    const handleCreateEventCalendar = async (data: FormValues): Promise<void> => {
        const mappedBody: CalendarEventRequestBody = {
            title: data.title,
            startDate: data.isFullDay ? data.date! : data.timeFrom!,
            endDate: data.isFullDay ? data.date! : data.timeTo!,
            reminders: reminders,
            calendarTypeId: Number(typeEvent),
        };

        const action = currentEventId
            ? updateCalendarEvent(currentEventId, mappedBody)
            : createCalendarEvent(mappedBody);

        await action.then(() => {
            reset();
            setRefreshKey(refreshKey + 1);
            handleClose();
        });
    };

    const handleDelete = async (id: number): Promise<void> => {
        await deleteCalendarEvent(id).then(() => {
            setRefreshKey(refreshKey + 1);
            handleClose();
        });
    };

    const toggleReminder = (minutesBefore: number, isChecked: boolean) => {
        const eventStart = values.timeFrom;
        if (eventStart) {
            const eventDate = new Date(eventStart);
            const date = DateHelper.addTime(eventDate, 0, minutesBefore * -1);
            const reminderDate = DateHelper.formatDate(date, 'YYYY-MM-DDTHH:mm:00');

            const message = minutesBefore === 0
                ? "Przypomnienie: Trwa wydarzenie"
                : `Przypomnienie: Wydarzenie za ${minutesBefore} minut.`;

            if (isChecked) {
                if (!reminders.some(r => r.date === reminderDate)) {
                    setReminders(prev => [...prev, { date: reminderDate, message }]);
                }
            } else {
                setReminders(prev => prev.filter(r => r.date !== reminderDate));
            }
        }
    };

    const isReminderSet = (minutesBefore: number): boolean => {
        const eventStart = values.timeFrom;
        if (eventStart) {
            const eventDate = new Date(eventStart);
            const date = DateHelper.addTime(eventDate, 0, minutesBefore * -1);
            const reminderDate = DateHelper.formatDate(date, 'YYYY-MM-DDTHH:mm:00');
            return reminders.some(r => r.date === reminderDate);
        }
        return false;
    };

    const handleClose = () => {
        hideForm();
    };

    const hideForm = () => {
        setShowAnimation(false);
        setTimeout(() => {
            setShowForm(false);
            reset();
        }, 300);
    };

    useEffect(() => {
        if (values.timeFrom) {
            setReminders([0, 15, 30].map(minutesBefore => {
                const date = DateHelper.addTime(new Date(values.timeFrom!), 0, -minutesBefore);
                const reminderDate = DateHelper.formatDate(date, 'YYYY-MM-DDTHH:mm:00');
                const message = minutesBefore === 0
                    ? "Przypomnienie: Trwa wydarzenie"
                    : `Przypomnienie: Wydarzenie za ${minutesBefore} minut.`;

                return {
                    date: reminderDate,
                    message: message
                };
            }));
        }
    }, [values.timeFrom]);


    if (!showForm) return null;

    return (
        <div className="max-h-full absolute inset-0 flex">
            <div
                className={`w-full p-6 pb-0 bg-gray-50 flex flex-col
                shadow-lg transform transition-transform duration-300 ease-out ${
                    showAnimation ? "translate-y-0" : "translate-y-full"
                }`}
            >
                <MonthCalendarMobile enableDarkMode={false}/>
                <FormProvider {...methods}>
                    <form
                        className="text-gray-900 overflow-hidden flex flex-col p-[4px]"
                        onSubmit={handleSubmit(handleCreateEventCalendar)}
                    >
                        <div className="flex justify-between items-center mb-4">
                            <button
                                type="button"
                                className="px-4 py-2 text-base leading-6 font-medium text-gray-500 rounded-full hover:text-green-700 focus:text-green-700 focus:outline-none focus:ring-2 focus:ring-green-700"
                                onClick={handleClose}
                            >
                                Anuluj
                            </button>
                            {currentEventId && (
                                <button
                                    type="button"
                                    className="px-4 py-2 text-base leading-6 font-medium text-gray-500 rounded-full hover:text-red-400 focus:text-red-400 focus:outline-none focus:ring-2 focus:ring-red-200"
                                    onClick={() => handleDelete(currentEventId)}
                                >
                                    <div className='flex items-center'>
                                        <TrashIcon className='w-6 h-6 text-red-400 mb-1'/>
                                        Usuń
                                    </div>
                                </button>
                            )}
                            <PrimaryButton
                                type='submit'
                                styleClass="text-base leading-6 font-medium px-4 py-2 max-w-fit rounded-full shadow hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-800"
                            >
                                Zapisz
                            </PrimaryButton>
                        </div>

                        <div className='px-[2px] flex flex-col gap-6 text-gray-700 max-h-full overflow-y-auto'>
                            <div className='flex gap-3 items-start'>
                                <PencilIcon className='w-5 h-5 mt-1'/>
                                <TextareaFormField
                                    name='title'
                                    label=''
                                    placeholder='Dodaj tytuł'
                                    className='!p-0 !border-none !bg-transparent !shadow-none !ring-0 !text-2xl placeholder:!text-2xl'
                                    rows={1}
                                />
                            </div>

                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    className={`text-sm px-2 py-1 rounded-full flex gap-2 items-center ${
                                        typeEvent === TypeCalendarEvent.EVENT
                                            ? "text-green-800 bg-primary_100"
                                            : "text-gray-600 bg-gray-200"
                                    }`}
                                    onClick={() => setTypeEvent(TypeCalendarEvent.EVENT)}
                                >
                                    <CalendarDaysIcon
                                        className={`h-3 w-3 ${
                                            typeEvent === TypeCalendarEvent.EVENT
                                                ? "text-green-500"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    Wydarzenie
                                </button>
                                <button
                                    type="button"
                                    className={`text-sm px-2 py-1 rounded-full flex gap-2 items-center ${
                                        typeEvent === TypeCalendarEvent.TASK
                                            ? "text-green-800 bg-primary_100"
                                            : "text-gray-600 bg-gray-200"
                                    }`}
                                    onClick={() => setTypeEvent(TypeCalendarEvent.TASK)}
                                >
                                    <CheckCircleIcon
                                        className={`h-3 w-3 ${
                                            typeEvent === TypeCalendarEvent.TASK
                                                ? "text-green-500"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    Zadanie
                                </button>
                            </div>

                            <div className='w-full border border-gray-300'></div>

                            <div className="flex flex-col gap-6">
                                <header className='flex gap-3 items-center'>
                                    <ClockIcon className='h-4 w-4 '/>
                                    <span className='text-lg leading-8 font-semibold'>Czas trwania</span>
                                </header>
                                <div className='text-sm leading-6 font-medium flex justify-between'>
                                    <span>Cały dzień</span>
                                    <SwitcherFormField name='isFullDay'/>
                                </div>
                                {!values.isFullDay
                                    ? (
                                        <div className='flex flex-col gap-2'>
                                            <DateTimeFormField
                                                labelClassName='!text-gray-700'
                                                name="timeFrom"
                                                label="Od"
                                                onlyDate={typeEvent === TypeCalendarEvent.TASK}
                                            />
                                            <DateTimeFormField
                                                labelClassName='!text-gray-700'
                                                name="timeTo"
                                                label="Do"
                                                onlyDate={typeEvent === TypeCalendarEvent.TASK}
                                            />
                                        </div>
                                    )
                                    : (
                                        <DateTimeFormField
                                            labelClassName='!text-gray-700'
                                            name="date"
                                            label="Wybierz datę"
                                            onlyDate={true}
                                        />
                                    )}
                            </div>

                            {(typeEvent === TypeCalendarEvent.EVENT && values.timeFrom) && (
                                <div className='w-full border border-gray-300'></div>
                            )}

                            {(typeEvent === TypeCalendarEvent.EVENT && values.timeFrom) && (
                                <div className="flex flex-col gap-3 pb-2">
                                    <header className='flex gap-3 items-center'>
                                        <BellIcon className='h-4 w-4 '/>
                                        <span className='text-lg leading-8 font-semibold'>Powiadomienie</span>
                                    </header>
                                    <div className='text-sm leading-6 font-medium flex justify-between'>
                                        <span>W chwili wydarzenia</span>
                                        <SwitcherFormField
                                            checked={isReminderSet(0)}
                                            onToggle={(checked) =>
                                                toggleReminder(0, checked)
                                            }
                                            name='reminder1'/>
                                    </div>
                                    <div className='text-sm leading-6 font-medium flex justify-between'>
                                        <span>15 minut przed rozpoczęciem</span>
                                        <SwitcherFormField
                                            name='reminder2'
                                            checked={isReminderSet(15)}
                                            onToggle={(checked) =>
                                                toggleReminder(15, checked)
                                            }/>
                                    </div>
                                    <div className='text-sm leading-6 font-medium flex justify-between'>
                                        <span>30 minut przed rozpoczęciem</span>
                                        <SwitcherFormField
                                            checked={isReminderSet(30)}
                                            name='reminder3'
                                            onToggle={(checked) =>
                                                toggleReminder(30, checked)
                                            }/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
});

export default CalendarFormMobile;
