interface UseRetryRequestOptions {
    maxRetries?: number;
    delay?: number;
}

export function useRetryRequest<T>(): (
    request: () => Promise<T>,
    options?: UseRetryRequestOptions
) => Promise<T> {
    return async (
        request: () => Promise<T>,
        options: UseRetryRequestOptions = {maxRetries: 3, delay: 1000}
    ): Promise<T> => {
        const {maxRetries, delay} = options;

        let attempt = 0;

        while (attempt < maxRetries!) {
            try {
                return await request();
            } catch (error) {
                attempt++;
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        }

        throw new Error('Exceeded maximum retry attempts');
    };
}
