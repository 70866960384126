import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {Firebase} from "./firebase.enum";
import {firebaseConfig} from "./firebase.config";

const app = initializeApp(firebaseConfig, Firebase.NAME);

export const messagingFirebaseApp = getMessaging(app);
export const getFirebaseToken = getToken;

onMessage(messagingFirebaseApp, (payload) => {
    console.log('Message received in foreground: ', payload);
});
