import {useEffect, useState} from 'react';
import {isChrome, isDesktop, isEdge, isMobile, isOpera, isSafari, isIOS} from 'react-device-detect';
import {BeforeInstallPromptEvent} from "../types/before-install-prompt-event.interface";
import {environment} from "../../../environments/environment";
import SessionStorageService from "../services/session-storage.service";
import {SessionsStorageKeys} from "../../core/types/sessions-storage-keys.interface";

/**
 * usePWAInstall hook
 *
 * The `usePWAInstall` hook manages the installation process of a Progressive Web App (PWA) and provides information
 * on whether the app is installable, whether it is already installed, and supplies installation instructions
 * for various browsers and platforms (desktop/mobile).
 *
 * This hook leverages the `beforeinstallprompt` event, triggered by supported browsers when a PWA is ready for installation.
 *
 * ### Usage Recommendation
 * It is recommended to place `usePWAInstall` at the highest possible section of your app, ideally in a context or a component mounted at the top level of the application tree.
 * This ensures it can globally listen for `beforeinstallprompt` events across the app.
 *
 * ### How It Works
 * - Listens for the `beforeinstallprompt` event and stores `deferredPrompt` for later, allowing the user to trigger the installation process at their convenience.
 * - Stores the `deferredPrompt` in `sessionStorage` to maintain its state during the user’s session, enabling the user to resume the installation after page refresh or re-login.
 * - Offers an `installPWA` function, which invokes the installation prompt and manages the install state.
 * - Provides fallback manual installation instructions based on browser and platform if the installation prompt is not supported or available.
 *
 * ### State Variables and Functions
 * - `isInstallable`: A boolean indicating whether the PWA is ready to be installed.
 * - `isInstalled`: A boolean indicating if the PWA is already installed (detected based on display mode).
 * - `installPWA`: An async function that prompts the user to install the PWA.
 * - `manualInstructions`: JSX with step-by-step installation instructions for unsupported browsers.
 *
 * ### Example Usage
 *
 * ```typescript
 * const { isInstallable, installPWA, manualInstructions, isInstalled } = usePWAInstall();
 *
 * return (
 *   <div>
 *     {isInstallable && (
 *       <button onClick={installPWA}>Install PWA</button>
 *     )}
 *     {!isInstallable && manualInstructions}
 *     {isInstalled && <p>The app is already installed.</p>}
 *   </div>
 * );
 * ```
 */
const usePWAInstall = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);
    const [manualInstructions, setManualInstructions] = useState<JSX.Element | null>(null);

    useEffect(() => {
        checkIfInstalled();

        const handler = (e: BeforeInstallPromptEvent) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setIsInstallable(true);

            if (!environment.production) console.log('mount beforeinstallprompt')
        };

        if (!deferredPrompt) window.addEventListener('beforeinstallprompt', handler);

        return () => {
            console.log('unmount beforeinstallprompt')
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    const installPWA = async () => {
        if (deferredPrompt) {
            deferredPrompt?.prompt();
            const {outcome} = await deferredPrompt?.userChoice;
            if (outcome === 'accepted') {
                setIsInstalled(true);
                if (!environment.production) console.log('User accepted install prompt PWA');
            } else {
                if (!environment.production) console.log('User rejected install prompt PWA');
            }
            setDeferredPrompt(null);
            setIsInstallable(false);
        } else {
            handleFallback();
        }
    };

    const checkIfInstalled = () => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        const isIosStandalone = (window.navigator as any).standalone === true;
        setIsInstalled(isStandalone || isIosStandalone);
    };

    const handleFallback = () => {
        setManualInstructions(getInstallInstructions())
    };

    const getInstallInstructions = () => {
        const isBrave = navigator.userAgent.includes("Brave");

        if (isIOS && !isSafari) {
            return (
                <div>
                    <p><strong>Instalacja aplikacji na IOS jest dostępna wyłącznie w Safari:</strong></p>
                    <p>Aby zainstalować aplikację, otwórz stronę w przeglądarce Safari i postępuj zgodnie z instrukcjami
                        instalacji.</p>
                </div>
            );
        }

        switch (true) {
            case isChrome && isDesktop:
                return (
                    <div>
                        <p><strong>Instalacja dla Google Chrome:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W pasku adresu znajduje się ikona pobrania apki.</li>
                            <li>Kliknij ikonę i potwierdź instalację.</li>
                            <li>Aplikacja zostanie dodana na pulpit lub do menu Start.</li>
                        </ol>
                    </div>
                );
            case isChrome && isMobile:
                return (
                    <div>
                        <p><strong>Instalacja dla Google Chrome:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W menu (trzy kropki) w prawym górnym rogu wybierz „Dodaj do ekranu głównego”.</li>
                            <li>Potwierdź dodanie, klikając „Zainstaluj jako aplikację”.</li>
                        </ol>
                    </div>
                );
            case isEdge && isDesktop:
                return (
                    <div>
                        <p><strong>Instalacja dla Microsoft Edge:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W pasku adresu pojawia się ikona instalacji (okno z plusem).</li>
                            <li>Kliknij ikonę i wybierz „Zainstaluj jako aplikację”.</li>
                            <li>Aplikacja pojawi się w menu Start lub na pulpicie.</li>
                        </ol>
                    </div>
                );
            case isEdge && isMobile:
                return (
                    <div>
                        <p><strong>Instalacja dla Microsoft Edge:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W menu (trzy kropki) w prawym dolnym rogu wybierz „Dodaj do ekranu głównego”.</li>
                            <li>Potwierdź dodanie, klikając „Dodaj”.</li>
                        </ol>
                    </div>
                );
            case isOpera && isDesktop:
                return (
                    <div>
                        <p><strong>Instalacja dla Opera:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W pasku adresu pojawia się ikona „Zainstaluj”.</li>
                            <li>Kliknij ikonę i potwierdź instalację.</li>
                            <li>Aplikacja pojawi się na pulpicie lub w menu Start.</li>
                        </ol>
                    </div>
                );
            case isOpera && isMobile:
                return (
                    <div>
                        <p><strong>Instalacja dla Opera:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W menu (trzy kropki) w prawym górnym rogu wybierz „Dodaj do ekranu głównego”.</li>
                            <li>Potwierdź dodanie, klikając „Dodaj”.</li>
                        </ol>
                    </div>
                );
            case isBrave && isDesktop:
                return (
                    <div>
                        <p><strong>Instalacja dla Brave:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W pasku adresu pojawia się ikona „Zainstaluj”.</li>
                            <li>Kliknij ikonę i potwierdź dodanie aplikacji.</li>
                            <li>Aplikacja zostanie dodana na pulpit i do menu Start.</li>
                        </ol>
                    </div>
                );
            case isBrave && isMobile:
                return (
                    <div>
                        <p><strong>Instalacja dla Brave:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>W menu (trzy kropki) w prawym górnym rogu wybierz „Dodaj do ekranu głównego”.</li>
                            <li>Potwierdź, klikając „Dodaj” w oknie dialogowym.</li>
                        </ol>
                    </div>
                );
            case isSafari && isDesktop:
                return (
                    <div>
                        <p><strong>Instalacja dla Safari:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>Wybierz ikonę „Udostępnij” w pasku narzędzi.</li>
                            <li>Wybierz „Dodaj do Docka”.</li>
                            <li>Ikona aplikacji pojawi się w Docku.</li>
                        </ol>
                    </div>
                );
            case isSafari && isMobile:
                return (
                    <div>
                        <p><strong>Instalacja dla Safari:</strong></p>
                        <ol className='list-disc ml-5'>
                            <li>Wybierz ikonę „Udostępnij” (kwadrat z strzałką w górę) na dole ekranu.</li>
                            <li>Wybierz „Dodaj do ekranu początkowego”.</li>
                            <li>Potwierdź, klikając „Dodaj”.</li>
                        </ol>
                    </div>
                );
            default:
                return (
                    <div>
                        <p><strong>Twoja przeglądarka nie wspiera instalacji PWA.</strong></p>
                        <p>Spróbuj użyć jednej z kompatybilnych przeglądarek, takich jak Chrome, Safari, Edge, Opera lub
                            Brave.</p>
                    </div>
                );
        }
    };

    return {isInstallable, installPWA, manualInstructions, setManualInstructions, isInstalled};
};

export default usePWAInstall;
